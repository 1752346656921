export default class ConfirmAge {
  static handleConfirmAge(callback) {
    if(ConfirmAge.isAgeConfirm()) return;

    return callback()
  }

  static confirmAge() {
    localStorage.setItem('confirm-age', true)
  }



  static isAgeConfirm() {
    return Boolean(localStorage.getItem('confirm-age'))
  }
}
