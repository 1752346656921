var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasePopupLayout',{attrs:{"no-border":"","title":_vm.$t('general.popups.createBundle.title'),"custom-close":_vm.close}},[_c('div',{staticClass:"create-promotion"},[(_vm.errors.collect('_').length)?_c('AppInfo',{staticClass:"mb-3",attrs:{"text":"","type":"error"}},[_c('ul',{staticClass:"pl-0"},_vm._l((_vm.errorsList),function(errorItem){return _c('li',{key:errorItem,staticClass:"list-unstyled"},[_vm._v("\n          "+_vm._s(errorItem)+"\n        ")])}),0)]):_vm._e(),_vm._v(" "),_c('form',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('div',{staticClass:"v-select-profile mt-1"},[_c('v-select',{staticClass:"vs-theme-app",class:{
                'vs-theme-app--selected': _vm.form.month,
              },attrs:{"name":"months","label":"label","options":_vm.months,"reduce":(option) => option,"placeholder":"months"},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}}),_vm._v(" "),_c('span',{staticClass:"v-select-placeholder",class:{
                'v-select-placeholder--active': _vm.form.month,
              }},[_vm._v("\n              "+_vm._s(_vm.$t("general.popups.createBundle.monthsPlaceholder"))+"\n            ")]),_vm._v(" "),(_vm.errors && _vm.errors.has('months'))?_c('small',{staticClass:"create-promotion__font create-promotion__font--error"},[_vm._v("\n              "+_vm._s(_vm.errors.first("months"))+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('b-col',[_c('div',{staticClass:"v-select-profile mt-1"},[_c('v-select',{key:_vm.form.discount,staticClass:"vs-theme-app",class:{
                'vs-theme-app--selected': _vm.form.discount,
              },attrs:{"name":"discount","label":"label","options":_vm.discounts,"reduce":(option) => option,"placeholder":"discount"},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", $$v)},expression:"form.discount"}}),_vm._v(" "),_c('span',{staticClass:"v-select-placeholder",class:{
                'v-select-placeholder--active': _vm.form.discount,
              }},[_vm._v("\n              "+_vm._s(_vm.$t("general.popups.createBundle.discountPlaceholder"))+"\n            ")]),_vm._v(" "),(_vm.errors && _vm.errors.has('discount'))?_c('small',{staticClass:"create-promotion__font create-promotion__font--error"},[_vm._v("\n              "+_vm._s(_vm.errors.first("discount"))+"\n            ")]):_vm._e()],1)])],1),_vm._v(" "),_c('div',{staticClass:"create-promotion__button-wrapper"},[_c('button',{staticClass:"base-button create-promotion__button",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c('span',{staticClass:"base-button__font"},[_vm._v("\n            "+_vm._s(_vm.$t("general.popups.createBundle.button.add"))+"\n          ")])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }