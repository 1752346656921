<template>
  <div class="confirm-popup-layout"  @click.stop>
    <div class="confirm-popup-layout__wrapper">
      <div class="confirm-popup-layout__title" v-if="title">
        <span
          class="confirm-popup-layout__font confirm-popup-layout__font--title"
        >
          {{ title }}
        </span>
      </div>

      <div class="confirm-popup-layout__content" v-if="$slots.default">
        <slot />
      </div>

      <div class="confirm-popup-layout__footer border-top" v-if="buttonText && showBottomButton">
        <button
          type="button"
          class="confirm-popup-layout__button"
          @click.stop="close"
        >
          <span
            class="confirm-popup-layout__font confirm-popup-layout__font--button"
          >
            {{ buttonText }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPopupLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "OK",
    },
    customClose: {
      type: Function,
      default: null,
    },
    showBottomButton: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    close() {
      if(this.customClose) return this.customClose();

      this.$popup.close();
    },
  },
};
</script>

<style scoped lang="scss">
.confirm-popup-layout {
  $parent: &;

  width: em(330);

  &__wrapper {
    position: relative;
    z-index: 2;
    background: $white;
    border-radius: em(10);
  }

  &__title {
    text-align: center;
    padding: em(19) em(10) em(18);
  }

  &__content {
    text-align: center;
  }

  &__footer {
    width: 100%;
  }

  &__button {
    text-align: center;
    padding: em(16) em(10) em(17);
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-style: normal;
    font-weight: 500;
    line-height: 1;

    &--title {
      font-size: em(18);
    }

    &--button {
      font-size: em(14);
    }
  }
}
</style>
