export default class Hashtag {
  id = 0;
  name = "";

  constructor(data) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.name = data.name;
  }
}
