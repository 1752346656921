import axios from "@/plugins/axios";

const state = () => ({
  messagesList: []
});

const getters = {
  messagesList: (state) => state.messagesList,
};

const actions = {
  fetchMessagesList({ commit }) {
    return axios({ method: "GET", url: `/lists/message` }).then((response) => {
      commit("setMessagesList", { lists: response.data?.lists ?? [] });

      return response;
    });
  },
};

const mutations = {
  setMessagesList(state, { lists = [] } = {}) {
    state.messagesList = lists;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
