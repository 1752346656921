import axios from "@/plugins/axios";

const state = () => ({
  postHome: [],
  postHomeMeta: null,
});

const actions = {
  fetchPost(store, postId) {
    return axios({ method: "GET", url: `/posts/${postId}` }).then((response) => {
      return response;
    });
  },
};

const getters = {
  postHome: (state) => state.postHome,
  postHomeMeta: (state) => state.postHomeMeta,
};

const mutations = {
  setPosts(state, { posts } = {}) {
    state.postHome = posts;
  },

  updatePost(state, { post } = {}) {
    const listDataIndex = state.postHome?.findIndex(
      (item) => item?.id === post.id
    );

    if (listDataIndex === -1) return;
    state.postHome[listDataIndex] = { ...post };
    state.postHome?.splice( state.postHome?.length);
  },


  updatePosts(state, { posts } = {}) {
    state.postHome = [...state.postHome, ...posts];
  },

  setMeta(state, { meta } = {}) {
    state.postHomeMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
