<template>
  <button
    @click="toRoute"
    class="post-tile"
    :class="{
      'post-tile--disabled': !hasAccess,
      'post-tile--selectable': isSelect,
    }"
    :style="backgroundStyle"
  >
<!--    'post-tile&#45;&#45;inactive': inactive,-->
    <template v-if="isFlowTile">
      <div class="post-tile__view">
        <span class="post-tile__icon-wrapper">
          <CIcon
            name="video"
            path="flows"
            class="post-tile__icon post-tile__icon--view"
          />
        </span>

        <span class="post-tile__font">
          {{ viewCount }}
        </span>
      </div>

      <button type="button" class="post-tile__subscription" v-if="!hasAccess">
        <span class="post-tile__icon-wrapper">
          <CIcon
            name="lock"
            path="flows"
            class="post-tile__icon post-tile__icon--lock"
          />
        </span>

        <span class="post-tile__font"> {{ accessTypeMessage }} </span>
      </button>
    </template>

    <template v-else>
      <div
        v-for="icon in descriptionIcons"
        :key="icon.id"
        class="post-tile__description"
        :class="{ [`post-tile__description--${icon.position}`]: icon.position }"
      >
        <CIcon
          :name="icon.name"
          path="flows"
          class="post-tile__icon post-tile__icon--view"
          :class="{ [`post-tile__icon--${icon.name}`]: icon.name }"
        />
      </div>

      <button type="button" class="post-tile__subscription" v-if="!hasAccess">
        <span class="post-tile__icon-wrapper">
          <CIcon
            name="lock"
            path="flows"
            class="post-tile__icon post-tile__icon--lock"
          />
        </span>

        <span class="post-tile__font"> {{ accessTypeMessage }} </span>
      </button>
    </template>
  </button>
</template>

<script>
import Post from "@/components/models/Post";
import { nFormat } from "@/tools/helpers";
import CIcon from "@/features/ui/CIcon.vue";
import { mapGetters, mapMutations } from "vuex";
import userPosts from "@/mixins/userPosts";
import anonymousHandler from "@/mixins/anonymousHandler";

const tileType = {
  select: 'select',
  default: 'default'
}

export default {
  name: "PostTile",
  components: {
    CIcon,
  },
  mixins: [userPosts, anonymousHandler],
  props: {
    post: {
      type: Post,
      required: true,
    },

    isFlowTile: {
      type: Boolean,
      default: false,
    },

    isDisabledLink: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },

    isVault: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    selectedItem: {
      type: Boolean,
      default: false,
    },
    tileType: {
      type: String,
      default: tileType.default,
      validator(value) {
        return [tileType.default, tileType.select].includes(value);
      },
    }
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
    userId() {
      return this.post.user.id;
    },
    isOwner() {
      const isPostOwner = this.post?.user?.id === this.user?.id || this.isVault;

      return this.isAdmin || isPostOwner;
    },

    currentPost() {
      return this.post;
    },

    postUrl() {
      if (!this.hasAccess) return "";
      if (this.isFlowTile) {
        return `/flow/${this.currentPost?.id}`;
      }

      return this.currentPost?.url;
    },

    imageUrl() {
      let media;

      if (this.isVault) {
        media = this.currentPost;
      } else {
        media = this.currentPost?.media?.[0];
      }

      return this.getImage(media);
    },

    hasAccess() {
      return this.isOwner || this.currentPost?.hasAccess;
    },

    accessTypeMessage() {
      const accessType = this.post.access_type;
      switch (accessType) {
        case Post.ACCESS_TYPE_FOLLOWERS:
          return this.$t("general.accessType.followers");
        case Post.ACCESS_TYPE_SUBSCRIBERS:
          return this.$t("general.accessType.subscribers");
        case Post.ACCESS_TYPE_PAID_POST:
          return this.$tc("general.accessType.paid", this.post.price || 0,{
            price: this.post.price || 0,
          });
        default:
          return "";
      }
    },

    isFlow() {
      return this.currentPost?.isFlow;
    },

    isVideo() {
      return !this.isFlow && this.currentPost?.hasVideo;
    },

    isSlides() {
      return !this.isFlow && this.currentPost?.hasSlides;
    },

    backgroundStyle() {
      return {
        // "background-image": `url(${this.imageUrl})`,
        "--image-path": `url(${this.imageUrl})`,
      };
    },

    viewCount() {
      return nFormat(this.currentPost?.views || 0);
    },

    icons() {
      return [
        { id: 1, name: "flow", position: "top-right", isShow: this.isFlow },
        { id: 2, name: "video", position: "top-right", isShow: this.isVideo },
        {
          id: 3,
          name: "slides",
          position: "bottom-right",
          isShow: this.isSlides,
        },
      ];
    },

    descriptionIcons() {
      return this.icons?.filter((icon) => icon?.isShow);
    },
  },

  data() {
    return {
      isSelect: this.selectedItem,
    };
  },

  mounted() {
    this.isSelect = this.selectedItem;
  },

  methods: {
    ...mapMutations({
      setCurrentSubscription: "subscriptions/setCurrentSubscription",
    }),

    getImageUrl(imageObject) {
      const { url = "", jwt = "" } = imageObject;

      return `${url}?token=${jwt}`;
    },

    getMuxImage(imageObject) {
      const image = this.getImageUrl(imageObject);
      if (image.includes("stream.mux.com")) return "/mock/header.webp";

      return image ?? "/mock/header.webp";
    },

    getImage(mediaObject) {
      const isMedia = mediaObject?.url?.url;
      if (!isMedia) {
        return "/mock/header.webp";
      }

      if (mediaObject?.screenshot?.url) {
        return this.getImageUrl(mediaObject?.screenshot);
      }

      if (mediaObject?.thumbnail?.url) {
        return this.getImageUrl(mediaObject?.thumbnail);
      }

      if(mediaObject?.thumbs?.[0]?.url) {
        return this.getImageUrl(mediaObject?.thumbs?.[0]?.url);
      }

      return this.getMuxImage(mediaObject.url);
    },

    toRoute() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      if (this.selectable && this.tileType === tileType.default) {
        this.select();
      }

      if(this.tileType === tileType.select) {
        this.chose()
      }

      if (this.inactive) return;

      if (!this.hasAccess) {
        this.unlock();
        return;
      }

      this.$router.push(this.postUrl);
    },
    unlock() {
      const postContext = this.$route.name;
      const isIndexPage = postContext === "index";
      const isUserPage = postContext === "user";
      const postData = {
        ...this.post,
        sub: this.post.user,
      };

      if (isIndexPage) {
        this.setCurrentSubscription({
          ...postData,
          action: async () => {
            const response = await this.fetchPost(this.post.id).catch(
              this.checkErrors
            );
            this.updatePost({
              post: response.data,
            });
          },
        });
      }

      if (isUserPage) {
        this.setCurrentSubscription({
          ...postData,
          action: () => {
            this.initialLoadPost();
          },
        });
      }

      this.$popup.open("SubscriptionPopup");
    },

    select() {
      this.isSelect = !this.isSelect;
      if (this.isSelect) {
        this.$emit("select", this.post);
      } else {
        this.$emit("unselect", this.post);
      }
    },

    chose() {
      this.$emit("chose", this.post);
    }
  },
};
</script>

<style lang="scss" scoped>
.post-tile {
  --image-path: null;

  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: $app-gray;
  background-image: var(--image-path);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__view,
  &__edit,
  &__subscription {
    display: flex;
    align-items: center;
    padding: em(3);
    border-radius: em(3);
    background-color: rgba($black, 0.7);
  }

  &__view {
    position: absolute;
    left: em(7);
    top: em(7);
  }

  &__subscription {
    width: auto;
    position: absolute;
    left: em(7);
    bottom: em(7);
  }

  &__edit {
    width: auto;
    position: absolute;
    right: em(7);
    top: em(7);
  }

  &__description {
    position: absolute;
    display: inline-flex;

    &--top-right {
      right: em(7);
      top: em(7);
    }

    &--bottom-right {
      right: em(7);
      bottom: em(7);
    }
  }

  &__font {
    color: $white;
    font-family: $font-default;
    font-size: em(10);
    font-style: normal;
    font-weight: 500;
    display: inline-flex;
    align-items: flex-end;
  }

  &__icon-wrapper {
    width: em(13);
    height: em(13);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: $white;

    &--view {
      margin-right: 1px;
      width: em(9);
      height: em(9);
    }

    &--lock {
      margin-right: em(5);
      width: em(9);
      height: em(9);
    }

    &--flow,
    &--video,
    &--slides {
      width: em(13);
      height: em(13);
    }
  }

  &--disabled {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
    }
  }

  &--inactive {
    pointer-events: none;
  }

  &--selectable {
    border-radius: em(5);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      width: 100%;
      height: 100%;
      border-radius: em(5);
      overflow: hidden;
      border: em(3) solid $app-gray;
      pointer-events: none;

      border-color: $app-blue;
    }
  }
}
</style>
