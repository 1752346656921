<template>
  <div class="comments" @click.stop>
    <div
      class="user-message-list"
      :class="{
        [`user-message-list--${inputPositionType}`]: inputPositionType,
      }"
    >
      <slot />

      <InfinityScroll
        v-if="comments.length"
        :items="comments"
        :has-more="hasMoreComments"
        :initial-page="initialPage"
        @rich-end="loadMore"
      >
        <template #default="{ item }">
          <AppComment
            :item="item"
            @delete-comment="deleteComment"
            @reply="reply"
          />
        </template>
      </InfinityScroll>

      <AppInfo
        v-else
        class="flow-comments-popup__info"
        :text="$t('general.comments.nocomments')"
      />
    </div>

    <div
      class="user-message-input"
      :class="{
        [`user-message-input--${inputPositionType}`]: inputPositionType,
      }"
      v-if="canCommit"
    >
      <div class="user-message-input__avatar-wrapper">
        <div class="user-message-input__avatar">
          <BAvatar :src="'/mock/PFP.png'" :text="'AV'" size="28px" />
        </div>
      </div>

      <div class="user-message-input__input">
        <CTextarea
          v-model.trim="message"
          theme="comments"
          :placeholder="$t('general.comments.placeholder')"
        >
          <template #appendAfter>
            <button type="button" class="button" @click="addComment">
              <CIcon
                name="send"
                path="comments"
                class="user-message-input__icon"
              />
            </button>
          </template>
        </CTextarea>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "@/components/models/Comment";

import CIcon from "@/features/ui/CIcon.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import AppComment from "@/features/containers/Comments/Comment/AppComment.vue";
import CTextarea from "@/features/ui/CTextarea.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

export default {
  name: "AppComments",
  components: {
    AppInfo,
    AppComment,
    InfinityScroll,
    CIcon,
    CTextarea,
  },
  mixins: [waitRequest, validationError],

  props: {
    postId: {
      type: [Number, String],
      required: true,
    },

    canCommit: {
      type: Boolean,
      default: true,
    },

    inputPositionType: {
      type: String,
      default: "",
    },
    callbackOnMessage: {
      type: Function,
      default: null,
    },
    callbackOnMessageRemove: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      message: "",
      comments: [],
      hasMoreComments: false,
      initialPage: 1,
      meta: {
        next_page_url: "",
        current_page: 1,
      },
    };
  },

  computed: {
    hasMorePosts() {
      return Boolean(this.meta?.next_page_url);
    },

    page() {
      return this.meta?.current_page || 1;
    },
  },

  mounted() {
    this.initialLoadComments(this.postId);
  },

  methods: {
    loadMore() {
      if (this.hasMoreComments)
        return this.loadComments(this.updateCurrentComments);
    },

    loadComments(successCallback) {
      if (this.requestInProgress) return;

      const url = new URL(
        `/comments/${this.postId}`,
        process.env.VUE_APP_API_URL
      );
      url.searchParams.set("page", this.page);

      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.failureLoadComments,
          this.checkErrors
        );
      });
    },

    initialLoadComments() {
      this.$showSpinner();
      this.loadComments(this.setCurrentComments);
    },

    setMeta(data) {
      this.meta.current_page = data?.current_page || 1;
      this.meta.next_page_url = data?.next_page_url || 1;
    },

    setCurrentComments(data) {
      const { current_page = 1, data: comments } = data;

      this.initComments(comments);
      this.setMeta(data);

      this.initialPage = current_page;
    },

    updateCurrentComments(data) {
      const comments = data.data;

      this.updateComments(comments);
      this.setMeta(data);
    },

    initComments(comments) {
      this.comments = comments?.map((comment) => new Comment(comment)) || [];
    },

    updateComments(comments) {
      const newComments = comments.map((comment) => new Comment(comment));
      this.comments = [this.comments, ...newComments];
    },

    failureLoadComments(err) {
      console.log(err);
    },

    deleteComment(comment) {
      if (this.requestInProgress) return;

      this.waitRequest(() => {
        return this.$post(
          `/comments/${comment.id}`,
          { _method: "DELETE" },
          () => this.successDelete(comment),
          this.failureDelete,
          this.checkErrors
        );
      });
    },

    failureDelete(err) {
      console.log("err", err);
    },

    successDelete(comment) {
      this.comments = this.comments.filter((item) => item.id !== comment.id);

      if (this.callbackOnMessageRemove) return this.callbackOnMessageRemove()
    },

    addComment() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        this.$hideSpinner();
        return this.$post(
          `/comments/${this.postId}`,
          { message: this.message },
          this.successAddComment,
          this.failureAddComment,
          this.checkErrors
        );
      });
    },

    successAddComment(comment) {
      const newComment = new Comment(comment);
      this.comments = [newComment, ...this.comments];
      this.message = "";

      this.scrollTop();

      if (this.callbackOnMessage) return this.callbackOnMessage();
    },

    failureAddComment(err) {
      console.log(err);
    },

    scrollTop() {
      this.$nextTick(function () {
        scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    },

    reply({ username }) {
      this.message = `@${username},  ${this.message}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/_variables.scss";

.comments {
  position: relative;
  overflow: auto;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.user-message-list {
  max-height: 50vh;
  overflow-x: scroll;

  @include hideScroll;

  &--post {
    max-height: none;
    margin-bottom: em(80);
  }
}

.user-message-input {
  display: flex;
  border-top: 1px solid $app-gray-2;
  width: 100%;
  background-color: white;

  @include media-breakpoint-up(sm) {
    border-bottom-left-radius: em(10);
    border-bottom-right-radius: em(10);
  }

  &__avatar-wrapper {
    margin-top: em(24);
    margin-right: em(13);
    margin-left: em(14);
  }

  &__avatar {
  }

  &__input {
    margin-top: em(17);
    margin-right: em(14);
    margin-bottom: em(17);
    flex: 1;
  }

  &__icon {
    color: $app-gray-6;
    width: em(19);
    height: em(19);
  }

  &--post {
    position: fixed;
    bottom: em(55);
    left: 0;

    @include media-breakpoint-up(sm) {
      position: relative;
      bottom: unset;
    }
  }
}
</style>
