<template>
  <BasePopupLayout
    class="add-to-collection-popup"
    :title="$t('general.popups.addToCollections.title')"
    :button-text="$t('general.popups.addToCollections.buttonClose')"
  >
    <template #addition>
      <button
        @click="customClose"
        class="button add-to-collection-popup__button"
      >
        <span
          class="add-to-collection-popup__font add-to-collection-popup__font--button"
        >
          {{ $t("general.popups.addToCollections.buttonNewList") }}
        </span>
      </button>
    </template>

    <div class="add-to-collection-popup__content">
      <transition name="fade">
        <div class="add-to-collection-popup__settings" v-if="lists.length">
          <CRadio
            v-model="selectedCollections"
            @changed="add"
            v-for="(item, index) in lists"
            :key="`${index + '-group'}`"
            :name="item.id"
            :value="item"
            group-name="settings"
            input-type="checkbox"
            class="add-to-collection-popup__button add-to-collection-popup__button--to-list"
          >
            <template #default="{ isActive, value }">
              <div
                class="button add-to-collection-button"
                :class="{ 'add-to-collection-button--active': isActive }"
              >
                <div class="add-to-collection-button__content">
                  <span class="add-to-collection-button__font">
                    {{ value.title || $t("general.popups.addToCollections.favorites") }}
                  </span>

                  <span
                    class="add-to-collection-button__font add-to-collection-button__font--secondary"
                  >
                    {{ $tc("general.popups.addToCollections.x-users", value.listees_count || 0, { count: value.listees_count || 0 }) }}
                  </span>
                </div>
              </div>
            </template>
          </CRadio>
        </div>
      </transition>
    </div>
  </BasePopupLayout>
</template>

<script>
import CRadio from "@/features/ui/CRadio.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

// import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import { mapGetters, mapMutations } from "vuex";
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";

export default {
  name: "AddToCollectionsPopup",
  components: {
    BasePopupLayout,
    // ConfirmPopupLayout,
    CRadio,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [waitRequest, validationError],

  data() {
    return {
      selectedCollections: [],
      lists: [],
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setContains: "selectUserCollection/setContains",
      setCollections: "pageCollections/setCollections",
    }),
    initState() {
      this.loadLists();
    },

    customClose() {
      this.$popup.open("AddNewCollectionsPopup", {
        addedUserId: this.data.addedUserId,
      });
    },

    loadLists() {
      this.waitRequest(() => {
        return this.$get(
          "/lists/user/" + this.data.addedUserId,
          (data) => {
            this.lists = data.lists.slice();
            this.setCollections({ collection: data.lists });

            this.selectedCollections = this.lists.filter((item) => {
              return data.contains.includes(item.id);
            });
          },
          (errors) => {
            console.log(errors);
          }
        );
      }).catch(this.checkErrors);
    },

    add(collection) {
      const collectionId = collection.id;

      this.waitRequest(() => {
        return this.$post(
          "/lists/" + this.data.addedUserId + "/" + collectionId,
          {},
          (data) => {
            if (!data.status) this.removeFromSelected(collectionId);
            this.updateContains();
          },
          (errors) => {
            console.log(errors);
            this.removeFromSelected(collectionId);
            this.updateContains();
          }
        );
      }).catch(this.checkErrors);
    },

    removeFromSelected(collectionId) {
      this.selectedCollections = this.selectedCollections.filter(
        (selectedCollections) => {
          return selectedCollections.id !== collectionId;
        }
      );
    },

    updateContains() {
      const contains = this.selectedCollections.map((item) => item.id);
      this.setContains({ contains });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-collection-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
  }

  &__settings {
    margin-bottom: em(12);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-height: 50vh;
    overflow: auto;

    @include hideScroll;
  }

  &__button {
    //margin-bottom: em(16);

    &--to-list {
      margin-bottom: em(16);
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: $black;

    &--button {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      text-align: right;
      color: $app-blue;
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.add-to-collection-button {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    align-self: flex-start;
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    margin-top: em(6);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: normal;

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      color: $app-gray-32;
    }
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
