<template>
  <BasePopupLayout :title="$t('general.popups.add-funds.title')">
    <div class="add-funds">
      <form class="add-funds__form" novalidate @submit.prevent="addFund">
        <div class="add-funds__field">
          <CInput
            type="text"
            name="amount"
            v-mask="currencyMask"
            v-model.trim="form.amount"
            :custom-errors="errors"
            :custom-field-name="$t('general.popups.add-funds.input.amount')"
            theme="settings"
            class="new-card__field new-card__field--name"
            :label="$t('general.popups.add-funds.input.amount')"
            :rules="'required'"
          />
        </div>

        <div class="add-funds__settings">
          <CRadio
            v-model="selected"
            v-for="(item, index) in cardsList"
            :key="`${index + '-group'}`"
            :name="item.name"
            :value="item"
            allow-uncheck
            group-name="settings"
            class="add-funds__radio-row"
          >
            <template #default="{ isActive, value }">
              <div
                class="button card-settings"
                :class="{ 'card-settings--active': isActive }"
              >
                <span class="add-funds__font add-funds__font--base">
                  <img :src="value.image" class="add-funds__image" />
                  ...{{ value.title }}
                </span>
              </div>
            </template>
          </CRadio>
        </div>

        <div class="button button-plus" @click="openAddCardPopup">
          <CIcon name="plus" class="add-funds__icon add-funds__icon--plus" />
          <div class="add-funds__plus">
            <span class="transaction-card__font transaction-card__font--base">
              New Payment Method
            </span>
          </div>

          <small
            class="text-danger button-plus__error"
            v-if="errors.has('payment_method_id')"
          >
            {{ errors.first("payment_method_id") }}
          </small>
        </div>

        <div class="add-funds__footer">
          <CButton
            type="submit"
            align="center"
            variant="primary"
            class="add-funds__button"
          >
            <span class="add-funds__font add-funds__font--button">
              {{ $t("general.popups.add-funds.button") }}
            </span>
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CRadio from "@/features/ui/CRadio.vue";
import { mapActions, mapGetters } from "vuex";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import CIcon from "@/features/ui/CIcon.vue";
import { paymentMethods } from "@/config/paymentMethods";

export default {
  name: "AddFundsPopup",
  components: { CIcon, CRadio, CButton, CInput, BasePopupLayout },
  mixins: [waitRequest, validationError],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        amount: "",
      },
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      cards: "payments/cards",
    }),
    currencyMask() {
      return createNumberMask({
        prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
    cardsList() {
      return (
        this.cards?.cards?.methods.map((item) => {
          return {
            ...item,
            image: this.cardImage(item.brand),
            title: this.curdNumber(item.title),
          };
        }) || []
      );
    },
  },
  mounted() {
    this.initState();
  },

  methods: {
    ...mapActions({
      fetchPaymentCards: "payments/fetchPaymentCards",
      addFunds: "wallet/addFunds",
      fetchWalletHistory: "wallet/fetchWalletHistory",
    }),
    initState() {
      this.fetchPaymentCards().catch(this.checkErrors);
    },
    curdNumber(string) {
      return string.replaceAll("*", "") || "WALLET";
    },
    cardImage(brand) {
      switch (brand) {
        case paymentMethods.Visa:
        case paymentMethods.VisaDebit:
          return "/img/payments/visa-small.webp";
        case paymentMethods.MasterCard:
        case paymentMethods.MasterCardDebit:
          return "/img/payments/mastercard-small.webp";
        case paymentMethods.Discover:
          return "/img/payments/discover-small.webp";
        case paymentMethods.AMEX:
          return "/img/payments/mastercard-small.webp";
        default:
          return "/img/payments/default-small.webp";
      }
    },
    close() {
      this.$emit("close");
    },
    openAddCardPopup() {
      this.$popup.open("AddCardPopup", {
        closeCallback: () => {
          this.$popup.close(1);
          this.$popup.open("AddFundsPopup");
        },
      });
    },

    addFund() {
      if (this.requestInProgress) return;
      this.$validator.errors.clear();

      return this.waitRequest(() => {
        return this.addFunds({
          amount: this.form.amount.replace("$", "").replaceAll(",", ""),
          payment_method_id: this.selected.id,
        })
          .then(() => {
            this.fetchWalletHistory().catch(this.checkErrors);
            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    successReport() {
      this.$popup.open("ConfirmPopup", {
        button: "Ok",
        title: this.$t("general.popups.confirm.title"),
        text: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-funds {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__field {
    margin-bottom: em(6);
  }

  &__settings {
    //display: flex;
  }

  &__radio-row {
    margin-bottom: em(20);
  }

  &__input {
    margin-top: em(10);
  }

  &__button {
    margin-bottom: em(10);
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__image {
    display: inline-block;
    width: 29px;
    height: 20px;
  }

  &__plus {
    margin-left: em(12);
    margin-bottom: em(37);
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-bottom: em(37);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--base {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $app-black;
    }

    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--button {
      color: $white;
      font-weight: 700;
    }

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }
}

.button-plus {
  display: flex;
  align-items: center;

  &__error {
    position: absolute;
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(10);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
