import Vue from 'vue';
import Vuex from 'vuex';
import coreState from './coreState';
import modules from './module';
import adminModules from './adminModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: coreState.state,
  getters: coreState.getters,
  actions: coreState.actions,
  mutations: coreState.mutations,

  modules: {
    ...modules,
    ...adminModules,
  },
});
