<template>
  <ConfirmPopupLayout
    class="content-preferences-popup"
    :title="$t('general.popups.contentPreferences.title')"
    :button-text="$t('general.popups.contentPreferences.buttonClose')"
    :custom-close="customClose"
  >
    <div class="content-preferences-popup__content">
      <div>
        <span
          class="content-preferences-popup__font content-preferences-popup--text"
        >
          {{ $t("general.popups.contentPreferences.text") }}
        </span>
      </div>

      <div class="content-preferences-popup__settings">
        <CRadio
          v-model="selected"
          v-for="(item, index) in settings"
          :key="`${index + '-group'}`"
          :name="item.name"
          :value="item"
          group-name="settings"
          input-type="checkbox"
          class="content-preferences-popup__button"
        >
          <template #default="{ isActive, value }">
            <div
              class="button content-preferences-button"
              :class="{ 'content-preferences-button--active': isActive }"
            >
              <img
                v-if="value.image"
                :src="value.image"
                alt=""
                class="content-preferences-button__image"
              />
              &nbsp;
              <span class="content-preferences-button__font">
                {{ value.name }}
              </span>
            </div>
          </template>
        </CRadio>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CRadio from "@/features/ui/CRadio.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ContentPreferencesPopup",
  mixins: [waitRequest, validationError],
  components: { CRadio, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      isNSFWContent: "flows/isNSFWContent",
      flowSettings: "flows/settings",
      contentSettings: "flows/contentSettings",
    }),
  },

  data() {
    return {
      selected: [],
      settings: [],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      updateFlowSettings: "flows/updateFlowSettings",
    }),

    initState() {
      this.settings = this.flowSettings?.categories || [];
      this.selected = this.settings.filter((item) =>
        this.contentSettings.flow_settings?.categories?.includes(item.id)
      );
    },

    async customClose() {
      if (this.settings.length) {
        const categories = this.selected.map((item) => item.id);
        this.updateFlowSettings({
          data: categories,
          key: "categories",
        });

        this.setSettings();
      }

      this.$popup.close();
    },

    successCallback() {
      console.log("successCallback");
    },

    failureCallback(value) {
      console.log(value);
    },

    setSettings() {
      if (this.requestInProgress) return;

      const settings = {
        categories: this.contentSettings.flow_settings.categories,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/profile/settings",
          settings,
          () => {},
          () => {
            this.settings = {};
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content-preferences-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
  }

  &__settings {
    margin-top: em(30);
    margin-bottom: em(12);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }

  &__button {
    margin-bottom: em(16);

    $gap: 13px;
    $n-items-per-row: 2;
    $n-items-on-last-row: 3;
    $n-gap-row: inner-gap($n-items-per-row, $gap);
    $n-gap-last-row: inner-gap($n-items-on-last-row, $gap);

    width: calc((100% - $n-gap-row) / $n-items-per-row);

    // change size on last 3 items
    &:nth-last-child(-n + #{$n-items-on-last-row}) {
      width: calc((100% - $n-gap-last-row) / $n-items-on-last-row);
    }
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}

.content-preferences-button {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }

  &__image {
    max-width: em(14);
    max-height: em(14);
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
