import { toFlatObject } from "@/tools/helpers";

export default {
  methods: {
    checkErrors(error) {
      const errorResponse = error?.response;
      const statusVariants = {
        errors: 422,
        notFound: 404,
        permission: 403,
        notAllowed: 405,
        serverError: 500,
        manyRequests: 429,
        conflict: 409,
        unauthorized: 401,
        toLargeContent: 413,
      };

      const errorObject = errorResponse?.data;
      const status = errorResponse?.status;

      switch (status) {
        case statusVariants.errors:
          this.addErrors(errorObject.errors);
          break;

        case statusVariants.conflict:
        case statusVariants.manyRequests:
        case statusVariants.notAllowed:
        case statusVariants.permission:
        case statusVariants.unauthorized:
        case statusVariants.toLargeContent:
          if (errorObject.redirect_to) {
            // if checkErrors called in popup context,
            // then on redirect close them (popup)
            this.$popup.close();
          }

          this.$popup.open(
              "ErrorPopup",
              {
                icon: "error",
                message: errorObject.message,
              },
              2
          );
          break;
        case statusVariants.notFound:
          this.$router.push( '/404')
          break;

        case statusVariants.serverError:
          this.$popup.open(
            "ErrorPopup",
            {
              icon: "error",
              message: errorObject.message,
            },
            2
          );
          break;

        default:
          throw error;
      }
    },

    addErrors(errors, form) {
      Object.keys(errors).forEach((el) => {
        this.addError(el, errors[el][0]);
      });

      if (form) this.$nextTick(() => this.scrollToError(form));
    },

    addError(fieldName, message) {
      this.errors.add({ field: fieldName, msg: message });
    },

    haveEmptyField(object) {
      const flatObject = toFlatObject(object);
      return Object.values(flatObject).some(
        (item) => typeof item !== "number" && !item
      );
    },
  },
};
