import axios from "axios";
import * as Upchunk from "@mux/upchunk/dist/upchunk";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import {
  availableVideoTypes,
  availableImagesTypes,
  postVideoMaxLength,
} from "@/config/video";
import { apiUrl } from "@/config/apiUrl";

const downloadAttempts = 10;
const downloadInterval = 5000;
export default {
  mixins: [waitRequest, validationError],
  data() {
    return {
      token: this.$store.state.token,
      headers: {
        Authorization: `Bearer ${this.$store.state.token}`,
      },

      validationConfig: { maxDuration: postVideoMaxLength },
      inputFileRef: null,
      adminUser: null,
      tempVideoObject: {
        id: Math.random(),
        type: 1,
        created_at: "",
        url: {
          id: "",
          url: "",
          jwt: "",
        },
        screenshot: {
          id: null,
          url: null,
          jwt: null,
        },
        progress: 1,
        isTemp: true,
        meta: {
          length: 0,
        },
      },
    };
  },
  methods: {
    setUser(id) {
      this.adminUser = id;
    },
    setupMuxInput(ref) {
      this.inputFileRef = ref;
    },
    clearInputData() {
      this.inputFileRef.value = "";
    },
    async muxUploadVideo(file) {
      try {
        await this.validateVideo(file);
      } catch (error) {
        console.error("validate video error", error.message);
        return;
      }

      try {
        const videoUploadUrlData = await this.getVideoUploadUrl();
        this.addPlaceholderCard();
        this.uploadVideo(file, videoUploadUrlData);
      } catch (error) {
        console.log(`😱 Creating authenticated upload url failed: ${error}`);
      }
    },

    async getVideoUploadUrl() {
      const data = this.adminUser ? { user_id: this.adminUser } : null;

      return this.waitRequest(() => {
        return axios.post(`${apiUrl}/media/get-video-upload-url`, data, {
          headers: this.headers,
        });
      }).catch(this.checkErrors);
    },

    uploadVideo(file, response) {
      const upload = Upchunk.createUpload({
        endpoint: response.data.data.url,
        file, // the file object with all your video file’s data
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      upload.on("error", (error) => {
        console.error("💥 🙀", error.detail);
      });

      upload.on("progress", (progress) => {
        this.tempVideoObject.progress = parseInt(progress.detail) || 0;
      });

      upload.on("success", async () => {
        this.fetchMedia(response);
      });
    },

    async validateVideo(file, config = this.validationConfig) {
      return new Promise((resolve, reject) => {
        if (file.type === "video/mp4" || file.type === "video/quicktime") {
          const video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);

            const duration = video.duration;
            this.tempVideoObject.meta.length = duration;

            if (duration > config.maxDuration) {
              this.$popup.open(
                "ErrorPopup",
                {
                  title: "Video to long",
                  message: `Videos duration ${
                    video.duration / 60
                  } minutes long. The maximum allowed duration for a video is ${
                    postVideoMaxLength / 60
                  } minutes`,
                },
                2
              );
              reject(new Error("Video to long"));
            } else {
              resolve();
            }
          };

          video.onerror = () => {
            reject(new Error("Download video error"));
          };

          video.src = URL.createObjectURL(file);
        } else {
          this.$popup.open(
            "ErrorPopup",
            {
              title: `Wrong file type format: ${file.type}`,
              message: `
              <br> available video types is ${availableVideoTypes.toString()}
              <br><br> available images types is ${availableImagesTypes.toString()}`,
            },
            2
          );
          reject(new Error("Wrong video format"));
        }
      });
    },

    fetchMedia(response) {
      let attempts = 0;

      const intervalId = setInterval(async () => {
        try {
          const responseMedia = await this.getMedia(response);
          let media = responseMedia.data;
          const hasVideo = media?.url?.url;
          if (!hasVideo) throw Error("No video");

          media = this.updateMedia(media, responseMedia);

          this.removePlaceholderCard();
          this.addVideoCard(media);

          clearInterval(intervalId);
        } catch (error) {
          attempts++;

          if (attempts >= downloadAttempts) {
            clearInterval(intervalId);
            this.removePlaceholderCard();

            this.$popup.open(
              "ErrorPopup",
              {
                title: "Download video error",
                message: `Please try again. Failed to download file after ${downloadAttempts} attempts`,
              },
              2
            );
            console.error(
              `Failed to download file after ${downloadAttempts} attempts`
            );
          }
        }
      }, downloadInterval);
    },

    async getMedia(response) {
      return this.waitRequest(() => {
        return axios.get(`${apiUrl}/media/${response.data.data.id}`, {
          headers: this.headers,
        });
      }).catch(this.checkErrors);
    },

    updateTempVideoObject() {
      this.tempVideoObject.id = Math.random();
      this.tempVideoObject.meta.length = 0;
    },

    addPlaceholderCard() {
      this.media.push(this.tempVideoObject);
    },

    removePlaceholderCard() {
      this.media = this.media.filter(
        (item) => item.id !== this.tempVideoObject.id
      );
      this.updateTempVideoObject();
      this.clearInputData();
    },

    updateMedia(media, responseMedia) {
      media.screenshot = responseMedia.data?.thumbs?.[0].url;
      media.meta = { length: this.tempVideoObject?.meta?.length || 0 };

      return media;
    },

    addVideoCard(video) {
      this.media.push(video);
    },
  },
};
