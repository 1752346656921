<template>
  <transition
    name="fade"
    @before-enter="setupPopup"
    @after-leave="setupPopup"
    @after-enter="setFocus"
  >
    <div
      v-if="popup"
      ref="popup"
      tabindex="-1"
      class="popup"
      :class="popupClass"
      @click.self="close(true)"
      @mouseup="setWrapperClicked(false)"
    >
      <transition :name="popupOverlay.animation" mode="out-in">
        <div
          class="popup__overlay"
          :class="{
            [`popup__overlay--${popupOverlay.class}`]: popupOverlay.class,
          }"
          @click="close"
        />
      </transition>

      <transition
        :name="popupAnimation"
        mode="out-in"
        @before-enter="scrollToTop"
        @after-leave="setPopupName"
      >
        <div
          v-if="showPopupComponent"
          :key="popup.name"
          class="popup__component-wrapper"
          @mousedown="setWrapperClicked(true)"
        >
          <component
            :is="popup.name"
            ref="componentIs"
            class="popup__component"
            :class="popupComponentClass"
            :data="popup.data"
            @close="close"
            v-click-outside="popupOverlayClickAction"
          >
            <template #close>
              <button
                type="button"
                class="button popup__close"
                name="cross"
                @click="close"
              >
                <CIcon name="close" class="popup__icon popup__icon--cross" />
              </button>
            </template>
          </component>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import CIcon from "@/features/ui/CIcon.vue";

import InfoPopup from "@/features/components/popups/InfoPopup.vue";
import ErrorPopup from "@/features/components/popups/ErrorPopup.vue";
import WhatReferralPopup from "@/features/components/popups/WhatReferralPopup.vue";
import AgeConfirmPopup from "@/features/components/popups/AgeConfirmPopup.vue";
import LoginPopup from "@/features/components/popups/LoginPopup.vue";
import SignUpPopup from "@/features/components/popups/SignUpPopup.vue";

import ResetPasswordPopup from "@/features/components/popups/auth/ResetPasswordPopup.vue";
import LanguagePopup from "@/features/components/popups/profile/LanguagePopup.vue";
import ReportPopup from "@/features/components/popups/ReportPopup.vue";
import ConfirmPopup from "@/features/components/popups/ConfirmPopup.vue";
import UnsubscribePopup from "@/features/components/popups/UnsubscribePopup.vue";
import SubscriptionPopup from "@/features/components/popups/SubscriptionPopup.vue";
import TipPopup from "@/features/components/popups/TipPopup.vue";
import UpdatePostPricePopup from "@/features/components/popups/UpdatePostPricePopup.vue";
import AddFundsPopup from "@/features/components/popups/AddFundsPopup.vue";
import AddMediaPopup from "@/features/components/popups/AddMediaPopup.vue";
import CreatePromotionPopup from "@/features/components/popups/CreatePromotionPopup.vue";
import CreateBundlePopup from "@/features/components/popups/CreateBundlePopup.vue";
import MediaPopup from "@/features/components/popups/MediaPopup.vue";
import VaultPopup from "@/features/components/popups/VaultPopup.vue";
import SchedulePopup from "@/features/components/popups/SchedulePopup.vue";
import ChoosePaymentMethodPopup from "@/features/components/popups/ChoosePaymentMethodPopup.vue";
import AddToCollectionsPopup from "@/features/components/popups/profile/AddToCollectionsPopup.vue";
import AddNewCollectionsPopup from "@/features/components/popups/profile/AddNewCollectionsPopup.vue";

// Flows
import ConfirmFlowNSFWPopup from "@/features/containers/Flow/popups/ConfirmFlowNSFWPopup.vue";
import VideoCountPopup from "@/features/containers/Flow/popups/VideoCountPopup.vue";
import SettingsFlowPopup from "@/features/containers/Flow/popups/SettingsFlowPopup.vue";
import CreatorPreferencesPopup from "@/features/containers/Flow/popups/CreatorPreferencesPopup.vue";
import ContentPreferencesPopup from "@/features/containers/Flow/popups/ContentPreferencesPopup.vue";
import FlowCommentsPopup from "@/features/containers/Flow/popups/FlowCommentsPopup.vue";
import AddCardPopup from "@/features/containers/my-cards/popups/AddCardPopup.vue";

import PreviewPostPopup from "@/features/containers/post-create/popups/PreviewPostPopup.vue";
import FlowPreferencesPopup from "@/features/containers/post-create/popups/FlowPreferencesPopup.vue";
import LockPopup from "@/features/containers/post-create/popups/LockPopup.vue";
import LocationPopup from "@/features/containers/post-create/popups/LocationPopup.vue";
import ExpirationPopup from "@/features/containers/post-create/popups/ExpirationPopup.vue";

import { isMobile } from "@/tools/helpers";
import { debounce } from "lodash";

let popup;

const overlayType = {
  customClose: {
    values: [
      "AgeConfirmPopup",
      "AddCardPopup",
      "AddMediaPopup",
      "SchedulePopup",
    ],
    modifier: {
      class: "info",
      animation: "fade",
      customClose: true,
    },
  },

  info: {
    values: [
      "InfoPopup",
      "ErrorPopup",
      "WhatReferralPopup",
      "ResetPasswordPopup",
      "LanguagePopup",
      "ConfirmFlowNSFWPopup",
      "VideoCountPopup",
      "SettingsFlowPopup",
      "CreatorPreferencesPopup",
      "ContentPreferencesPopup",
      "FlowCommentsPopup",
      "ReportPopup",
      "ConfirmPopup",
      "TipPopup",
      "UpdatePostPricePopup",
      "AddFundsPopup",
      "CreatePromotionPopup",
      "CreateBundlePopup",
      "MediaPopup",
      "VaultPopup",
      "ChoosePaymentMethodPopup",
      "PreviewPostPopup",
      "FlowPreferencesPopup",
      "LockPopup",
      "AddNewCollectionsPopup",
      "LoginPopup",
      "SignUpPopup",
    ],
    modifier: {
      class: "info",
      animation: "fade",
      customClose: false,
    },
  },
};

const defaultOverlay = {
  class: "info",
  animation: "fade",
};

const typesData = {
  // position
  center: {
    values: [
      "InfoPopup",
      "ErrorPopup",
      "WhatReferralPopup",
      "ResetPasswordPopup",
      "ConfirmFlowNSFWPopup",
      "VideoCountPopup",
      "CreatorPreferencesPopup",
      "ContentPreferencesPopup",
      "ConfirmPopup",
      "UnsubscribePopup",
      "SubscriptionPopup",
      "AgeConfirmPopup",
      "PreviewPostPopup",
      "FlowPreferencesPopup",
      "ExpirationPopup",
      "AddNewCollectionsPopup",
    ],
    modifier: "popup--center",
  },

  bottom: {
    values: [
      "LanguagePopup",
      "SettingsFlowPopup",
      "FlowCommentsPopup",
      "AddCardPopup",
      "ReportPopup",
      "TipPopup",
      "UpdatePostPricePopup",
      "AddFundsPopup",
      "CreatePromotionPopup",
      "AddToCollectionsPopup",
      "CreateBundlePopup",
      "MediaPopup",
      "VaultPopup",
      "SchedulePopup",
      "ChoosePaymentMethodPopup",
      "LockPopup",
      "LocationPopup",
      "LoginPopup",
      "SignUpPopup",
      "AddMediaPopup",
    ],
    modifier: "popup--bottom",
  },

  right: {
    values: [],
    modifier: "popup--right",
    customClose: false,
  },

  left: {
    values: [],
    modifier: "popup--left",
    customClose: false,
  },
};

const typesPopupAnimation = {
  modal: {
    values: [
      "LanguagePopup",
      "InfoPopup",
      "ResetPasswordPopup",
      "ConfirmFlowNSFWPopup",
      "VideoCountPopup",
      "SettingsFlowPopup",
      "FlowCommentsPopup",
      "AddCardPopup",
      "ReportPopup",
      "TipPopup",
      "UpdatePostPricePopup",
      "AddFundsPopup",
      "CreatePromotionPopup",
      "AddToCollectionsPopup",
      "CreateBundlePopup",
      "MediaPopup",
      "VaultPopup",
      "ChoosePaymentMethodPopup",
      "LockPopup",
      "LocationPopup",
    ],
    modifier: "modal",
  },
  modalLeft: {
    values: [],
    modifier: "modal-left",
    customClose: false,
  },
  modalRight: {
    values: [],
    modifier: "modal-right",
    customClose: false,
  },
};

export default {
  name: "Popup",
  directives: {
    ClickOutside,
  },
  components: {
    CIcon,
    InfoPopup,
    ErrorPopup,
    WhatReferralPopup,
    ResetPasswordPopup,
    LanguagePopup,
    ReportPopup,
    TipPopup,
    UpdatePostPricePopup,
    AddFundsPopup,
    AddMediaPopup,
    CreatePromotionPopup,
    CreateBundlePopup,
    MediaPopup,
    VaultPopup,
    SchedulePopup,
    ChoosePaymentMethodPopup,
    ConfirmPopup,
    UnsubscribePopup,
    SubscriptionPopup,
    AgeConfirmPopup,
    // Flow
    ConfirmFlowNSFWPopup,
    VideoCountPopup,
    SettingsFlowPopup,
    CreatorPreferencesPopup,
    ContentPreferencesPopup,
    FlowCommentsPopup,
    PreviewPostPopup,
    FlowPreferencesPopup,
    LockPopup,
    LocationPopup,
    ExpirationPopup,
    AddToCollectionsPopup,
    AddNewCollectionsPopup,
    LoginPopup,
    SignUpPopup,
    AddCardPopup,
  },
  props: {
    level: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      popup: null,
      game: null,
      popupName: null,
      showPopupComponent: false,
      wrapperClicked: false,
      isLandscape: false,
    };
  },
  computed: {
    isMobile() {
      return isMobile();
    },

    currentPopupData() {
      return this.findModifier(typesData);
    },

    popupOverlay() {
      return this.findModifier(overlayType)?.[0] || defaultOverlay;
    },

    popupAnimation() {
      return this.findModifier(typesPopupAnimation)?.[0];
    },

    popupOverlayClickAction() {
      return () => this.close(this.popup?.data?.closeCallback);
    },

    popupClass() {
      return [
        `popup--${this.popupName}`,
        this.currentPopupData,
        this.$route.name,
      ];
    },

    popupComponentClass() {
      return {
        [`${this.popup?.data?.className}`]: this.popup?.data?.className,
      };
    },
  },

  mounted() {
    this.$popup.addOpen({ level: this.level, callback: this.changePopup });
    this.$popup.addClose({ level: this.level, callback: this.close });

    window.addEventListener("keydown", this.keydownHandler);
  },
  beforeDestroy() {
    this.$popup.removeOpen({ level: this.level, callback: this.changePopup });
    this.$popup.removeClose({ level: this.level, callback: this.close });

    window.removeEventListener("keydown", this.keydownHandler);
  },
  methods: {
    findModifier(data) {
      return Object.values(data)
        .filter(({ values }) => values.includes(this.popupName))
        .map((item) => item.modifier);
    },

    changePopup: debounce(function (popup) {
      if (popup && popup.name !== this.popupName) {
        this.popup = popup;

        this.$nextTick(() => {
          this.showPopupComponent = true;
        });
      } else this.close();
    }, 300),

    setWrapperClicked(value) {
      setTimeout(() => {
        this.wrapperClicked = value;
      });
    },

    close(callback) {
      if (typeof callback === "function") callback(this.closePopup);
      this.closePopup();
    },

    closePopup() {
      this.showPopupComponent = false;

      this.$nextTick(() => {
        this.popup?.onClose?.();
        this.popup = null;
      });
    },

    setPopupName() {
      this.popupName = this.popup ? this.popup.name : null;
    },

    scrollToTop() {
      const { popup } = this.$refs;
      if (popup) popup.scrollTop = 0;
    },

    setupPopup() {
      if (this.popup) {
        this.setPopupName();

        this.$nextTick(() => {
          popup = this.$refs.popup;
          this.$bodyLock.lock(popup);
        });
      } else {
        this.$bodyLock.unlock(popup);
      }
    },
    setFocus() {
      this.$refs.popup?.focus();
    },
    keydownHandler(event) {
      if (this.popupOverlay.customClose) return;

      const keyCodes = {
        esc: 27,
      };
      const { keyCode } = event;
      const keysMap = {
        [keyCodes.esc]: () => {
          this.$popup.close(this.level);
        },
      };

      if (Object.prototype.hasOwnProperty.call(keysMap, keyCode)) {
        keysMap[keyCode]?.();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";

.popup {
  $parent: &;
  position: absolute;

  // above bootstrap overlay
  z-index: 1050;

  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: background-color $time-normal, opacity $time-normal;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: background-color $time-normal, opacity $time-normal,
      transform $time-normal, backdrop-filter $time-normal;

    &--info {
      background-color: rgba(black, 0.8);
      background-image: none;
    }
  }

  &__component-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__component {
  }

  &__close {
    position: absolute;
    top: em(22);
    right: em(22);
    z-index: 100;
    width: em(40);
    height: em(40);
    padding: 0;
    color: black;
    transition: transform $time-fast;
  }

  &__icon {
    &--cross {
      width: em(40);
      height: em(40);
    }
  }

  // modifiers
  &--center {
    #{$parent} {
      &__component {
        margin: auto;
      }
    }
  }

  &--bottom {
    #{$parent} {
      &__component {
        margin: auto 0 0;

        @include media-breakpoint-up(sm) {
          margin: auto;
        }
      }
    }
  }
}
</style>
