import { availableImagesTypes, availableVideoTypes } from "@/config/video";
import mux from "@/mixins/mux";

export default {
  mixins: [mux],
  data() {
    return {
      resetUiKey: Math.random(),
      media: [],
      imagesMediaType: availableImagesTypes.toString(),
      mediaTypes:
        availableVideoTypes.toString() + availableImagesTypes.toString(),
    };
  },
  computed: {
    popupConfig() {
      return {
        uploadDeviceCallback: this.uploadFromDevice,
        mediaClickCallback: this.mediaClick,
        addMediaCallback: this.addMedia,
        removeMediaByIdCallback: this.removeMediaById,
        media: this.media,
      };
    },
  },
  methods: {
    mediaPopup() {
      this.$popup.open("MediaPopup", {
        ...this.popupConfig,
      });
    },
    vaultPopup(flowRequestType = undefined) {
      this.$popup.open("VaultPopup", {
        ...this.popupConfig,
        flowRequestType,
      });
    },
    addMedia(media) {
      this.media.push(media);
    },
    removeMediaById(id) {
      this.media = this.media.filter((item) => item.id !== id);
    },
    mediaChange(data) {
      console.log("media chan", data);
    },
    mediaClick() {
      this.$refs.videoInput.click();
    },
    uploadFromDevice(uploadEvent) {
      const file = uploadEvent?.target?.files[0];

      const isVideoFile = availableVideoTypes.includes(file?.type);
      if (isVideoFile) {
        this.muxUploadVideo(file);
        return;
      }

      const isImages = availableImagesTypes.includes(file?.type);
      if (isImages) {
        this.$refs.uploader.submitFile(file);
        return;
      }

      this.$refs.file.value = "";
      this.$popup.open(
        "ErrorPopup",
        {
          title: `Wrong file type format: ${file.type}`,
          message: `
        <br> available video types is ${availableVideoTypes.toString()}
        <br><br> available images types is ${availableImagesTypes.toString()}`,
        },
        2
      );
    },
  },
};
