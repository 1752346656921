<template>
  <BasePopupLayout :title="$t('general.popups.comments.title')">
    <div class="flow-comments-popup">
      <AppInfo
        class="flow-comments-popup__info"
        :text="$t('general.popups.flowComments.info')"
      />

      <AppComments
        :post-id="data.postId"
        :callback-on-message="data.callbackOnMessage"
        :callback-on-message-remove="data.callbackOnMessageRemove"
      />
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import AppComments from "@/features/containers/Comments/AppComments.vue";

export default {
  name: "FlowCommentsPopup",
  components: {
    AppComments,
    AppInfo,
    BasePopupLayout,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  data() {
    return {
      comments: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.flow-comments-popup {
  margin: em(0) em(-13) em(-17) em(-14);

  &__info {
    margin: em(0) em(13) em(17) em(14);
  }
}
</style>
