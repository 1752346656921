import Vue from "vue";
import en from "vee-validate/dist/locale/en";
import VeeValidate, { Validator } from "vee-validate";

import dictionaryEn from "@/locales/en";

Validator.localize("en", en);

Vue.use(VeeValidate, {
  locale: "en",
  dictionary: {
    en: dictionaryEn.veeValidate,
  },
  useConstraintAttrs: false,
  events: "change|blur",
});
