<template>
  <ConfirmPopupLayout
    class="expirations-popup"
    :title="$t('general.popups.expiration.title')"
    :custom-close="customClose"
  >
    <div class="expirations-popup__content">
      <transition name="fade">
        <div class="expirations-popup__settings">
          <CRadio
            v-model="expire"
            v-for="(item, index) in expireOptions"
            :key="`${index + '-tab'}`"
            :name="item.name"
            :value="item"
            group-name="time-tabs"
            class="time-tabs"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="time-tabs__item"
                :class="{
                  'time-tabs__item--active': isActive,
                  'time-tabs__item--disabled': item.disabled,
                }"
              >
                <span class="time-tabs__font"> {{ tab.text }} </span>
              </div>
            </template>
          </CRadio>
        </div>
      </transition>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CRadio from "@/features/ui/CRadio.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ExpirationPopup",
  mixins: [waitRequest, validationError],
  components: { CRadio, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      expirations: "postCreate/expirations",
    }),
  },

  data() {
    return {
      expire: { id: 0, text: this.$tc("general.x-days", 0) },
      expireOptions: [
        { id: 0, text: this.$tc("general.x-days", 0) },
        { id: 1, text: this.$t("general.popups.expiration.options.24") },
        { id: 3, text: this.$t("general.popups.expiration.options.3") },
        { id: 7, text: this.$t("general.popups.expiration.options.7") },
      ],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setExpirations: "postCreate/setExpirations",
    }),

    customClose() {
      this.setExpirations({ expirations: this.expire.id });

      this.$popup.close();
    },

    initState() {
      this.expire = this.expireOptions.find(
        (item) => item.id === this.expirations
      );
    },
  },
};
</script>

<style scoped lang="scss">
.expirations-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
  }

  &__settings {
    margin-top: em(12);
    margin-bottom: em(12);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

}

.expirations-button {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}

.time-tabs {
  &__icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: em(5);
    width: em(16);
    height: em(16);
  }

  &:not(:first-child) {
    margin-left: em(16);
  }

  &__item {
    display: inline-flex;
    align-items: center;
    padding: em(7) em(8);
    background-color: $app-gray-4;
    border-radius: em(5);
    color: $black;
    transition: color $time-normal $ease, background-color $time-normal $ease;

    &--active {
      background-color: $app-blue;
      color: $white;
    }
  }

  &__font {
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
