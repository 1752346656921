<template>
  <div class="c-swiper swiper" :class="{ 'slider--free': isFreeMode }">
    <div
      ref="swiper"
      class="swiper-container c-swiper__container"
      :class="{ [`c-swiper__container--${containerClass}`]: containerClass }"
    >
      <slot name="static" v-if="staticView" />
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper c-swiper__wrapper" v-else>
        <!-- Slides -->
        <div
          v-for="(item, index) in items"
          :key="`${index}`"
          class="swiper-slide c-swiper__slide"
          :class="{
            'swiper-slide-active c-swiper__slide--active':
              currentIndex === index,
          }"
        >
          <slot name="slide" :itemData="item" :index="index" :swiper="swiper" />
        </div>

        <div
          v-if="this.$slots.appendSlide"
          class="swiper-slide c-swiper__slide"
        >
          <slot name="appendSlide" />
        </div>
      </div>

      <div
        v-if="hasPagination"
        class="swiper-pagination c-swiper__pagination"
      />

      <button
        v-if="hasNavigation"
        type="button"
        class="swiper-button-prev c-swiper__button c-swiper__button--prev"
        :class="{ 'c-swiper__button--disabled': isBeginning }"
        @click="slidePrev"
      >
        <CIcon
          class="c-swiper__icon c-swiper__icon--prev"
          :name="customArrow"
        />
      </button>

      <button
        v-if="hasNavigation"
        type="button"
        class="swiper-button-next c-swiper__button c-swiper__button--next"
        :class="{
          'c-swiper__button--disabled': isEnd,
        }"
        @click="slideNext"
      >
        <CIcon
          class="c-swiper__icon c-swiper__icon--next"
          :name="customArrow"
        />
      </button>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Navigation, EffectFade, Pagination, Mousewheel } from "swiper/modules";

Swiper.use([Navigation, EffectFade, Pagination, Mousewheel]);
import "swiper/swiper-bundle.css";
import { merge } from "lodash";

import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "CSwiper",
  components: { CIcon },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    sliderConfig: {
      type: Object,
      default: () => ({}),
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: undefined,
    },
    customArrow: {
      type: String,
      default: "arrow",
    },

    staticView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiper: null,
      currentIndex: 0,
      isEnd: false,
      isBeginning: true,
    };
  },
  computed: {
    sliderOptions() {
      const defaultConfig = {
        slidesPerView: "auto",
        speed: 1000,
        // arrows: true,
        grabCursor: true,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: (slide) => {
            // handle once on active slide
            if (this.currentIndex === slide.activeIndex) return;

            const eventsConfig = this.getSliderEventsConfig(slide)

            this.$emit("slideChangeTransitionStart", eventsConfig);
          },
          slideChangeTransitionEnd: (slide) => {
            const eventsConfig = this.getSliderEventsConfig(slide)

            this.$emit("slideChangeTransitionEnd", eventsConfig);
          },
        },
      };

      if (this.hasPagination) {
        defaultConfig.pagination = {
          el: ".c-swiper__pagination",
          bulletActiveClass:
            "swiper-pagination-bullet-active c-swiper__bullet--active",
          bulletClass: "swiper-pagination-bullet c-swiper__bullet",
          clickable: true,
        };
      }

      return merge(defaultConfig, this.sliderConfig);
    },
    hasNavigation() {
      return this.sliderOptions?.navigation && this.items.length > 1;
    },
    isFreeMode() {
      return this.sliderOptions?.freeMode;
    },

    virtual() {
      return this.sliderOptions;
    },
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, this.sliderOptions);
  },
  methods: {
    slidePrev() {
      this.swiper?.slidePrev();
    },

    slideNext() {
      this.swiper?.slideNext();
    },

    getSliderEventsConfig(slide) {
      this.currentIndex = slide.activeIndex;

      this.isEnd = slide.isEnd;
      this.isBeginning = slide.isBeginning;

      let totalSlides = slide.slides.length;
      let slidesLeftToEnd = totalSlides - slide.activeIndex - 1;

      return {
        index: this.currentIndex,
        isBeginning: this.isBeginning,
        isEnd: this.isEnd,
        item: this.items[this.currentIndex],
        slidesLeftToEnd,
      }
    }
  },
};
</script>

<style scoped lang="scss">
.c-swiper {
  $parent: &;

  position: relative;
  width: 100%;

  &__wrapper {
    height: 100%;
  }

  &__container {
    height: 100%;
    position: static;
    overflow: visible;
  }

  &__slide {
    width: auto;
  }

  &__button {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(64);
    height: em(64);
    margin: 0;
    border: none;
    border-radius: 50%;
    transition: opacity $time-slow;
    top: 50%;
    transform: translate(0,-50%);

    &::before,
    &::after {
      content: none;
    }

    &:focus,
    &:active {
      #{$parent}__icon {
        &--prev,
        &--next {
          //circle {
          //  fill: red;
          //  stroke: red;
          //}

          //path {
          //  fill: red;
          //}
        }
      }
    }

    @include hover {
      #{$parent}__icon {
        &--prev,
        &--next {
          //circle {
          //  fill: red;
          //  stroke: red;
          //}
        }
      }
    }

    & + & {
      margin-left: em(24);
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
      touch-action: none;
    }

    &--prev {
      position: absolute;
      left: 0;
    }

    &--next {
      position: absolute;
      right: 0;
    }
  }

  &__icon {
    &--prev {
      width: em(47);
      height: em(32);
      transform: rotate(-180deg);
    }

    &--next {
      width: em(47);
      height: em(32);
    }
  }

  &__font {
    &--count {
      font-size: em(12);
      line-height: 1;
    }
  }

  &__pagination {
    bottom: em(10);
    left: 0;
    z-index: 1050;
    display: flex;
    gap: em(20);
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: default;

    ::v-deep #{$parent} {
      &__bullet {
        width: em(6);
        height: em(6);
        background-color: $white;
        cursor: pointer;
        opacity: 0.8;
        transition: all $time-fast;

        &--active {
          width: em(10);
          height: em(10);
          background-color: $app-blue;
          opacity: 1;
        }
      }
    }
  }
}
</style>
