<template>
  <b-row class="users">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.requests") }}
          </h5>
<!--          <b-nav pills>-->
<!--            <b-nav-item-->
<!--              to="/admin/requests/creators"-->
<!--              exact-->
<!--              exact-active-class="active"-->
<!--            >-->
<!--              {{ $t("admin.creators") }}-->
<!--            </b-nav-item>-->
<!--            <b-nav-item-->
<!--              to="/admin/requests/partners"-->
<!--              exact-->
<!--              exact-active-class="active"-->
<!--            >-->
<!--              {{ $t("admin.partners") }}-->
<!--            </b-nav-item>-->
<!--          </b-nav>-->
        </div>
      </b-row>
      <b-row>
        <b-table
          id="table"
          hover
          ref="table"
          responsive
          :items="loadRequests"
          :fields="fields"
          per-page="6"
          class="small nowrap"
          :current-page="page"
        >
          <template #cell(avatar)="{ item }">
            <b-avatar
              v-if="item && item.user"
              :src="item.user.avatar"
              :to="item.user.url"
              size="sm"
            />
          </template>
          <template #cell(username)="{ item }">
            <b-link v-if="item && item.user" :to="item.user.url">{{
              item.user.username
            }}</b-link>
          </template>
          <template #cell(name)="{ item }">
            <span v-if="item.user"> {{ item.user.name }}</span>
          </template>
          <template #cell(status)="{ item }">
            <span v-if="item.user"> {{ item.getStatusName() }} </span>
          </template>
          <template #cell(actions)="{ item }">
            <div v-if="item.user">
              <b-button
                v-if="!item.isApproved()"
                @click.prevent="approve(item)"
                variant="primary"
                size="sm"
                class="mr-1 w-auto"
              >
                {{ $t("admin.approve") }}
              </b-button>

              <b-button
                v-if="item.isPending()"
                @click.prevent="decline(item)"
                variant="danger"
                size="sm"
                class="w-auto"
              >
                {{ $t("admin.decline") }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
          align="center"
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Request from "@/components/models/Request";
export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.request-id") },
        { key: "avatar", label: this.$t("admin.avatar"), tdClass: "full" },
        { key: "username", label: this.$t("admin.username") },
        { key: "name", label: this.$t("admin.name") },
        { key: "status", label: this.$t("admin.status") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ],
    };
  },
  watch: {
    q() {
      this.reset();
    },
    type() {
      this.reset();
    },
  },
  computed: {
    type() {
      return this.$route.params.type ?? "creators";
    },
  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadRequests(ctx, callback) {
      let url = "/requests/list";
      if (this.type) {
        url += "/" + this.type;
      }
      url += "?page=" + ctx.currentPage + "&q=" + this.q;
      this.$aGet(
        url,
        (data) => {
          let requests = [];
          for (let obj of data.data) {
            const request = new Request(obj);
            requests.push(request);
          }
          this.total = data.total;
          this.perPage = data.per_page;
          callback(requests);
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    approve(request) {
      this.$aPost(
        "/requests/approve/" + this.type + "/" + request.user_id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    decline(request) {
      this.$aPost(
        "/requests/decline/" + this.type + "/" + request.user_id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>
