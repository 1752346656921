<template>
  <BasePopupLayout :title="data.title || ''">
    <div class="report-popup">
      <form class="report-popup__form" novalidate @submit.prevent="sendReport">
        <div class="report-popup__field">
          <div class="report-popup__field-name">
            <span class="report-popup__font report-popup__font--field-name">
              {{ $t("general.popups.report.field.reason.name") }}
            </span>
          </div>

          <CSelect
            v-if="reasonsList.length"
            v-model="reason"
            name="reason"
            :options="reasonsList"
            :placeholder="$t('general.popups.report.field.reason.placeholder')"
            label="name"
            theme="comments"
            class="report-popup__input"
            :searchable="false"
            :rules="'required'"
            :custom-errors="errors"
          />
        </div>

        <div class="report-popup__field">
          <div class="report-popup__field-name">
            <span class="report-popup__font report-popup__font--field-name">
              {{ $t("general.popups.report.field.comment.name") }}
            </span>
          </div>

          <CInput
            class="report-popup__input"
            type="text"
            name="comment"
            input-mode="text"
            theme="comments"
            :custom-errors="errors"
            :rules="'required'"
            v-model.trim="comment"
            :placeholder="$t('general.popups.report.field.comment.placeholder')"
          />
        </div>

        <div class="report-popup__footer">
          <CButton type="submit" variant="primary" class="report-popup__button">
            <span class="report-popup__font report-popup__font--button">
              {{ $t("general.popups.report.buttonSend") }}
            </span>
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";
import CSelect from "@/features/ui/CSelect.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SettingsFlowPopup",
  components: { CButton, CInput, BasePopupLayout, CSelect },
  mixins: [waitRequest, validationError],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({ reasonsList: "settings/reasonsList" }),
  },

  data() {
    return {
      comment: "",

      reason: null,
      selectReport: [
        { id: 1, name: "first", value: "desc" },
        { id: 2, name: "second", value: "asc" },
        { id: 3, name: "second", value: "asc" },
        { id: 4, name: "second", value: "asc" },
      ],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setSettings: "settings/setSettings",
    }),
    close() {
      this.$emit("close");
    },

    initState() {
      this.getSettings();
    },

    async sendReport() {
      const isValidComment = await this.$validator.validate("comment");
      const isValidReason = await this.$validator.validate("reason");

      if (!isValidComment || !isValidReason || this.requestInProgress) return;

      let data = {
        post_id: this.data.post_id,
        report_reason_id: this.reason?.id || 1,
        comment: this.comment,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/report",
          data,
          this.successReport,
          this.failureReport,
          this.checkErrors
        );
      });
    },

    failureReport(error) {
      console.log("error", error);
    },

    successReport() {
      this.$popup.open(
        "ErrorPopup",
        {
          callback: this.successReportCallback,
          icon: "success",
          button: "Ok",
          title: this.$t("general.popups.confirmReport.title"),
          message: this.$t("general.popups.confirmReport.text"),
        },
        2
      );
    },

    successReportCallback() {
      this.$popup.close(2)
      this.$popup.close()
    },

    getSettings() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        const url = `/collect/settings`;

        return this.$get(
          url,
          this.successSettingsCallback,
          this.failureSettingsCallback,
          this.checkErrors
        );
      });
    },

    successSettingsCallback(data) {
      this.setSettings({ settings: data?.settings });
    },

    failureSettingsCallback(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.report-popup {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__field {
    margin-bottom: em(6);
  }

  &__input {
    margin-top: em(10);
  }

  &__button {
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--button {
      color: $white;
      font-weight: 700;
    }
  }
}
</style>
