import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";

const state = () => ({
  wallet: { balance: null },
  history: { data: [] },
});


const getters = {
  wallet: (state) => state.wallet,
  history: (state) => state.history,
};

const actions = {
  fetchWallet({ commit }) {
    return axios({ method: "GET", url: "/wallet" }).then((response) => {
      commit("setWallet", response.data?.wallet);

      return response;
    });
  },

  fetchWalletHistory({ commit }, { page = 1, limit = 30 } = {}) {
    const requestQuery = {
      page,
      pre_page: limit,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/wallet/history", params }).then(
      (response) => {
        commit("setWalletHistory", response.data);

        return response;
      }
    );
  },

  addFunds({ commit }, { amount, payment_method_id } = {}) {
    console.log('addFunds', amount, payment_method_id)
    return axios({
      method: "POST",
      url: "/wallet",
      data: { amount, payment_method_id },
    }).then((response) => {
      commit("setWallet", response.data?.wallet );

      return response;
    });
  },
};

const mutations = {
  setWallet(state,  wallet) {
    state.wallet = wallet;
  },

  setWalletHistory(state, history) {
    state.history = history;
  },

  updateCards(state, card) {
    state.cards.cards.methods = [...state.cards?.cards?.methods, card];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
