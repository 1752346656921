import Hashtag from "@/components/models/Hashtag";

export default class TrendingTopic {
  id = 0;
  hashtag_id = 0;
  order = 0;
  hashtag = new Hashtag(null);

  constructor(data) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.hashtag_id = data.hashtag_id;
    this.order = data.order;
    this.hashtag = new Hashtag(data.hashtag);
  }
}
