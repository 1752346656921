<template>
  <BasePopupLayout :is-header="false" >
    <div class="login-popup">
      <div class="login-popup__title">
        <span class="login-popup__font login-popup__font--title">
          {{ $t("general.auth.login.title") }}
        </span>
      </div>

      <div class="login-popup__content">
        <AppInfo
          class="mb-3"
          text=""
          type="error"
          v-if="errors.collect('_').length"
        >
          <ul class="pl-0">
            <li
              class="list-unstyled"
              v-for="errorItem in errorsList"
              :key="errorItem"
            >
              {{ errorItem }}
            </li>
          </ul>
        </AppInfo>

        <form novalidate class="form" @submit.prevent="submitForm">
          <CInput name="_" hidden />
          <CInput
            name="email"
            type="email"
            v-model="email"
            class="form__input"
            :label="$t('general.auth.login.form.email')"
            :rules="'required|email'"
          />

          <CInput
            type="password"
            name="password"
            v-model="password"
            :label="$t('general.auth.login.form.password')"
            :rules="'required'"
          />

          <CButton
            scope="itemscope"
            variant="primary"
            class="w-100 mb-3 text-uppercase"
            type="submit"
            :disabled="hasValidationError"
          >
            <span class="">
              {{ $t("general.auth.login.button.login") }}
            </span>
          </CButton>

          <div class="d-flex small align-items-center justify-content-start">
            <CLink to="/forgot" class="font__forgot">
              {{ $t("general.forgot-password") }}
            </CLink>
          </div>
        </form>
      </div>

      <div class="login-popup__settings">
        <div class="border-top p-3 w-100">
          <div align="center">
            <button
              class="button login-popup__link"
              @click.stop="$popup.open('SignUpPopup', null, 2)"
            >
              {{ $t("general.signup") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import userLocation from "@/mixins/userLocation";
import User from "@/components/models/User";
import CLink from "@/features/ui/CLink.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";

export default {
  name: "LoginPopup",
  components: {
    CButton,
    CInput,
    CLink,
    BasePopupLayout,
    AppInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [validationError, waitRequest, userLocation],
  data() {
    return {
      email: "",
      password: "",
      key: Math.random(),
    };
  },

  computed: {
    hasValidationError() {
      return !this.email || !this.password || (
        Boolean(this.errors?.collect("password").length) ||
        Boolean(this.errors?.collect("email").length)
      );
    },
    errorsList() {
      if(!this.errors.collect('_').length) return []

      return new Set(this.errors.collect('_'))
    },
  },

  methods: {
    submitForm() {
      this.login(User.CHANNEL_EMAIL);
    },

    login(type) {
      let fields = {
        channel_type: type,
        email: this.email,
        password: this.password,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/auth/login",
          fields,
          (data) => {
            this.$saveToken(data.token);
            this.$saveUser(data.user);
            location.reload();
            this.sendUserLocation();
          },
          (err) => {
            console.log(err);
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.font {
  &__forgot {
    font-size: em(12);
  }
}

@import "~@/assets/scss/vendors/_variables.scss";

.login-popup {
  margin-bottom: em(-17);
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__settings {
    margin-top: em(30);
    margin-left: em(-15);
    margin-right: em(-15);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__link {
    color: $app-blue;
  }
}
</style>
