<template>
  <component
    :is="component"
    v-bind="props"
    :active-class="activeClass"
    :exact-active-class="exactActiveClass"
  >
    <slot />
  </component>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  components: {
    RouterLink,
  },
  props: {
    to: {
      type: String,
      default: undefined,
    },

    target: {
      type: String,
      default: undefined,
    },

    type: {
      type: String,
      default: undefined,
    },

    activeClass: {
      type: String,
      default: "",
    },
    exactActiveClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    component() {
      switch (true) {
        case this.type === "a" && !!this.to:
          return "a";
        case !!this.to:
          return "router-link";
        default:
          return "button";
      }
    },
    props() {
      const props = {};

      switch (this.component) {
        case "router-link":
          props.to = this.to;
          if (this.target) props.target = this.target;
          break;
        case "a":
          props.href = this.to;
          break;
        case "button":
          props.type = this.type;
          break;
      }

      return props;
    },
  },
};
</script>
