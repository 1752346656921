var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-select",class:{
    'c-select--active': _vm.focused || !_vm.isEmptyValue,
    'c-select--disabled': _vm.disabled,
  },on:{"mousedown":_vm.openSelect}},[(_vm.inputLabel)?_c('label',{staticClass:"c-select__label"},[_c('span',{staticClass:"c-select__label-name"},[_vm._v(_vm._s(_vm.inputLabel))])]):_vm._e(),_vm._v(" "),_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules),expression:"rules"}],ref:"select",staticClass:"c-select__field",class:{
      [`c-select__field--theme--${_vm.theme}`]: _vm.theme,
      'multiselect--custom-option': _vm.$scopedSlots.option,
    },attrs:{"options":_vm.options,"show-labels":false,"name":_vm.name,"track-by":_vm.trackBy,"label":_vm.label,"allow-empty":false,"searchable":_vm.searchable,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"preselect-first":_vm.preselectFirst},on:{"select":_vm.selectHandler,"open":_vm.openHandler,"close":_vm.closeHandler},scopedSlots:_vm._u([{key:"caret",fn:function({ toggle }){return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$scopedSlots.arrow)?_vm._t("arrow"):_vm._e(),_vm._v(" "),(_vm.currentTheme.hasArrow)?_c('button',{staticClass:"c-select__arrow",class:{
            'c-select__arrow--reversed': _vm.focused,
          },attrs:{"tabindex":"-1","type":"button"},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle.apply(null, arguments)}}},[_c('CIcon',{staticClass:"c-select__icon c-select__icon--arrow",attrs:{"name":"arrow-padding"}})],1):_c('span')],2)]}},(_vm.$scopedSlots.option)?{key:"option",fn:function({ option }){return [_vm._t("option",null,{"option":option})]}}:null,(_vm.$scopedSlots.single)?{key:"singleLabel",fn:function(){return [_vm._t("single")]},proxy:true}:null,{key:"noResult",fn:function(){return [_c('span',{staticClass:"c-select__element c-select__element--disabled"},[_vm._v("\n        no result\n      ")])]},proxy:true},{key:"noOptions",fn:function(){return [_c('span',{staticClass:"c-select__element c-select__element--disabled"},[_vm._v("\n        empty list\n      ")])]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_vm._v(" "),(_vm.errors.has(_vm.name))?_c('div',{staticClass:"c-select__error"},[_c('span',{staticClass:"c-select__font c-select__font--error"},[_vm._v("\n      "+_vm._s(_vm.errors.first(_vm.name))+"\n    ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }