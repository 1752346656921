<template>
  <div
    class="info"
    :class="{
      [`info--${type}`]: type,
      'info--icon': icon,
    }"
    v-if="text || $slots.default"
  >
    <slot name="icon">
      <CIcon name="info-circle" path="payment-card" class="info__icon" v-if="icon" />
    </slot>
    <div class="info__text">
      <span class="info__font">
        <slot>
          {{ text }}
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";

const infoTypes = {
  primary: "primary",
  warning: "warning",
  error: "error",
  disabled: "disabled",
};
export default {
  name: "AppInfo",
  components: { CIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "primary",
      validator(type) {
        return Object.keys(infoTypes).includes(type);
      },
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  $parent: &;

  display: flex;
  justify-content: center;
  border: em(5);
  background: $app-pale-sky;

  &__text {
    padding: em(14) em(21);
    text-align: center;
  }

  &__icon {
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
  }

  &__font {
    color: $app-font-color-info;
    text-align: center;
    font-family: $font-default;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &--icon {
    display: flex;
    justify-content: flex-start;

    #{$parent}__text {
      padding: em(11) em(12) em(12) 0;
      text-align: left;
    }

    #{$parent}__icon {
      margin: em(11) em(8) em(12) em(12);
    }
  }

  &--error {
    background: $app-red-2;

    #{$parent} {
      &__font {
        color: $app-red-3;
      }
    }
  }

  &--disabled {
    background: #fafafa;
    border-radius: 5px;
    border: 1px solid #f3f3f3;

    #{$parent} {
      &__font {
        color: #c5c5c5;
      }
    }
  }
}
</style>
