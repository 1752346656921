import axios from "@/plugins/axios";

const state = () => ({
  settings: {
    discord: '',
    report_reasons: [],
    categories: [],
    payment_types: [],
    subscription_cancel_reason: [],
  },
});

const actions = {
  fetchSettings({ commit }) {
    return axios({ method: "GET", url: "/collect/settings" }).then((response) => {
      commit("setSettings", response.data);

      return response;
    });
  },
};

const getters = {
  settings: (state) => state.settings,
  reasonsList: (state) => state.settings.report_reasons,
  subscription_cancel_reason: (state) => state.settings.subscription_cancel_reason,
};

const mutations = {
  setSettings(state, settings) {
    state.settings = settings.settings;
  },

  updateSettings(state, settings) {
    state.settings = settings;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
