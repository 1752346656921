import Vue2TouchEvents from 'vue2-touch-events';
import Vue from 'vue';

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  swipeTolerance: 80,
  longTapTimeInterval: 600,
});
