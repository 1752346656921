<template>
  <BasePopupLayout :title="$t('general.popups.settingsFlow.title')">
    <div class="settings-flow-popup">
      <AppInfo
        class="settings-flow-popup__info"
        :text="$t('general.popups.settingsFlow.info')"
      />

      <div class="settings-flow-popup__settings">
        <button
          type="button"
          class="menu-button settings-flow-popup__button"
          v-for="button in buttonList"
          :key="button.id"
          @click.stop="button.action"
        >
          <span class="menu-button__font menu-button__font--main">
            {{ button.name }}
          </span>

          <span class="menu-button__addition">
            <img
              v-for="icon in button.icons"
              :key="icon.image"
              :src="icon.image"
              class="menu-button__icon--addition"
            />
          </span>

          <CIcon name="arrow-menu-to-right" class="menu-button__icon" />
        </button>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import CIcon from "@/features/ui/CIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "SettingsFlowPopup",
  components: { CIcon, BasePopupLayout, AppInfo },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      settingsSex: "flows/settingsSex",
      flowSettings: "flows/settings",
      contentSettings: "flows/contentSettings",
    }),

    selectedPreferences() {
      const preferences = this.selected
        .reduce((acc, item) => {
          let images = [];
          item.value.image && images.push(item.value.image);
          item.value.image_second && images.push(item.value.image_second);

          return [...acc, { images }];
        }, [])
        .reduce((acc, item) => {
          return [...acc, ...item.images];
        }, []);

      const orientations = this.selectedOrientation
        .reduce((acc, item) => {
          let images = [];
          item.value.image && images.push(item.value.image);
          item.value.image_second && images.push(item.value.image_second);

          return [...acc, { images }];
        }, [])
        .reduce((acc, item) => {
          return [...acc, ...item.images];
        }, []);

      return [...preferences,...orientations].map((item) => ({ image: item }));
    },

    buttonList() {
      return [
        {
          id: 1,
          name: this.$t("general.popups.settingsFlow.buttonCreatorPreferences"),
          icons: this.selectedPreferences,
          action: () => this.$popup.open("CreatorPreferencesPopup"),
        },
        {
          id: 2,
          name: this.$t("general.popups.settingsFlow.buttonContentPreferences"),
          icons: this.selectedCategories,
          action: () => this.$popup.open("ContentPreferencesPopup"),
        },
        {
          id: 3,
          name: this.$t("general.popups.report.title"),
          icons: [],
          action: this.reportPostOpen,
        },
      ];
    },
  },

  mounted() {
    this.initState();
  },

  data() {
    return {
      settings: [],
      selected: [],
      settingsOrientation: [],
      selectedOrientation: [],
      settingsCategories: [],
      selectedCategories: [],
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },

    reportPostOpen() {
      this.$popup.open("ReportPopup", {
        title: this.$t("general.popups.report.title"),
        post_id: this.data.postId,
      });
    },

    initState() {
      this.settings =
        Object.entries(this.settingsSex.sex).map(([key, value]) => {
          return {
            id: parseInt(key),
            value,
          };
        }) || [];

      this.selected =
        this.settings.filter((item) =>
          this.contentSettings.flow_settings?.sex_preference.includes(item.id)
        ) || [];

      this.settingsOrientation =
        Object.entries(this.settingsSex.orientation).map(([key, value]) => {
          return {
            id: parseInt(key),
            value,
          };
        }) || [];

      this.selectedOrientation =
        this.settingsOrientation.filter((item) =>
          this.contentSettings.flow_settings?.orientation_preference.includes(
            item.id
          )
        ) || [];

      this.settingsCategories = this.flowSettings.categories || [];
      // Object.entries(this.flowSettings.categories).map(([key, value]) => {
      //   return {
      //     id: parseInt(key),
      //     name: value,
      //   };
      // }) || [];

      this.selectedCategories =
        this.settingsCategories.filter((item) =>
          this.contentSettings.flow_settings?.categories.includes(item.id)
        ) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-flow-popup {
  margin-bottom: em(25);
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__settings {
    margin-top: em(30);
    margin-left: em(-15);
    margin-right: em(-15);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__button {
  }

  &__font {
    &--preferences {
      color: $black;
      font-family: $font-default;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.menu-button {
  display: flex;
  justify-content: space-between;
  padding: em(16) em(16) em(17);
  border-top: 1px solid $app-gray-2;

  &:last-child {
    border-bottom: 1px solid $app-gray-2;
  }

  &__font {
    &--main {
      color: $black;
      font-family: $font-default;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__addition {
    margin-left: auto;
  }

  &__icon {
    width: em(17);
    height: em(17);
    color: $app-gray-5;

    &--addition {
      width: em(14);
      min-width: em(14);
      height: em(14);
      margin-right: em(5);
    }
  }
}

.setting-button {
  $parent: &;
  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  padding: em(15, 24) em(15, 24);

  &__font {
    color: $app-blue;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
