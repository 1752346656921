<template>
  <ConfirmPopupLayout
    class="reset-password-popup"
    :title="$t('general.auth.reset-password.popup.title')"
    :button-text="$t('general.auth.reset-password.popup.button')"
    :custom-close="customClose"
  >
    <div class="reset-password-popup__content">
      <span class="reset-password-popup__font reset-password-popup--text">
        {{ $t("general.auth.reset-password.popup.content.text-1") }}
        <br /><br />
        {{ $t("general.auth.reset-password.popup.content.text-2") }}
      </span>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";

export default {
  name: "ResetPasswordPopup",
  components: { ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    customClose() {
      return this.data.callback
    }
  },
};
</script>

<style scoped lang="scss">
.reset-password-popup {
  $parent: &;

  &__content {
    width: em(268);
    margin: 0 auto;
    padding: 0 em(10) em(16) em(10);
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}
</style>
