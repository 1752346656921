<template>
  <div id="app">
    <router-view></router-view>

    <ui-cookies />

    <Popup class="layout__popup" level="1" />
    <Popup class="layout__popup" level="2" />
  </div>
</template>
<script>
import UiCookies from "@/features/components/UiCookies.vue";
import Popup from "@/layout/Popup/Popup.vue";

import Pusher from "pusher-js";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import userLocation from "@/mixins/userLocation";

import {mapGetters} from "vuex";
import ConfirmAge from "@/services/cinfirmAge";

export default {
  components: { Popup, UiCookies },
  mixins: [waitRequest, validationError, userLocation],
  computed: {
    ...mapGetters({ isAuth: 'isAuth',   isAnonymousUser: "isAnonymousUser"}),
    swiperItems() {
      return this.$store.state.swiperItems;
    },
    swiperOptions() {
      return this.$store.state.swiperOptions;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.initState();
  },
  watch: {
    currentUser() {
      this.listen();
    },
  },
  methods: {
    initState() {
      ConfirmAge.handleConfirmAge(() => {
        this.$popup.open('AgeConfirmPopup', null, 2)
      })

      this.listen();

      if(!this.isAuth) return;

      this.sendUserLocation()
    },
    listen() {
      if (this.currentUser && !this.isAnonymousUser && !this.$store.state.pusher) {
        this.$store.state.pusher = new Pusher(
          process.env.VUE_APP_PUSHER_APP_KEY,
          {
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            authEndpoint:
              process.env.VUE_APP_API_URL +
              "/" +
              process.env.VUE_APP_API_VERSION +
              "/broadcasting/auth",
            auth: {
              headers: { Authorization: "Bearer " + this.$store.state.token },
            },
          }
        );
        this.$store.state.pusher.subscribe(
          "private-users." + this.currentUser?.id
        );
        this.$store.state.pusher.bind_global((_, data) => {
          if (data.updates) {
            this.$store.state.updates = {
              notifications: data.updates.notifications,
              messages: data.updates.messages,
            };
          }
        });
      }
    },
  },
};
</script>
