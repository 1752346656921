<template>
  <BasePopupLayout no-border :title="config.title" :custom-close="close">
    <div class="new-card">
      <div class="new-card__title">
        <span class="new-card__font new-card__font--title">
          {{ $t("general.my-cards.billing") }}
        </span>
      </div>

      <AppInfo
        class="mb-3"
        text=""
        type="error"
        v-if="errors.collect('_').length"
      >
        <ul class="pl-0">
          <li
            class="list-unstyled"
            v-for="errorItem in errorsList"
            :key="errorItem"
          >
            {{ errorItem }}
          </li>
        </ul>
      </AppInfo>

      <form>
        <b-row>
          <b-col>
            <div class="v-select-profile">
              <v-select
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected':
                    form.billing_info.country && form.billing_info.country.name,
                }"
                name="billing_info.country"
                v-model="form.billing_info.country"
                label="name"
                :options="countriesList"
                :reduce="(option) => option"
                placeholder="countries"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.billing_info.country,
                }"
              >
                {{ $t("general.my-cards.input.country") }}
              </span>

              <small
                v-if="errors && errors.has('billing_info.country')"
                class="new-card__font new-card__font--error"
              >
                {{ errors.first("billing_info.country") }}
              </small>
            </div>
          </b-col>

          <b-col>
            <div class="v-select-profile">
              <v-select
                :key="
                  form.billing_info.country && form.billing_info.country.name
                "
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected':
                    form.billing_info.country && form.billing_info.country.name,
                }"
                :disabled="!form.billing_info.country"
                name="billing_info.region"
                v-model="form.billing_info.region"
                label="name"
                :options="regions"
                :reduce="(option) => option"
                placeholder="region"

              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active':
                    form.billing_info.region && form.billing_info.region.name,
                }"
              >
                {{ $t("general.my-cards.input.region") }}
              </span>

              <small
                v-if="errors && errors.has('billing_info.region')"
                class="new-card__font new-card__font--error"
              >
                {{ errors.first("billing_info.region") }}
              </small>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <div class="new-card__input">
              <CInput
                type="text"
                name="billing_info.street"
                :custom-field-name="$t('general.my-cards.input.street')"
                v-model="form.billing_info.street"
                :custom-errors="errors"
                theme="settings"
                class="new-card__field"
                :label="$t('general.my-cards.input.street')"
                :rules="'required'"
              >
                <template #appendAfter="{ errors }">
                  <transition name="fade" mode="out-in">
                    <CIcon
                      name="check"
                      class="new-card__icon new-card__icon--check"
                      v-if="!errors && form.billing_info.street"
                    />

                    <CIcon
                      name="false"
                      class="new-card__icon new-card__icon--false"
                      v-else-if="errors"
                    />
                  </transition>
                </template>
              </CInput>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="new-card__input">
              <CInput
                type="text"
                name="billing_info.city"
                v-model="form.billing_info.city"
                :custom-errors="errors"
                :custom-field-name="$t('general.my-cards.input.city')"
                theme="settings"
                class="new-card__field"
                :label="$t('general.my-cards.input.city')"
                :rules="'required'"
              >
              </CInput>
            </div>
          </b-col>

          <b-col>
            <div class="new-card__input">
              <CInput
                type="text"
                name="billing_info.zip"
                v-mask="'#####'"
                :custom-errors="errors"
                v-model="form.billing_info.zip"
                :custom-field-name="$t('general.my-cards.input.zip')"
                theme="settings"
                class="new-card__field"
                :label="$t('general.my-cards.input.zip')"
                :rules="'required'"
              />
            </div>
          </b-col>
        </b-row>

        <div class="new-card__title">
          <span class="new-card__font new-card__font--title">
            {{ $t("general.my-cards.details") }}
          </span>
        </div>

        <b-row>
          <b-col>
            <div class="new-card__input">
              <CInput
                type="text"
                name="cardholderName"
                v-model.trim="form.cardholderName"
                :custom-errors="errors"
                :custom-field-name="$t('general.my-cards.Street')"
                theme="settings"
                class="new-card__field new-card__field--name"
                :label="$t('general.my-cards.input.nameCard')"
                :rules="'required|max:40'"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="new-card__input">
              <CInput
                :disabled="isUpdateCard"
                type="text"
                name="number"
                v-mask="'#### #### #### ####'"
                v-model.trim="form.number"
                :custom-field-name="$t('general.my-cards.Street')"
                theme="settings"
                :custom-errors="errors"
                class="new-card__field new-card__field--name"
                :label="$t('general.my-cards.input.curdNumber')"
                :rules="'required'"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="new-card__input">
              <CInput
                type="text"
                name="custom"
                v-model="expiration"
                v-mask="'## / ##'"
                :custom-field-name="$t('general.my-cards.Month / Year')"
                theme="settings"
                class="new-card__field new-card__field--bio-link"
                :label="$t('general.my-cards.input.expiration')"
              >
                <template #appendAddition>
                  <small
                    v-if="
                      (errors && errors.has('expYear')) ||
                      errors.has('expMonth')
                    "
                    class="new-card__font new-card__font--error"
                  >
                    {{ errors.first("expYear") || errors.first("expMonth") }}
                  </small>
                </template>
              </CInput>
            </div>
          </b-col>

          <b-col>
            <div class="new-card__input new-card__input--bottom">
              <CInput
                :disabled="isUpdateCard"
                type="password"
                v-model="form.cvc"
                :custom-errors="errors"
                v-mask="'###'"
                :custom-field-name="$t('general.my-cards.Street')"
                name="cvc"
                theme="settings"
                class="new-card__field new-card__field--bio-link"
                :label="$t('general.my-cards.input.cvc')"
              />
            </div>
          </b-col>
        </b-row>

        <CRadio
          :disabled="isUpdateCard"
          type="radio"
          allow-uncheck
          v-model="form.confirm"
          rules="required"
          name="confirm"
          :value="$t('general.my-cards.confirm')"
          group-name="settings"
          class="new-card__field new-card__field--radio"
        >
          <template #default="{ isActive, value }">
            <div
              class="button card-settings"
              :class="{
                'card-settings--active': isActive,
                'card-settings--disabled': isUpdateCard,
              }"
            >
              <span class="new-card__font new-card__font--confirm">
                {{ value }}
              </span>
            </div>

            <small
              v-if="errors.has('confirm')"
              class="new-card__font new-card__font--error"
            >
              {{ errors.first("confirm") }}
            </small>
          </template>
        </CRadio>

        <AppInfo
          type="disabled"
          class="new-card__info"
          icon
          :text="$t('general.my-cards.info')"
        />

        <div class="new-card__image-wrapper">
          <img src="/img/payments/payment-images.webp" class="new-card__image" />
        </div>

        <p class="new-card__description">
          <span class="new-card__font new-card__font--description">
            {{ $t("general.my-cards.description") }}
          </span>
        </p>

        <div class="button mt-3">
          <CButton
            variant="primary"
            type="button"
            class="base-button new-card__button"
            block
            @click="create"
          >
            {{ config.button }}
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CInput from "@/features/ui/СInput.vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import CButton from "@/features/ui/СButton.vue";
import CRadio from "@/features/ui/CRadio.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";

export default {
  name: "AddCardPopup",
  components: {
    AppInfo,
    CRadio,
    CButton,
    vSelect,
    CInput,
    CIcon,
    BasePopupLayout,
  },
  mixins: [validationError, waitRequest],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      expirationData: "",
      form: {
        number: null,
        expMonth: "",
        expYear: "",
        cvc: "",
        cardholderName: "",
        billing_info: {
          country: null,
          region: null,
          street: "",
          city: "",
          zip: "",
        },
        confirm: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "collect/countries",
    }),
    isUpdateCard() {
      return Boolean(this.data?.card);
    },

    errorsList() {
      if(!this.errors.collect('_').length) return []

      return new Set(this.errors.collect('_'))
    },

    config() {
      const config = {
        add: {
          title: this.$t("general.my-cards.addNewCards"),
          button: this.$t("general.my-cards.addPaymentCardButton"),
        },
        update: {
          title: this.$t("general.my-cards.updateCardTitle"),
          button: this.$t("general.my-cards.updatePaymentCardButton"),
        },
      };

      return this.isUpdateCard ? config.update : config.add;
    },
    countriesList() {
      return this.countries || [];
    },
    regions() {
      return this.form?.billing_info?.country?.regions || [];
    },
    expiration: {
      get() {
        return this.expirationData;
      },
      set(val) {
        this.expirationData = val;

        if (val.length >= 6) {
          const [expMonth, expYear] = val.split(" / ");

          this.form.expMonth = parseInt(expMonth, 10);
          this.$validator.errors.remove('expMonth')
          if (this.form.expMonth < 1 || this.form.expMonth > 12) {
            this.$validator.errors.add({
              field: "expMonth",
              name: "expMonth",
              msg: "Month must be at 1 to 12 digit!",
            });
          }

          if (expYear.length !== 2) return;

          this.form.expYear = parseInt(expYear, 10);
          this.$validator.errors.remove('expYear')
          if (this.form.expYear < 24) {
            this.$validator.errors.add({
              field: "expYear",
              name: "expYear",
              msg: "Year cant be less than 24!",
            });
          }
        }
      },
    },
  },
  watch: {
    "form.billing_info.country": {
      handler(value) {
        if (!value) {
          this.form.billing_info.region = null;
        }
      },
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchCountriesWithStates: "collect/fetchCountriesWithStates",
      createPaymentMethod: "payments/createPaymentMethod",
      updatePaymentMethod: "payments/updatePaymentMethod",
    }),
    initState() {
      this.fetchCountriesWithStates()
        .then(() => {
          if (this.isUpdateCard) {
            this.form.number = this.data.card.title
              .replaceAll("*", "")
              .padStart(7, ".");
            this.form.billing_info = this.data.card?.billing_info;
            this.form.billing_info.country = this.countriesList?.find(
              (item) => item.name === this.data.card?.billing_info.country
            );
            this.form.billing_info.region =
              this.form.billing_info.country?.regions?.find(
                (item) => item.name === this.data.card?.billing_info.region
              );
          }
        })
        .catch(this.checkErrors);
    },
    async create() {
      if (this.requestInProgress) return;

      const form = this.prepareFormData();
      this.$validator.errors.clear()

      if (!this.isUpdateCard) {
        this.waitRequest(() => {
          return this.createPaymentMethod(form)
            .then((response) => {
              this.close(response.data);
            })
            .catch(this.checkErrors);
        });
      }

      this.waitRequest(() => {
        return this.updatePaymentMethod({
          form: { ...form, confirm: null, cvc: null, number: null },
          id: this.data.card.id,
        })
          .then(() => {
            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    prepareFormData() {
      let cvc, number, confirm;
      if (!this.isUpdateCard) {
        number = parseInt(this.form.number?.replaceAll(" ", ""), 10);
        cvc = this.form.cvc;
        confirm = Boolean(this.form.confirm.length);
      }
      const country = this.form.billing_info.country?.name || "";
      const region = this.form.billing_info.region?.name || "";
      const city = this.form.billing_info.city;
      const zip = this.form.billing_info.zip;
      const street = this.form.billing_info.street;

      const expMonth = +this.form.expMonth;
      const expYear = this.form.expYear;
      const cardholderName = this.form.cardholderName;

      const form = {
        cardholderName,
        cvc,
        expMonth,
        expYear,
        confirm,
        number,
        billing_info: {
          country,
          region,
          street,
          city,
          zip,
        },
      };

      return form;
    },

    close(changedMethod) {
      if (this.data.closeCallback) {
        this.data.closeCallback({ changedMethod });
      } else {
        this.$popup.close(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-card {
  //margin: em(0) em(-13) em(-17) em(-14);
  height: 100%;
  overflow: auto;
  //overflow-x: clip;

  @include hideScroll();
  //overscroll-behavior: contain;

  &__title {
    margin-top: 19px;
    margin-bottom: 19px;
  }

  &__info {
    margin: em(0) em(13) em(17) em(14);
  }

  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__image {
    margin-top: em(29);
  }

  &__input {
    &--bottom {
    }
  }

  &__info {
    margin: 17px 0 0;
  }

  &__description {
    margin-top: em(8);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__field {
    &--name {
    }

    &--bio-link {
    }
    &--radio {
      margin-top: em(17);
    }
  }

  &__icon {
    color: $app-gray-18;

    &--camera {
      width: em(26);
      min-width: em(26);
      height: em(23);

      &--2 {
        width: em(20);
        min-width: em(20);
        height: em(18);
      }
    }

    &--cross {
      width: em(35);
      min-width: em(35);
      height: em(35);

      &--2 {
        width: em(25);
        min-width: em(25);
        height: em(25);
      }
    }

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }

  &__counter {
  }

  &__font {
    &--addition {
    }
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $black;
    }
    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: $app-font-color-placeholder;
      text-align: center;
    }

    &--confirm {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      color: $app-font-color-placeholder;
    }

    &--error {
      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
      color: $app-red;
      margin-top: 11px;
    }
  }

  &__button-wrapper {
  }

  &__button {
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }

  &--disabled {
    &:before {
      content: "";
      display: inline-block;
      width: em(16);
      min-width: em(16);
      height: em(16);
      min-height: em(16);
      background-color: transparent;
      border: 1px solid $app-gray-18;
      border-radius: 50%;
      margin-right: em(17);
      transition: background-color $time-normal $ease, border $time-normal $ease;
    }
  }
}
</style>
