<template>
  <div class="base-popup-layout">
    <div class="base-popup-layout__wrapper">
      <PopupBaseHeader
        v-if="isHeader"
        :title="title"
        @close="close"
        class="base-popup-layout__header"
      >
        <template #default>
          <slot name="addition"/>
        </template>
      </PopupBaseHeader>

      <div class="base-popup-layout__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import PopupBaseHeader from "@/features/components/popups/layout/PopupBaseHeader.vue";

export default {
  name: "BasePopupLayout",
  components: { PopupBaseHeader },
  props: {
    title: {
      type: String,
      default: "",
    },
    customClose: {
      type: Function,
      default: null,
    },
    isHeader: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    close() {
      if (this.customClose) return this.customClose();

      this.$popup.close();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";

.base-popup-layout {
  flex: 1;

  &__header {
    margin-bottom: em(20);
  }

  &__wrapper {
    width: 100%;
    border-top-left-radius: em(10);
    border-top-right-radius: em(10);
    background-color: $white;

    padding: em(14) em(13) em(17) em(14);

    @include media-breakpoint-up(sm) {
      border-bottom-left-radius: em(10);
      border-bottom-right-radius: em(10);
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 40%
  }

  @include media-breakpoint-up(lg) {
    max-width: 30%
  }
}
</style>
