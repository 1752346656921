import LayoutAdmin from "@/components/layout/admin/Layout.vue";

// import PageBookmarks from "@/components/pages/PageBookmarks.vue";
// import PagePrice from "@/components/pages/PagePrice.vue";
// import PagePaymentSuccess from "@/components/pages/PagePaymentSuccess.vue";
// import PagePaymentFailure from "@/components/pages/PagePaymentFailure.vue";
// import PageSubscriptions from "@/components/pages/PageSubscriptions.vue";
// import PagePayouts from "@/components/pages/PagePayouts.vue";
// import PagePayments from "@/components/pages/PagePayments.vue";

import PageLoginAdmin from "@/components/pages/admin/PageLogin";
import PageDashboardAdmin from "@/components/pages/admin/PageDashboard";
import PageUsersAdmin from "@/components/pages/admin/PageUsers";
import PageUsersEditAdmin from "@/components/pages/admin/PageUsersEdit";
import PageSubscriptionsAdmin from "@/components/pages/admin/PageSubscriptions";
import PagePaymentsAdmin from "@/components/pages/admin/PagePayments";
import PagePayoutsAdmin from "@/components/pages/admin/PagePayouts";
import PagePostsAdmin from "@/components/pages/admin/PagePosts";
import PageSettingsAdmin from "@/components/pages/admin/PageSettings";
import PageRequestsAdmin from "@/components/pages/admin/PageRequests";
import User from "@/components/models/User";
import VueRouter from "vue-router";
import store from "@/store";

export const routes = [
  // Admin
  {
    path: "/admin",
    component: LayoutAdmin,
    children: [
      {
        path: "/admin",
        components: {
          default: PageDashboardAdmin,
          login: PageLoginAdmin,
        },
      },
      {
        path: "/admin/users/:type?",
        component: PageUsersAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER, User.ROLE_MODERATOR_MOD],
        },
      },
      {
        path: "/admin/users/edit/:id",
        component: PageUsersEditAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER, User.ROLE_MODERATOR_MOD],
        },
      },
      {
        path: "/admin/subscriptions/:type?",
        component: PageSubscriptionsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/payments/:type?",
        component: PagePaymentsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/payouts/:type?",
        component: PagePayoutsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/posts",
        component: PagePostsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/requests/:type?",
        component: PageRequestsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/settings",
        component: PageSettingsAdmin,
        meta: {
          permissions: [User.ROLE_SUPERUSER],
        },
      },
      {
        path: "/admin/creator/:type?",
        component: () => import("@/components/pages/admin/PageCreator.vue"),
        meta: {
          permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_CONTENT_MOD,
            User.ROLE_MODERATOR_MOD,
          ],
        },
      },
      {
        path: "/admin/content/:type?",
        component: () => import("@/components/pages/admin/PageContent.vue"),
        meta: {
          permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_CONTENT_MOD,
            User.ROLE_MODERATOR_MOD,
          ],
        },
      },
    ],
  },

  // Base routes (new)
  {
    name: "login",
    path: "/login",
    component: () => import("@/pages/PageLogin.vue"),
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("@/pages/PageSignup.vue"),
  },
  {
    name: "forgot",
    path: "/forgot",
    component: () => import("@/pages/PageForgot.vue"),
  },
  {
    name: "password-reset",
    path: "/forgot-password/:code",
    component: () => import("@/pages/PagePasswordReset.vue"),
  },

  {
    path: "/email-verify/:token",
    component: () => import("@/pages/PageEmailVerify.vue"),
  },

  {
    name: "referral-user",
    path: "/r/:userId",
    component: () => import("@/pages/PageSignup.vue"),
  },

  {
    path: "/",
    component: () => import("@/layout/user/Layout.vue"),
    children: [
      {
        name: "404",
        path: "/404",
        component: () => import("@/pages/PageError.vue"),
      },

      {
        name: "my-cards",
        path: "/my-cards",
        component: () => import("@/pages/PageMyCards.vue"),
      },

      {
        name: "my-wallets",
        path: "/my-wallets",
        component: () => import("@/pages/PageMyWallets.vue"),
      },
      {
        name: "my-subscription",
        path: "/my-subscription",
        component: () => import("@/pages/PageMySubscription.vue"),
      },

      {
        name: "promotions",
        path: "/promotions",
        component: () => import("@/pages/PagePromotions.vue"),
      },

      {
        name: "statements",
        path: "/statements",
        component: () => import("@/pages/PageStatements.vue"),
      },

      {
        name: "discover",
        path: "/discover",
        component: () => import("@/pages/PageDiscover.vue"),
      },

      {
        name: "settings-menu",
        path: "/settings-menu",
        component: () => import("@/pages/PageSettingsMenu.vue"),
      },

      {
        name: "settings-account",
        path: "/settings-account",
        component: () => import("@/pages/PageSettingsAccount.vue"),
      },

      {
        name: "settings-username",
        path: "/settings/username",
        component: () => import("@/pages/PageSettingsUsername.vue"),
      },

      {
        name: "settings-phone",
        path: "/settings/phone",
        component: () => import("@/pages/PagePhone.vue"),
      },

      {
        name: "settings-delete-account",
        path: "/settings/delete-account",
        component: () => import("@/pages/PageDeleteAccount.vue"),
      },

      {
        name: "settings-twitter",
        path: "/settings/twitter",
        component: () => import("@/pages/PageSettingsTwitter.vue"),
      },

      {
        name: "settings-google",
        path: "/settings/google",
        component: () => import("@/pages/PageSettingsGoogle.vue"),
      },

      {
        name: "settings-privacy",
        path: "/settings-privacy",
        component: () => import("@/pages/PagePrivacy.vue"),
      },

      {
        name: "settings-notifications",
        path: "/settings-notifications",
        component: () => import("@/pages/PageSettingsNotifications.vue"),
      },

      {
        name: "settings-subscription",
        path: "/settings-subscription",
        component: () => import("@/pages/PageSettingsSubscription.vue"),
      },

      {
        name: "settings-bundles",
        path: "/settings-bundles",
        component: () => import("@/pages/PageSettingsBundles.vue"),
      },

      {
        name: "my-referrals",
        path: "/referrals",
        component: () => import("@/pages/PageMyReferrals.vue"),
      },

      {
        name: "queue",
        path: "/queue",
        component: () => import("@/pages/PageQueue.vue"),
      },

      {
        name: "vault",
        path: "/vault",
        component: () => import("@/pages/PageVault.vue"),
      },
      {
        name: "vault-select",
        path: "/vault/select",
        component: () => import("@/pages/PageVaultSelect.vue"),
      },

      {
        name: "become-creator",
        path: "/become-creator",
        component: () => import("@/pages/PageBecomeCreator.vue"),
      },

      {
        name: "become-creator-verify",
        path: "/become-creator-verify",
        component: () => import("@/pages/PageBecomeCreatorVerify.vue"),
      },

      {
        name: "become-creator-end",
        path: "/become-creator-end",
        component: () => import("@/pages/PageBecomeCreatorEnd.vue"),
      },

      {
        name: "become-partner",
        path: "/become-partner",
        component: () => import("@/pages/PageBecomePartner.vue"),
      },

      {
        name: "become-partner-verify",
        path: "/become-partner-verify",
        component: () => import("@/pages/PageBecomePartnerVerify.vue"),
      },

      {
        name: "become-partner-end",
        path: "/become-partner-end",
        component: () => import("@/pages/PageBecomePartnerEnd.vue"),
      },

      {
        name: "flow",
        path: "/flow",
        component: () => import("@/pages/PageFlow.vue"),
      },
      {
        name: "flow-post",
        path: "/flow/:post",
        component: () => import("@/pages/PageFlow.vue"),
      },
      {
        name: "following",
        path: "/following",
        component: () => import("@/pages/PageFollowing.vue"),
      },
      {
        name: "followers",
        path: "/followers",
        component: () => import("@/pages/PageFollowers.vue"),
      },
      {
        name: "fans",
        path: "/fans",
        component: () => import("@/pages/PageFans.vue"),
      },
      // {
      //   path: "/payments/:attach?",
      //   component: PagePayments,
      // },
      // {
      //   // old route /payout
      //   name: "wallet",
      //   path: "/wallet",
      //   component: PagePayouts,
      // },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/pages/PageNotifications.vue"),
      },
      {
        path: "/kyc/success",
        name: "kyc-success",
        component: () => import("@/pages/PageBecomeCreatorEnd.vue"),
      },
      {
        path: "/kyc/declined",
        name: "kyc-declined",
        component: () => import("@/pages/PageKYCDecline.vue"),
      },
      {
        path: "/kyc/failure",
        name: "kyc-failure",
        component: () => import("@/pages/PageKYCFailure.vue"),
      },
      // {
      //   path: "/subscriptions",
      //   component: PageSubscriptions,
      //   name: "subscriptions",
      // },
      // {
      //   path: "/payment/failure",
      //   component: PagePaymentFailure,
      // },
      // {
      //   path: "/payment/success/:gateway",
      //   component: PagePaymentSuccess,
      // },
      {
        name: "messages",
        path: "/messages",
        component: () => import("@/pages/PageMessages.vue"),
      },

      {
        path: "/messages/:id",
        name: "message",
        component: () => import("@/pages/PageChat.vue"),
      },

      {
        path: "/mass-messages",
        name: "mass-message",
        component: () => import("@/pages/PageMassMessages.vue"),
      },

      {
        path: "/mass-messages/message",
        name: "mass-message-message",
        component: () => import("@/pages/PageMassMessageChat.vue"),
      },

      //  old route /list
      {
        name: "collections",
        path: "/collections",
        component: () => import("@/pages/PageCollections.vue"),
      },
      {
        name: "selected-collection",
        path: "/collections/:id",
        component: () => import("@/pages/PageSelectedCollections.vue"),
      },
      // {
      //   path: "/bookmarks",
      //   component: PageBookmarks,
      // },
      // {
      //   path: "/settings/price",
      //   component: PagePrice,
      // },
      {
        name: "setting-email",
        path: "/settings/email",
        component: () => import("@/pages/PageEmail.vue"),
      },
      {
        name: "setting-password",
        path: "/settings/password",
        component: () => import("@/pages/PagePassword.vue"),
      },
      {
        name: "settings-my-profile",
        path: "/settings/my-profile",
        component: () => import("@/pages/PageMyProfile.vue"),
      },
      {
        name: "posts-create",
        path: "/posts/create",
        component: () => import("@/pages/PageNewPost.vue"),
      },
      {
        path: "/posts/edit/:id",
        component: () => import("@/pages/PageNewPost.vue"),
      },
      {
        name: "search",
        path: "/search",
        components: {
          default: () => import("@/pages/PageDiscover.vue"),
        },
      },
      {
        name: "user",
        path: "/:username",
        component: () => import("@/pages/PageUser.vue"),
      },
      {
        path: "/:id/:username",
        component: () => import("@/pages/PagePost.vue"),
      },
      {
        name: "index",
        path: "/",
        components: {
          default: () => import("@/pages/PageHome.vue"),
          login: () => import("@/pages/PageLogin.vue"),
        },
      },
    ],
  },
  {
    name: "error",
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.path.includes("/admin")) {
    if (to.meta.permissions && to.meta.permissions.length) {
      const userRolesList = store.state.currentUser?.role;
      const hasPermission = userRolesList.some((userRole) =>
        to.meta.permissions.includes(userRole)
      );

      if (hasPermission) next();
      else next("/admin");
    } else next();
  } else next();
});

router.beforeEach((to, from, next) => {
  const isTempAccount = store.getters["isAnonymousUser"];
  const isFlowPage = from.path.includes("/flow");
  const isToForgot = to.path === "/forgot";
  const isToLogin = to.path === "/login";
  const isUserPage = from.name === "user";

  if (isTempAccount && (isFlowPage || isUserPage)) {
    if (isToForgot || isToLogin) {
      next();
    } else {
      router.app.$popup.open("LoginPopup", {}, 2);
    }
  } else next();
});

router.beforeEach((to, from, next) => {
  if (to.name === "referral-user") {
    try {
      router.app.$store.dispatch(
        "partner/setReferralCookie",
        parseInt(to.params.userId)
      );
    } catch (err) {
      console.log("referral", err);
    }
  }
  next();
});

export default router;
