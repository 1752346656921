import User from "@/components/models/User";

export default [
    {
        url: "/admin",
        icon: "bi-bar-chart",
        text: "admin.dashboard",
        permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_CONTENT_MOD,
            User.ROLE_MODERATOR_MOD,
        ],
    },
    {
        url: "/admin/users",
        icon: "bi-people",
        text: "admin.users",
        permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_MODERATOR_MOD
        ],
    },
    {
        url: "/admin/posts",
        icon: "bi-stickies",
        text: "admin.posts",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/subscriptions",
        icon: "bi-calendar",
        text: "admin.subscriptions",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/payments",
        icon: "bi-credit-card",
        text: "admin.payments",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/payouts",
        icon: "bi-coin",
        text: "admin.payouts",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/requests/creators",
        icon: "bi-wind",
        text: "admin.requests",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/settings",
        icon: "bi-tools",
        text: "admin.settings",
        permissions: [User.ROLE_SUPERUSER],
    },
    {
        url: "/admin/content",
        icon: "bi bi-collection-play",
        text: "Content",
        permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_CONTENT_MOD,
            User.ROLE_MODERATOR_MOD,
        ],
    },

    {
        url: "/admin/creator",
        icon: "bi-emoji-wink",
        text: "Parody creators", // Direct string since it's not using $t for translation
        permissions: [
            User.ROLE_SUPERUSER,
            User.ROLE_CONTENT_MOD,
            User.ROLE_MODERATOR_MOD,
        ],
    },
]
