var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"c-input",class:{
    'c-input--disabled': _vm.disabled,
    'c-input--active': _vm.isActive,
    'c-input--error': _vm.errors.has(_vm.name),
    [`c-input--theme--${_vm.theme}`]: _vm.theme,
  }},[(_vm.label)?_c('span',{staticClass:"c-input__label"},[_c('span',{staticClass:"c-input__label-text"},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules),expression:"rules"}],staticClass:"c-input__field",class:{
      'c-input__field--password': _vm.isPassword,
      [`c-input__field--theme--${_vm.theme}`]: _vm.theme,
    },attrs:{"data-vv-as":_vm.customFieldName,"type":_vm.typeLocal,"mask":"#### #### #### ####","inputmode":_vm.inputMode,"name":_vm.name,"autocomplete":"off","required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.setFocus(true)},"blur":function($event){return _vm.setFocus(false)},"input":_vm.emitInput}},'input',_vm.$attrs,false)),_vm._v(" "),_c('span',{staticClass:"c-input__append c-input__append--after"},[_vm._t("appendAfter",null,{"errors":_vm.errors.has(_vm.name)})],2),_vm._v(" "),(_vm.isPassword && !_vm.$slots.appendAfter)?_c('button',{staticClass:"c-input__button",attrs:{"type":"button"},on:{"click":_vm.showPassword}},[_c('CIcon',{staticClass:"c-input__icon c-input__icon--password",attrs:{"name":_vm.typeLocal === 'password' ? 'eye-closed' : 'eye-open'}})],1):_vm._e(),_vm._v(" "),(_vm.clearButton)?_c('button',{staticClass:"c-input__button",attrs:{"type":"button"},on:{"click":_vm.showPassword}},[_c('CIcon',{staticClass:"c-input__icon",attrs:{"name":"close"}})],1):_vm._e(),_vm._v(" "),(_vm.isPreloader)?_c('span',{staticClass:"c-input__preloader"}):_vm._e(),_vm._v(" "),(_vm.errors.has(_vm.name) && !_vm.isCustomErrors)?_c('span',{staticClass:"c-input__error"},[_c('span',{staticClass:"c-input__font c-input__font--error"},[_vm._v("\n      "+_vm._s(_vm.errors.first(_vm.name))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.customErrors && _vm.customErrors.has(_vm.name))?_c('span',{staticClass:"c-input__error"},[_c('span',{staticClass:"c-input__font c-input__font--error"},[_vm._v("\n      "+_vm._s(_vm.customErrors.first(_vm.name))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"c-input__append c-input__append--addition"},[_vm._t("appendAddition",null,{"hasErrors":_vm.errors.has(_vm.name),"errors":_vm.errors})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }