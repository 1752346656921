<template>
  <BasePopupLayout :title="$t('general.popups.lockPopup.title')">
    <div class="lock-popup">
      <div class="lock-popup__content">
        <div class="lock-popup__settings">
          <CRadio
            v-model="selectedItem"
            v-for="(item, index) in settings"
            :key="`${index + '-group'}`"
            :name="item.name"
            :value="item"
            group-name="settings"
            class="lock-popup__radio-row"
          >
            <template #default="{ isActive, value }">
              <div
                class="button card-settings"
                :class="{ 'card-settings--active': isActive }"
              >
                <CIcon
                  :name="value.icon"
                  path="tip"
                  class="card-settings__icon"
                />

                <span class="card-settings__font">
                  {{ value.name }}
                </span>

                <span
                  class="card-settings__font card-settings__font--price"
                  v-if="value.hasPriceField"
                >
                  &nbsp;{{ value.price }}
                </span>
              </div>
            </template>

            <template #appendAfter="{ value }">
              <button v-if="value.action" type="button" @click="value.action">
                <span class="card-settings__font card-settings__font--addition">
                  {{ value.buttonText }}
                </span>
              </button>
            </template>
          </CRadio>
        </div>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";

import CIcon from "@/features/ui/CIcon.vue";
import CRadio from "@/features/ui/CRadio.vue";
import { mapGetters, mapMutations } from "vuex";
import lockSettings from "@/mixins/lockSettings";

export default {
  name: "LockPopup",
  components: { CRadio, CIcon, BasePopupLayout },
  mixins: [lockSettings],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      price: 10,
      selected: {
        id: 1,
        name: this.$t("general.popups.lockPopup.choices.everyone"),
        icon: "wallet-money",
      },
    };
  },

  computed: {
    ...mapGetters({
      lockOption: "postCreate/lockOption",
      lockPrice: "postCreate/lockPrice",
    }),
    selectedItem: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selected = value;
        this.setLockOption({ lockOption: this.selected.id });
      },
    },

    settings() {
      return this.lockSettings;
    },
  },

  mounted() {
    this.initState();
  },
  methods: {
    ...mapMutations({ setLockOption: "postCreate/setLockOption" }),
    initState() {
      this.selected = this.settings.find((item) => item.id === this.lockOption);
    },
    updatePrice() {
      this.$popup.open('UpdatePostPricePopup')
    }
  },
};
</script>

<style lang="scss" scoped>
.lock-popup {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__settings {
    //display: flex;
  }

  &__radio-row {
    margin-bottom: em(20);
  }

  &__button {
    margin-bottom: em(10);
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--button {
      color: $white;
      font-weight: 700;
    }

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }

    &--price {
      color: $app-gray-16;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
