// import axios from '@/plugins/axios'

import { cleanObj } from "@/tools/helpers";

const contentTypes = {
  pending: "1",
  rejected: "2",
  active: "3",
};
const state = () => ({
  contents: {
    [contentTypes.pending]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
    [contentTypes.rejected]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
    [contentTypes.active]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
  },
});

const getters = {
  contents: (store) => store.contents,
};

const actions = {};

const mock = [
  {
    id: 123,
    preview: {
      video: {},
      user: {
        avatar: "some",
        username: "",
      },

    },
    caption:
      "shooting in my favorite set today! unlock the full video below to see behind the scenes 👀",
    description: "a woman with red hair and white shirt smiles at the camera",
    categories: [{ text: "B/G NSFW" }, { text: "TikTok Edits" }],
    metadata: ['tag'],
  },
];

const mutations = {
  setContents(state, { contents, params } = {}) {
    const { status, page } = params;

    state.contents[status].value = contents.data.length ? contents.data : mock;
    state.contents[status].count = contents.total;
    state.contents[status].limit = contents.per_page;
    state.contents[status].page = page;
  },

  addToContentsList(state, { contents } = {}) {
    state.contents[contentTypes.pending].value = [
      ...state.contents[contentTypes.pending].value,
      contents,
    ];
  },

  deleteContent(state, { id, type }) {
    state.contents[type].value = state.contents[type]?.value?.filter(
      (item) => item?.id !== id
    );
  },

  updateContentsList(state, { content, type, status }) {
    const listDataIndex = state.contents[type]?.value?.findIndex(
      (item) => item?.id === content.id
    );

    if (listDataIndex === -1) return;

    const newContent = cleanObj(content);

    if(status) {
      state.contents[status].value[listDataIndex] = { ...newContent };
      state.contents[status].value.splice(state.contents[status].value?.length);
    } else {
      state.contents[type].value[listDataIndex] = { ...newContent };
      state.contents[type].value.splice(state.contents[type].value?.length);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
