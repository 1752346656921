const state = () => ({
  bookmarks: [],
  bookmarksMeta: null,
});

const actions = {};

const getters = {
  bookmarks: (state) => state.bookmarks,
  bookmarksMeta: (state) => state.bookmarksMeta,
};

const mutations = {
  setBookmarks(state, { bookmarks } = {}) {
    state.bookmarks = bookmarks;
  },

  updateBookmarks(state, { bookmarks } = {}) {
    state.bookmarks = [...bookmarks, ...state.bookmarks];
  },

  removeBookmark(state, { bookmarks } = {}) {
    state.bookmarks = state.bookmarks.filter(
      (item) => item.id !== bookmarks.id
    );
  },

  setMeta(state, { meta } = {}) {
    state.bookmarksMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
