import anonymousHandler from "@/mixins/anonymousHandler";

export const likeBaseUrls = {
  posts: "posts",
  comments: "comments",
};
export default {
  mixins: [anonymousHandler],
  data() {
    return {
      likeUrl: ``,
      likeObject: {
        isLike: false,
        likesCount: 0,
      },
    };
  },
  methods: {
    setLikeData({
      baseRoute = likeBaseUrls.posts,
      id = 0,
      isLike = false,
      likesCount = 0,
    }) {
      this.likeUrl = `/${baseRoute}/${id}/like`;
      this.likeObject.isLike = isLike;
      this.likeObject.likesCount = likesCount;
    },

    addLike() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      if (this.requestInProgress) return;

      this.toggleLikesAndCount();

      return this.waitRequest(() => {
        this.$hideSpinner();
        return this.$post(
          this.likeUrl,
          {},
          this.successLikeUpdate,
          this.failureLikeUpdate,
          this.checkErrors
        );
      });
    },

    updateLike({ count = 1, like = true } = {}) {
      this.likeObject.isLike = like;
      this.likeObject.likesCount += count;
    },

    successLikeUpdate(data) {
      this.likeObject.isLike = data?.is_liked;
      this.likeObject.likesCount = data?.likes_count || 0;
    },

    failureLikeUpdate(err) {
      console.log(err);
      this.toggleLikesAndCount();
    },

    toggleLikesAndCount() {
      if (this.likeObject.isLike) {
        // remove like
        this.updateLike({ count: -1, like: false });
      } else {
        this.updateLike();
      }
    },
  },
};
