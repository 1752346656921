const state = () => ({
  following: [],
  followingMeta: null,
});

const actions = {};

const getters = {
  following: (state) => state.following,
  followingMeta: (state) => state.followingMeta,
};

const mutations = {
  setFollowing(state, { following } = {}) {
    state.following = following;
  },

  updateFollowing(state, { following } = {}) {
    state.following = [...state.following, ...following];
  },

  setMeta(state, { meta } = {}) {
    state.followingMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
