import { render, staticRenderFns } from "./CSelect.vue?vue&type=template&id=a8ea7296&scoped=true&"
import script from "./CSelect.vue?vue&type=script&lang=js&"
export * from "./CSelect.vue?vue&type=script&lang=js&"
import style0 from "./CSelect.vue?vue&type=style&index=0&id=a8ea7296&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8ea7296",
  null
  
)

export default component.exports