<template>
  <BasePopupLayout
    :title="$t('general.popups.addMedia.title')"
    :custom-close="close"
  >
    <div class="add-media">
      <div class="add-media__form">
        <div class="add-media__field">
          <span class="add-media__font add-media__font--base">
            {{ $t("general.popups.addMedia.field") }}
          </span>
        </div>

        <div>
          <div>
            <ui-media-uploader
              :key="resetUiKey"
              v-model="media"
              ref="uploader"
              is-vault
              @change="mediaChange"
              class="add-media__preview"
            />
          </div>

          <div class="add-media__uploads">
            <div class="add-media__file">
              <input
                ref="videoInput"
                type="file"
                :accept="mediaTypes"
                @change="uploadFromDevice"
                hidden
              />
              <button
                ref="file"
                type="button"
                @click="mediaClick"
                class="button add-media__button add-media__button--upload"
              />
              <span class="add-media__font add-media__font--upload">
                {{ $t("general.popups.addMedia.upload") }}
              </span>
            </div>
          </div>
        </div>

        <div class="add-media__footer">
          <CButton
            @click="addMedia"
            align="center"
            variant="primary"
            class="add-media__button"
          >
            <span class="add-media__font add-media__font--button">
              {{ $t("general.popups.addMedia.button") }}
            </span>
          </CButton>
        </div>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CButton from "@/features/ui/СButton.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiMediaUploader from "@/features/containers/post-create/components/UiMediaUploader.vue";
import uploadFromDevice from "@/mixins/uploadFromDevice";

export default {
  name: "AddMediaPopup",
  components: { UiMediaUploader, CButton, BasePopupLayout },
  mixins: [waitRequest, validationError, uploadFromDevice],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        amount: "",
      },
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      cards: "payments/cards",
    }),
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.clearMedia()
  },

  methods: {
    ...mapActions({
      removeMedia: "media/removeMedia",
    }),
    ...mapMutations({
      updateVault: "media/updateVault",
    }),
    initState() {
      this.setupMuxInput(this.$refs.videoInput);
    },
    close() {
      this.$popup.close();
    },
    addMedia() {
      this.updateVault({ media: this.media });
      this.$refs.uploader.clean()
      this.close();
    },
    clearMedia() {
      const mediaToRemove = this.media.map(item => {
        return new Promise(() => {
          this.removeMedia(item.id)
        })
      })

      Promise.allSettled(mediaToRemove).catch(this.checkErrors)
    }
  },
};
</script>

<style lang="scss" scoped>
.add-media {
  margin-bottom: 0;
  padding-left: 0;

  &__preview {
    //margin-left: -15px;
  }

  &__title {
    margin-top: 21px;
  }

  &__button {
    margin-top: 20px;
    margin-bottom: 10px;

    &--upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: em(53);
      border-radius: em(5);
      border: 1px dashed $app-gray-11;
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 0;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: em(54) 0 0 0;
      position: relative;

      &--vault {
        background-color: $app-gray;
      }
    }
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__plus {
    margin-left: em(12);
    margin-bottom: em(37);
  }

  &__font {
    font-family: var(--font-family);

    &--base {
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $app-black;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-bottom: em(37);
  }

  &__uploads {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 14px;
  }

  &__font {
    &--upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: $app-gray-12;
      text-align: center;

      font-size: em(12);
      font-weight: 400;
      pointer-events: none;
      white-space: nowrap;
    }
  }

  &__file {
    position: relative;
    width: 100%;
  }
}
</style>
