import axios from "@/plugins/axios";

const state = () => ({
  promo: null,
});

const getters = {
  promo: (state, getters, rootState, rootGetters) => rootGetters.user.promo,
  bundles: (state, getters, rootState, rootGetters) => rootGetters.user.bundles
};

const actions = {
  // fetchPromo({ commit }) {
  //   return axios({ method: "GET", url: "/promo" }).then((response) => {
  //     commit("setPromo", {
  //       promo: response.data.promo,
  //     });
  //
  //     commit('setUser', response.data, { root: true })
  //
  //     return response;
  //   });
  // },

  createPromo({ commit }, data) {
    return axios({ method: "POST", url: "/promo", data }).then((response) => {
      commit('setUser', response.data, { root: true })

      return response;
    });
  },

  stopPromo({ commit }) {
    return axios({ method: "POST", url: "/promo-stop" }).then((response) => {
      commit('setUser', response.data, { root: true })

      return response;
    });
  },
};

const mutations = {
  setPromo(state, { promo } = {}) {
    state.promo = promo;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
