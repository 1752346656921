<template>
  <BasePopupLayout no-border :title="config.title" :custom-close="close">
    <div class="create-promotion">
      <div class="create-promotion__tabs">
        <CRadio
          v-model="selectedTab"
          v-for="(item, index) in tabs"
          :key="`${index + '-tab'}`"
          :name="item.name"
          :value="item"
          group-name="promotion-tab"
          class="promotion-tab"
        >
          <template #default="{ isActive, value: tab }">
            <div
              class="promotion-tab__item"
              :class="{ 'promotion-tab__item--active': isActive }"
            >
              <span
                class="promotion-tab__font"
                :class="{ 'promotion-tab__font--active': isActive }"
              >
                {{ tab.content }}
              </span>
            </div>
          </template>
        </CRadio>
      </div>

      <AppInfo
        class="mb-3"
        text=""
        type="error"
        v-if="errors.collect('_').length"
      >
        <ul class="pl-0">
          <li
            class="list-unstyled"
            v-for="errorItem in errorsList"
            :key="errorItem"
          >
            {{ errorItem }}
          </li>
        </ul>
      </AppInfo>

      <form>
        <b-row class="mb-3">
          <b-col>
            <div class="v-select-profile">
              <v-select
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected': form.limit,
                }"
                name="billing_info.country"
                v-model="form.limit"
                label="label"
                :options="limits"
                :reduce="(option) => option"
                placeholder="countries"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.limit,
                }"
              >
                {{ $t("general.popups.createPromotion.input.limit") }}
              </span>

              <small
                v-if="errors && errors.has('limit')"
                class="create-promotion__font create-promotion__font--error"
              >
                {{ errors.first("limit") }}
              </small>
            </div>
          </b-col>

          <b-col>
            <div class="v-select-profile">
              <v-select
                :key="form.expiration"
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected': form.expiration,
                }"
                name="billing_info.region"
                v-model="form.expiration"
                label="label"
                :options="expiration"
                :reduce="(option) => option"
                placeholder="region"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.expiration,
                }"
              >
                {{ $t("general.popups.createPromotion.input.expiration") }}
              </span>

              <small
                v-if="errors && errors.has('expiration')"
                class="create-promotion__font create-promotion__font--error"
              >
                {{ errors.first("expiration") }}
              </small>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="selectedTab.type === 2">
          <b-col>
            <div class="create-promotion__input">
              <CInput
                type="text"
                name="discount_price"
                v-mask="currencyMask"
                v-model.trim="form.discount_price"
                :custom-errors="errors"
                :custom-field-name="
                  $t('general.popups.createPromotion.input.firstMonth')
                "
                theme="settings"
                class="create-promotion__field create-promotion__field--name"
                :label="$t('general.popups.createPromotion.input.firstMonth')"
                :rules="'required|max:40'"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-else class="mb-3">
          <b-col>
            <div class="v-select-profile">
              <v-select
                :key="form.trial_duration_days"
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected': form.trial_duration_days,
                }"
                name="billing_info.duration"
                v-model="form.trial_duration_days"
                label="label"
                :options="duration"
                :reduce="(option) => option"
                placeholder="region"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.trial_duration_days,
                }"
              >
                {{ $t("general.popups.createPromotion.input.duration") }}
              </span>

              <small
                v-if="errors && errors.has('trial_duration_days')"
                class="create-promotion__font create-promotion__font--error"
              >
                {{ errors.first("trial_duration_days") }}
              </small>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="create-promotion__input">
              <CInput
                :disabled="isUpdateCard"
                type="text"
                name="message"
                v-model.trim="form.message"
                :custom-field-name="
                  $t('general.popups.createPromotion.input.message')
                "
                theme="settings"
                :custom-errors="errors"
                class="create-promotion__field create-promotion__field--name"
                :label="$t('general.popups.createPromotion.input.message')"
              />
            </div>
          </b-col>
        </b-row>

        <div class="create-promotion__button-wrapper">
          <button
            type="submit"
            @click.prevent="create"
            class="base-button create-promotion__button"
          >
            <span class="base-button__font">
              {{ $t("general.myProfile.save") }}
            </span>
          </button>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import CRadio from "@/features/ui/CRadio.vue";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { cleanObj, declension } from "@/tools/helpers";
export default {
  name: "CreatePromotionPopup",
  components: {
    CRadio,
    AppInfo,
    vSelect,
    CInput,
    BasePopupLayout,
  },
  mixins: [validationError, waitRequest],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    const expiration = new Array(30).fill(0).map((_, index) => {
      return {
        label: `${index + 1} ${this.declensionDay(index + 1)}`,
        value: index + 1,
      };
    });

    const limits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 100].map(
      (item) => {
        return {
          label: `${item} ${this.declensionFan(item)}`,
          value: item,
        };
      }
    );

    const duration = new Array(30).fill(0).map((_, index) => {
      return {
        label: `${index + 1} ${this.declensionDay(index + 1)}`,
        value: index + 1,
      };
    });

    return {
      limits,
      expiration,
      duration,

      form: {
        limit: null,
        expiration: null,
        discount_price: 2,
        trial_duration_days: null,
        message: "",
      },

      selectedTab: {
        id: "cards",
        type: 1,
        content: this.$t("general.popups.createPromotion.tab.free"),
      },
    };
  },
  computed: {
    ...mapGetters({
      promo: "promo/promo",
    }),
    currencyMask() {
      return createNumberMask({
        prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
    tabs() {
      return [
        {
          id: "cards",
          type: 1,
          content: this.$t("general.popups.createPromotion.tab.free"),
        },
        {
          id: "history",
          type: 2,
          content: this.$t("general.popups.createPromotion.tab.firstMonth"),
        },
      ];
    },
    isUpdateCard() {
      return Boolean(this.data?.card);
    },

    errorsList() {
      if (!this.errors.collect("_").length) return [];

      return new Set(this.errors.collect("_"));
    },

    config() {
      const config = {
        add: {
          title: this.$t("general.popups.createPromotion.title"),
          button: this.$t(
            "general.popups.createPromotion.addPaymentCardButton"
          ),
        },
        update: {
          title: this.$t("general.popups.createPromotion.titleUpdate"),
          button: this.$t(
            "general.popups.createPromotion.updatePaymentCardButton"
          ),
        },
      };

      return this.isUpdateCard ? config.update : config.add;
    },
    countriesList() {
      return this.countries || [];
    },
    regions() {
      return this.form?.billing_info?.country?.regions || [];
    },
  },
  watch: {
    "form.billing_info.country": {
      handler(value) {
        if (!value) {
          this.form.billing_info.region = null;
        }
      },
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    cleanObj,
    declension,
    ...mapActions({
      createPromo: "promo/createPromo",
    }),
    initState() {
      if(this.promo) {
        this.form.limit = this.limits.find(item => item.value === this.promo?.limit);
        this.form.expiration = this.expiration.find(item => item.value === this.promo?.expiration);
        this.form.discount_price = this.promo.face_discount_price;
        this.form.trial_duration_days = this.duration.find(item => item.value === this.promo?.trial_duration_days)
        this.form.message = this.promo.message;
      }
    },
    declensionDay(number) {
      return this.declension(number, ["day", "days", "days"]);
    },
    declensionFan(number) {
      return this.declension(number, ["fan", "fans", "fans"]);
    },
    async create() {
      if (this.requestInProgress) return;

      const form = this.prepareFormData();

      this.waitRequest(() => {
        return this.createPromo(form)
          .then(() => {
            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    prepareFormData() {
      const discount_price =
        this.selectedTab.type === 2 && this.form?.discount_price
          ? this.form?.discount_price?.replace("$", "")?.replaceAll(",", "")
          : null;

      return cleanObj({
        type: this.selectedTab.type,
        limit: this.form?.limit?.value || undefined,
        expiration: this.form?.expiration?.value || undefined,
        trial_duration_days: this.form?.trial_duration_days?.value || undefined,
        message: this.form?.message,
        discount_price,
      });
    },

    close() {
      if (this.data.closeCallback) {
        this.data.closeCallback();
      } else {
        this.$popup.close(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-promotion {
  //margin: em(0) em(-13) em(-17) em(-14);
  //height: 500px;
  overflow-y: auto;
  overflow-x: clip;
  @include hideScroll();

  &__tabs {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 18px;
  }

  &__title {
    margin-top: 19px;
    margin-bottom: 19px;
  }

  &__info {
    margin: em(0) em(13) em(17) em(14);
  }

  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__image {
    margin-top: em(29);
  }

  &__input {
    &--bottom {
    }
  }

  &__info {
    margin: 17px 0 0;
  }

  &__description {
    margin-top: em(8);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__field {
    &--name {
    }

    &--bio-link {
    }
    &--radio {
      margin-top: em(17);
    }
  }

  &__icon {
    color: $app-gray-18;

    &--camera {
      width: em(26);
      min-width: em(26);
      height: em(23);

      &--2 {
        width: em(20);
        min-width: em(20);
        height: em(18);
      }
    }

    &--cross {
      width: em(35);
      min-width: em(35);
      height: em(35);

      &--2 {
        width: em(25);
        min-width: em(25);
        height: em(25);
      }
    }

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }

  &__counter {
  }

  &__font {
    &--addition {
    }
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $black;
    }
    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: $app-font-color-placeholder;
      text-align: center;
    }

    &--confirm {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      color: $app-font-color-placeholder;
    }

    &--error {
      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
      color: $app-red;
      margin-top: 11px;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__button {
    margin-left: auto;
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }

  &--disabled {
    &:before {
      content: "";
      display: inline-block;
      width: em(16);
      min-width: em(16);
      height: em(16);
      min-height: em(16);
      background-color: transparent;
      border: 1px solid $app-gray-18;
      border-radius: 50%;
      margin-right: em(17);
      transition: background-color $time-normal $ease, border $time-normal $ease;
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.promotion-tab {
  text-align: center;

  &__item {
    padding: 8px 8px 10px;
    border-radius: 5px;
    background-color: $app-gray-7;

    &--active {
      background-color: $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--active {
      color: $white;
    }
  }
}
</style>
