import { cleanObj } from "@/tools/helpers";
import axios from "@/plugins/axios";
import { mediaTypes } from "@/config/mediaTypes";

const state = () => ({
  vault: {
    current_page: 1,
    next_page_url: null,
    data: [],
  },
  selectedVaultMedia: [],
});

const getters = {
  vault: (state) => state.vault,
  selectedVaultMedia: (state) => state.selectedVaultMedia,
};

const actions = {
  createMedia({ commit }) {
    return axios({ method: "GET", url: `/media` }).then((response) => {
      commit("setVault", { vault: response.data });

      return response;
    });
  },

  fetchVault({ commit }, { page = 1, flow_compatible = undefined } = {}) {
    const requestQuery = {
      page,
      flow_compatible,
      vault: mediaTypes.vault,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: `/media`, params }).then((response) => {
      commit("setVault", { vault: response.data });

      return response;
    });
  },

  removeMedia(store, mediaId) {
    return axios({ method: "DELETE", url: `/media/${mediaId}`})
  }
};

const mutations = {
  updateVault(state, { media = [] } = {}) {
    state.vault.data = [...media, ...(state.vault.data || [])];
  },
  setVault(state, { vault } = {}) {
    if (vault.current_page === 1) {
      state.vault = vault;
      return;
    }

    state.vault = {
      ...vault,
      current_page: vault.current_page || 1,
      next_page_url: vault.next_page_url || "",
      data: [...(state.vault.data || []), ...(vault.data || [])],
    };
  },
  setSelectedMedia(state, media) {
    state.selectedVaultMedia = media.map(item => {
      return {...item, isVault: true }
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
