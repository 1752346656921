<template>
  <BasePopupLayout
    no-border
    :title="$t('general.popups.schedule.title')"
  >
    <div class="schedule-popup">
      <AppInfo
        class="mb-3"
        :text="$t('general.popups.schedule.info')"
        type="primary"
      />


      <div class="schedule-popup__datepicker py-0">
        <CInput
          type="datetime-local"
          :min="availableMinDate"
          name="schedule"
          input-mode="text"
          theme="secondary"
          v-model="published_at"
          :placeholder="$t('Add time to publisch')"
        />
        <small v-if="errors.has('schedule')">
          <span class="text-danger">
            {{ errors.first("schedule") }}
          </span>
        </small>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import uploadFromDevice from "@/mixins/uploadFromDevice";
import CInput from "@/features/ui/СInput.vue";
import {formatSchedulePostDate, formatSchedulePostDateNoUTC} from "@/tools/helpers";
export default {
  name: "SchedulePopup",
  components: {
    CInput,
    AppInfo,
    BasePopupLayout,
  },
  mixins: [uploadFromDevice],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      availableMinDate: new Date().toISOString().slice(0, 16),
      published_at: this.getPublischeDate(this.data.published_at),
    };
  },
  computed: {
    errorsList() {
      if (!this.errors.collect("_").length) return [];

      return new Set(this.errors.collect("_"));
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    formatSchedulePostDate,
    formatSchedulePostDateNoUTC,
    initState() {
      this.published_at = this.getPublischeDate(this.data.published_at);

      this.data.closeCallback = () => {
        this.data.setScheduleCallback(this.published_at)
        this.$popup.close(1)
      }
    },

    getPublischeDate(date) {
      return this.formatSchedulePostDateNoUTC(date)?.split("T")?.join(" ");
    },


    // close() {
    //   if (this.data.closeCallback) {
    //     this.data.closeCallback();
    //   } else {
    //     this.$popup.close(1);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.schedule-popup{
 &__datepicker {
   position: relative;
   width: 100%;
   min-height: em(78);
   //padding: em(16);
   padding-bottom: 0;
 }
}
</style>
