<template>
  <BasePopupLayout :is-header="false">
    <div class="login-popup">
      <div class="login-popup__title">
        <span class="login-popup__font login-popup__font--title">
          {{ $t("general.auth.signUp.title") }}
        </span>
      </div>

      <div class="login-popup__content">
        <AppInfo
          class="mb-3"
          text=""
          type="error"
          v-if="errors.collect('_').length"
        >
          <ul class="pl-0">
            <li
              class="list-unstyled"
              v-for="errorItem in errors.collect('_')"
              :key="errorItem"
            >
              {{ errorItem }}
            </li>
          </ul>
        </AppInfo>

        <form novalidate class="form" @submit.prevent="submitForm">
          <CInput name="_" hidden />
          <CInput
            name="email"
            type="email"
            v-model="email"
            :custom-errors="errors"
            :label="$t('general.auth.signUp.form.email')"
            :rules="'required|email'"
          >
            <template #appendAfter>
              <transition name="fade" mode="out-in">
                <IconCheck class="icon icon--check" v-if="email" />
                <IconFalse class="icon icon--false" v-else />
              </transition>
            </template>
          </CInput>

          <CInput
            type="password"
            name="password"
            v-model="password"
            :custom-errors="errors"
            :label="$t('general.auth.signUp.form.password')"
            :rules="'required|min:8'"
          >
            <template #appendAfter>
              <transition name="fade" mode="out-in">
                <IconCheck class="icon icon--check" v-if="password" />
                <IconFalse class="icon icon--false" v-else />
              </transition>
            </template>
          </CInput>

          <CInput
            type="text"
            name="name"
            v-model="name"
            :label="$t('general.auth.signUp.form.name')"
            :rules="'required'"
          >
            <template #appendAfter>
              <transition name="fade" mode="out-in">
                <IconCheck class="icon icon--check" v-if="name" />
                <IconFalse class="icon icon--false" v-else />
              </transition>
            </template>
          </CInput>

          <CButton
            type="submit"
            variant="primary"
            class="w-100 text-uppercase"
            :disabled="hasValidationError"
          >
            {{ $t("general.auth.signUp.button.sign-up") }}
          </CButton>

          <i18n
            path="general.auth.signUp.agree.base-text"
            tag="div"
            class="font font--policy mt-2 mb-4 ml-2"
          >
            <template v-slot:terms>
              <CLink :to="'/terms.html'" target="_blank">
                {{ $t("general.auth.signUp.agree.terms") }}
              </CLink>
            </template>

            <template v-slot:policy>
              <CLink :to="'/privacy.html'" target="_blank">
                {{ $t("general.auth.signUp.agree.policy") }}
              </CLink>
            </template>
          </i18n>
        </form>
      </div>

      <div class="login-popup__settings">
        <div class="border-top p-3 w-100">
          <div class="text-center">
            {{ $t("general.auth.signUp.footer") }}
            <button
              @click.stop="$popup.open('LoginPopup', null, 2)"
              class="button login-popup__link"
            >
              {{ $t("general.auth.signUp.button.login") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import userLocation from "@/mixins/userLocation";
import User from "@/components/models/User";
import CLink from "@/features/ui/CLink.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";
import IconFalse from "@/assets/svg/false.svg";
import IconCheck from "@/assets/svg/check.svg";

export default {
  name: "SignUpPopup",
  components: {
    IconCheck,
    IconFalse,
    CButton,
    CInput,
    CLink,
    BasePopupLayout,
    AppInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [validationError, waitRequest, userLocation],
  data() {
    return {
      email: "",
      password: "",
      name: '',
    };
  },

  computed: {
    hasValidationError() {
      return !this.email || !this.password || (
        Boolean(this.errors?.collect("password").length) ||
        Boolean(this.errors?.collect("email").length)
      );
    },
  },

  methods: {
    submitForm() {
      this.login(User.CHANNEL_EMAIL);
    },

    login(type) {
      let fields = {
        channel_type: type,
        email: this.email,
        password: this.password,
        name: this.name,
      };

      return this.waitRequest(() => {
        return  this.$post(
          "/auth/signup",
          fields,
          (data) => {
            this.$saveToken(data.token);
            this.$saveUser(data.user);
            location.reload();
            this.sendUserLocation();
          },
          (errors) => {
            this.errors = errors;
          },
          this.checkErrors
        )
      });
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  &--check {
    width: em(14);
    height: em(10);
  }

  &--false {
    width: em(15);
    height: em(15);
  }
}

.font {
  &__forgot {
    font-size: em(12);
  }
}

@import "~@/assets/scss/vendors/_variables.scss";

.login-popup {
  margin-bottom: em(-17);
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__settings {
    margin-top: em(30);
    margin-left: em(-15);
    margin-right: em(-15);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__link {
    text-align: center;
    display: inline;
    width: auto;
    color: $app-blue;
  }
}
</style>
