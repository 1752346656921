<template>
  <b-row class="users-edit" v-if="user != null">
    <b-col>
      <b-row class="border-bottom sticky-top bg-white page-header">
        <div class="d-flex align-items-center w-100 mx-3">
          <b-link @click="$router.go(-1)">
            <i class="bi-arrow-left" />
          </b-link>
          <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
            {{ $t("admin.edit-user") }}
          </h5>
          <b-button @click.prevent="saveSettings">{{
            $t("general.save")
          }}</b-button>
        </div>
      </b-row>
      <b-form @submit="saveSettings">
        <b-row>
          <b-card :header="$t('admin.commission')" class="w-100 m-3">
            <ui-form-input
              type="text"
              name="commission"
              v-model="user.commission"
              append="%"
              :placeholder="$t('admin.default')"
              :description="$t('admin.leave-empty-for-default', [commission])"
              :errors="errors"
            />
          </b-card>
        </b-row>

        <b-row>
          <div class="w-100 mx-3 mt-3">
            <b-form-group label="User role" label-for="role">
              <v-select
                v-model="role"
                label="name"
                name="role"
                v-validate="'required'"
                :options="adminRoles"
                :reduce="(option) => option.id"
              />

              <small class="text-danger" v-show="errors.has('role')">
                {{ errors.first("role") }}
              </small>
            </b-form-group>

            <b-form-group :label="$t('general.username')" label-for="username">
              <b-form-input
                v-model="user.username"
                type="text"
                name="username"
                placeholder="User username"
                v-validate="'required'"
              />

              <small class="text-danger" v-show="errors.has('username')">
                {{ errors.first("username") }}
              </small>
            </b-form-group>

            <b-form-group :label="$t('general.full-name')" label-for="name">
              <b-form-input
                v-model="user.name"
                type="text"
                name="name"
                placeholder="User name"
                v-validate="'required'"
              />

              <small class="text-danger" v-show="errors.has('name')">
                {{ errors.first("name") }}
              </small>
            </b-form-group>

            <b-form-group :label="$t('general.bio')" label-for="bio">
              <b-form-textarea
                v-model="user.bio"
                type="text"
                name="bio"
                placeholder="User bio"
              />

              <small class="text-danger" v-show="errors.has('bio')">
                {{ errors.first("bio") }}
              </small>
            </b-form-group>

            <b-form-group label="Location" label-for="location">
              <v-select
                v-model="location"
                label="address"
                name="location"
                v-validate="'required'"
                :options="locationList"
                :reduce="(option) => option"
                @search="onLocationInput"
              />

              <small class="text-danger" v-show="errors.has('location')">
                {{ errors.first("location") }}
              </small>
            </b-form-group>

            <b-form-group :label="$t('general.website')" label-for="website">
              <b-form-input type="url" name="website" v-model="user.website" />

              <small class="text-danger" v-show="errors.has('website')">
                {{ errors.first("website") }}
              </small>
            </b-form-group>

            <b-form-group :label="$t('general.email')" label-for="email">
              <b-form-input
                type="email"
                name="email"
                v-validate="'required|email'"
                v-model="email"
              />

              <small class="text-danger" v-show="errors.has('email')">
                {{ errors.first("email") }}
              </small>
            </b-form-group>

            <b-form-group
              :label="$t('general.new-password')"
              label-for="new_password"
            >
              <b-form-input
                type="password"
                name="new_password"
                :data-vv-as="$t('general.new-password')"
                v-model="newPassword"
              />

              <small class="text-danger" v-show="errors.has('new_password')">
                {{ errors.first("new_password") }}
              </small>
            </b-form-group>

            <b-form-group
              :label="$t('general.confirm-password')"
              label-for="new_password_confirmation"
            >
              <b-form-input
                type="password"
                name="new_password_confirmation"
                :data-vv-as="$t('general.confirm-password')"
                v-validate="{
                  required: Boolean(newPassword),
                  is: newPassword,
                }"
                v-model="newPasswordConfirm"
              />

              <small
                class="text-danger"
                v-show="errors.has('new_password_confirmation')"
              >
                {{ errors.first("new_password_confirmation") }}
              </small>
            </b-form-group>
          </div>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>
<script>
import User from "../../models/User";
import UiFormInput from "../../ui/UiFormInput.vue";
import vSelect from "vue-select";
import settings from "@/mixins/settings";
import location from "@/mixins/location";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  components: {
    vSelect,
    UiFormInput,
  },

  mixins: [settings, location, waitRequest, validationError],

  data() {
    return {
      location: null,
      username: "",
      user: {
        username: null,
        name: null,
      },
      email: null,
      newPassword: null,
      newPasswordConfirm: null,
      role: "",
    };
  },

  computed: {
    userId() {
      return this.$route.params.id;
    },
    commission() {
      return process.env.VUE_APP_COMMISSION + "%";
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    initState() {
      this.loadUser();
      this.getSettingsCategories();
    },

    loadUser() {
      this.$aGet(
        "/users/" + this.userId,
        (data) => {
          this.user = new User(data);
          this.user.commission =
            data.commission == process.env.VUE_APP_COMMISSION
              ? null
              : data.commission;
          this.email = data.email;
          this.location = {
            address: this.user.location.address,
            lat: this.user.location.lat,
            lon: this.user.location.lon,
          };
          this.role = this.adminRoles.find(
            (role) => role.id === this.user.role[0]
          ).id;
          this.newPassword = null;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    async saveSettings() {
      const isValid = await this.$validator.validate();

      if (this.requestInProgress || !isValid) return;

      return this.waitRequest(() => {
        return this.$aPost(
          "/users/" + this.userId,
          {
            _method: "PUT",
            role: [this.role],
            username: this.user.username,
            name: this.user.name,
            bio: this.user.bio,
            location: this.location,
            website: this.user.website,
            email: this.email,
            new_password: this.newPassword,
            new_password_confirmation: this.newPasswordConfirm,
            commission: this.user.commission,
          },

          (data) => {
            this.user = new User(data);
            this.user.commission =
              data.commission == process.env.VUE_APP_COMMISSION
                ? null
                : data.commission;
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            });
          },
          (errors) => {
            // this.errors = errors;
            console.log("errors", errors);
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>
