<template>
  <ConfirmPopupLayout
    class="confirm-popup"
    :custom-close="close"
    :button-text="$t('general.ageConfirm.buttonOk')"
  >
    <div class="age-confirm-popup">
      <div class="age-confirm-popup__content">
        <div class="age-confirm-popup__icon-wrapper">
          <img
            src="/img/icons/pepper-18.png"
            class="age-confirm-popup__icon age-confirm-popup__icon--pepper"
          />
        </div>

        <div class="age-confirm-popup__title">
          <span class="age-confirm-popup__font age-confirm-popup__font--title">
            {{ $t("general.ageConfirm.title") }}
          </span>
        </div>

        <div class="age-confirm-popup__message">
          <div class="age-confirm-popup__text">
            <div
              class="age-confirm-popup__font age-confirm-popup__font--message"
            >
              {{ $t("general.ageConfirm.textStart") }}<br />
              <br />
              {{ $t("general.ageConfirm.textMid") }}<br />
              <br />

              <i18n path="general.ageConfirm.textTerm" tag="div" class="ml-2">
                <template v-slot:terms>
                  <CLink to="'/terms.html'" target="_blank">
                    {{ $t("general.ageConfirm.terms") }}
                  </CLink>
                </template>

                <template v-slot:leave>
                  <a href="'/login'" target="_blank">
                    {{ $t("general.ageConfirm.leave") }}
                  </a>
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CLink from "@/features/ui/CLink.vue";
import ConfirmAge from "@/services/cinfirmAge";

export default {
  name: "AgeConfirmPopup",
  components: { CLink, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appear: false,
    };
  },
  methods: {
    close() {
      ConfirmAge.confirmAge()

      this.$popup.close(2);
    },
  },
};
</script>

<style scoped lang="scss">
.age-confirm-popup {
  $parent: &;

  //width: em(420);
  width: 100%;

  &__content {
    padding: em(32) em(25) em(25);
  }

  &__title {
    margin-top: em(23);
  }

  &__message {
    margin-top: em(11);
  }

  &__font {
    font-family: $font-default;
    text-align: center;
    font-style: normal;

    &--title {
      color: $black;
      font-size: em(18);
      font-weight: 700;
    }

    &--message {
      color: $app-font-color-gray-3;
      font-size: em(10);
      font-weight: 400;
    }
  }

  &__icon {
    &--pepper {
      width: em(53);
      height: em(53);
    }
  }
}
</style>
