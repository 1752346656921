import { render, staticRenderFns } from "./UiMediaUploader.vue?vue&type=template&id=21fef000&scoped=true&"
import script from "./UiMediaUploader.vue?vue&type=script&lang=js&"
export * from "./UiMediaUploader.vue?vue&type=script&lang=js&"
import style0 from "./UiMediaUploader.vue?vue&type=style&index=0&id=21fef000&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21fef000",
  null
  
)

export default component.exports