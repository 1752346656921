<template>
  <div
    class="c-select"
    :class="{
      'c-select--active': focused || !isEmptyValue,
      'c-select--disabled': disabled,
    }"
    @mousedown="openSelect"
  >
    <label v-if="inputLabel" class="c-select__label">
      <span class="c-select__label-name">{{ inputLabel }}</span>
    </label>

    <!--    [currentTheme.selectFieldModifier]: currentTheme.selectFieldModifier,-->
    <!--    [currentTheme.multiselectModifier]: currentTheme.multiselectModifier,-->
    <multiselect
      ref="select"
      v-model="value"
      v-validate="rules"
      class="c-select__field"
      :class="{
        [`c-select__field--theme--${theme}`]: theme,
        'multiselect--custom-option': $scopedSlots.option,
      }"
      :options="options"
      :show-labels="false"
      :name="name"
      :track-by="trackBy"
      :label="label"
      :allow-empty="false"
      :searchable="searchable"
      :disabled="disabled"
      :placeholder="placeholder"
      :preselect-first="preselectFirst"
      @select="selectHandler"
      @open="openHandler"
      @close="closeHandler"
    >
      <template #caret="{ toggle }">
        <transition name="fade" mode="out-in">
          <slot v-if="$scopedSlots.arrow" name="arrow"></slot>
          <button
            v-if="currentTheme.hasArrow"
            class="c-select__arrow"
            :class="{
              'c-select__arrow--reversed': focused,
            }"
            tabindex="-1"
            type="button"
            @mousedown.prevent.stop="toggle"
          >
            <CIcon
              name="arrow-padding"
              class="c-select__icon c-select__icon--arrow"
            />
          </button>
          <span v-else></span>
        </transition>
      </template>

      <template v-if="$scopedSlots.option" #option="{ option }">
        <slot name="option" :option="option" />
      </template>

      <template v-if="$scopedSlots.single" #singleLabel>
        <slot name="single" />
      </template>

      <template #noResult>
        <span class="c-select__element c-select__element--disabled">
          no result
        </span>
      </template>

      <template #noOptions>
        <span class="c-select__element c-select__element--disabled">
          empty list
        </span>
      </template>
    </multiselect>

    <div v-if="errors.has(name)" class="c-select__error">
      <span class="c-select__font c-select__font--error">
        {{ errors.first(name) }}
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import CIcon from "@/features/ui/CIcon.vue";

Vue.component("multiselect", Multiselect);

const themes = {
  default: "default",
  secondary: "secondary",
  comments: "comments",
};

const selectConfig = {
  [themes.default]: {
    hasArrow: true,
  },
  [themes.secondary]: {
    hasArrow: false,
    // selectFieldModifier: "c-select__field--inline",
    // multiselectModifier: "multiselect--inline",
  },
  [themes.comments]: {
    hasArrow: false,
  }
};

export default {
  name: "CSelect",
  components: { CIcon },
  inject: ["$validator"],
  model: {
    prop: "selectedValue",
    event: "change",
  },
  props: {
    name: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      required: true,
      type: Array,
    },
    trackBy: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    inputLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: [String, Number, Object],
      default: null,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: themes.default,
      validator(value) {
        return Object.values(themes).includes(value);
      },
    },
  },
  data() {
    return {
      focused: false,
      value: this.selectedValue,
      minSearchableValue: 20,
      contentWrapper: null,
    };
  },
  computed: {
    isEmptyValue() {
      if (this.value && typeof this.value === "object") {
        return !Object.keys(this.value).length;
      }
      return !this.value;
    },

    currentTheme() {
      return selectConfig[this.theme];
    },
  },
  watch: {
    selectedValue(to) {
      this.value = to;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.contentWrapper = this.$refs.select.$el.querySelector(
        ".multiselect__content-wrapper"
      );
    });
  },
  methods: {
    openSelect() {
      this.$emit("open");

      const selectRef = this.$refs.select;

      // We take the method from the ref since the native one is not
      // processing outside the selected input itself
      selectRef.activate(); // multiselect component method
    },
    selectHandler(selectedOption) {
      this.$validator.reset({ name: this.name });
      this.$emit("change", selectedOption);
    },
    closeHandler() {
      this.focused = false;
    },
    openHandler() {
      this.focused = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-select {
  $parent: &;

  position: relative;
  cursor: pointer;
  margin-bottom: em(20);

  &__label {
    //position: absolute;
    //top: em(36);
    //left: em(30);
    display: block;
    margin-bottom: em(4);
    //transform: translateY(-50%);
    //transform-origin: left;
    //transition: transform $time-normalFast;
    pointer-events: none;
    //@include media-breakpoint-down(sm) {
    //  top: em(29);
    //  left: em(15);
    //}
  }

  &__label-name {
    color: $app-blue-2;
    font-size: em(12);
    line-height: em(1.1);
  }

  &__field {
    @include autoFill($app-blue);

    width: 100%;
    //height: 100%;
    min-height: em(64, 16);
    padding: 0 em(16, 16);
    color: $app-blue;
    font-weight: normal;
    font-size: em(16);
    font-family: $font-default;
    font-style: normal;
    line-height: 1.2;
    border: 1px solid $app-border-gray-1;
    border-radius: em(8);
    outline: none;

    &::placeholder {
      color: $app-font-color-placeholder;
    }

    &:focus {
      border: 1px solid $app-blue-2;
    }

    //box-sizing: border-box;
    //width: 100%;
    //height: em(36);
    //padding: 0;
    //color: $app-blue-2;
    //background: transparent;
    //border: 1px solid $app-blue-2;
    //transition: border-color $time-normal;

    //&:focus {
    //@include hover-state;
    //}

    //@mixin hover-state {
    //  border-color: $app-blue;
    //}

    &--theme {
      &--primary {
      }

      &--secondary {
        &::placeholder {
          color: $app-font-color-placeholder-secondary;
        }

        background-color: $app-gray;
      }

      &--comments {
        min-height: em(44);
        background-color: $app-gray;

        &::placeholder {
          color: $app-font-color-placeholder-secondary;
        }
      }
    }
  }

  &__error {
    position: absolute;
    bottom: em(-20);
    left: 0;
    display: block;
    width: 100%;
    color: $red;
    font-weight: $font-medium;
  }

  &__font {
    &--error {
      @include text-overflow;

      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: em(14);
    z-index: 2;
    width: em(24);
    height: em(24);
    padding: 0;
    background: none;
    border: none;
    transform: translateY(-50%);

    &--reversed {
      top: auto;
      bottom: 50%;
      transform: translateY(50%) scale(-1);
    }
  }

  &__icon {
    &--arrow {
      width: em(24);
      height: em(24);
      transform: rotate(90deg);
    }
  }

  &__datepicker {
    width: 100% !important;
  }

  &--active {
    #{$parent} {
      &__label {
        transform: translateY(#{em(-29)}) scale(0.75);

        //@include media-breakpoint-down(sm) {
        //  transform: translateY(#{em(-16, 12)}) scale(0.75);
        //}
      }
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &--error {
    #{$parent} {
      &__field {
        border-color: $red;
      }
    }
  }
}
</style>
