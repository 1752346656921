import {postStatuses, postType} from "@/config/postsType";
import {cleanObj} from "@/tools/helpers";
import axios from "@/plugins/axios";

const state = () => ({
  posts: {
    [postType.flows]: [],
    [postType.posts]: [],
  },
  meta: {
    [postType.flows]: null,
    [postType.posts]: null,
  },
  schedule: {
    current_page: 1,
    next_page_url: null,
    data: [],
  }
});

const actions = {
  fetchSchedule({ commit }, { page = 1, userId } = {}) {
    const requestQuery = {
      page,
      // type: postType.posts,
      post_type: postStatuses.scheduled
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: `posts/user/${userId}`, params }).then(
      (response) => {
        commit('setSchedule', { schedule: response.data })

        return response;
      }
    );
  },
};

const getters = {
  posts: (state) => state.posts,
  postsMeta: (state) => state.meta,
  schedule: (state) => state.schedule,
};

const mutations = {
  setPosts(state, { posts, type } = {}) {
    state.posts[type] = posts;
  },

  updatePosts(state, { posts, type } = {}) {
    state.posts[type] = [...state.posts[type], ...posts];
  },

  setMeta(state, { meta, type } = {}) {
    state.meta[type] = meta;
  },

  setSchedule(state, { schedule } = {}) {
    if (schedule.current_page === 1) {
      state.schedule = schedule;
      return;
    }

    state.schedule = {
      ...schedule,
      current_page: schedule.current_page || 1,
      next_page_url: schedule.next_page_url || '',
      data: [...(state.schedule.data || []), ...(schedule.data || [])],
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
