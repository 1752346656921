<template>
  <ConfirmPopupLayout :show-bottom-button="false">
    <template>
      <div class="video-count-popup">
        <CIcon name="video" path="flows" class="video-count-popup__icon" />

        <div class="video-count-popup__text">
          <i18n
            path="general.popups.videoCount"
            tag="span"
            class="video-count-popup__font"
          >
            <template v-slot:videos>{{ data.amount || 0 }}</template>
          </i18n>
        </div>
      </div>
    </template>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "VideoCountPopup",
  components: { CIcon, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  }
};
</script>

<style lang="scss" scoped>
.video-count-popup {
  padding: em(18) 0 em(20);

  &__icon {
    color: $black;
    width: em(24);
    height: em(26);
  }

  &__text {
    margin-top: em(9);
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
  }
}
</style>
