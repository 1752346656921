const state = () => ({
    followers: [],
    followersMeta: null,
});

const actions = {};

const getters = {
    followers: (state) => state.followers,
    followersMeta: (state) => state.followersMeta,
};

const mutations = {
    setFollowers(state, { followers } = {}) {
        state.followers = followers;
    },

    updateFollowers(state, { followers } = {}) {
        state.followers = [...state.followers, ...followers];
    },

    setMeta(state, { meta } = {}) {
        state.followersMeta = meta;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
