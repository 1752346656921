const state = () => ({
  fans: [],
  fansMeta: null,
});

const actions = {};

const getters = {
  fans: (state) => state.fans,
  fansMeta: (state) => state.fansMeta,
};

const mutations = {
  setFans(state, { fans } = {}) {
    state.fans = fans;
  },

  updateFans(state, { fans } = {}) {
    state.fans = [...state.fans, ...fans];
  },

  setMeta(state, { meta } = {}) {
    state.fansMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
