import axios from "@/plugins/axios";
import {cleanObj} from "@/tools/helpers";

const state = () => ({
  link: "",
  referrals: {
    current_page: 1,
    next_page_url: '',
    data: [],
  },
  partnerStatus: null,
});

const getters = {
  link: (state) => state.link,
  referrals: (state) => state.referrals,
  partnerStatus: (state) => state.partnerStatus
};

const actions = {
  setReferralCookie(store, partner_id) {
    return axios({
      method: "POST",
      url: "/set-referral-cookie",
      data: { partner_id },
      withCredentials: true,
    });
  },

  fetchPartnerStatus({ commit }) {
    return axios({
      method: "GET",
      url: "/partner-request",
    }).then(response => {
      commit('setPartnerStatus', response.data)

      return response
    }) ;
  },

  createPartner({ commit }) {
    return axios({
      method: "POST",
      url: "/partner-request",
    }).then(response => {
      commit('setPartnerStatus', response.data)

      return response
    });
  },

  fetchLink({ commit }) {
    return axios({
      method: "GET",
      url: "/partner-link",
    }).then((response) => {
      commit("setLink", response.data.link);

      return response;
    });
  },

  fetchReferrals({ commit }, { page = 1 } = {}) {
    const requestQuery = {
      page,
    };
    const params = cleanObj(requestQuery);

    return axios({
      method: "GET",
      url: "/referrals",
      params
    }).then((response) => {
      commit("setReferrals", { referrals: response.data });

      return response;
    });
  },
};

const mutations = {
  setLink(state, link) {
    state.link = link;
  },

  setPartnerStatus(state, status) {
    state.partnerStatus = status
  },

  setReferrals(state, { referrals } = {}) {
    if (referrals?.current_page === 1) {
      state.referrals = referrals;
      return;
    }

    state.referrals = {
      ...referrals,
      current_page: referrals.current_page || 1,
      next_page_url: referrals.next_page_url || '',
      data: [...(state.referrals.data || []), ...(referrals.data || [])],
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
