import { postType } from "@/config/postsType";
import { set } from "lodash";

const state = () => ({
  contentSettings: {
    flow_settings: {
      categories: [],
    },
  },
  settings: {},
  settingsSex: {},
  activeVideoId: null,
  prevVideoIndex: null,
  storageSlide: 0,
  content: {
    flows: {
      posts: [],
      meta: null,
    },
    posts: {
      posts: [],
      meta: null,
    },
  },
});

const actions = {
  fetchPosts() {},
};

const getters = {
  isNSFWContent: (state, getters, rootState, rootGetters) =>
    rootGetters.user?.nsfw_enabled,
  flows: (state) => state.content.flows.posts,
  posts: (state) => state.content.posts.posts,
  flowsMeta: (state) => state.content.flows.meta,
  postsMeta: (state) => state.content.posts.meta,
  activeVideoId: (state) => state.activeVideoId,
  prevVideoIndex: (state) => state.prevVideoIndex,
  contentSettings: (state) => state.contentSettings,
  settings: (state) => state.settings,
  settingsSex: (state) => state.settingsSex,
  storageSlide: (state) => state.storageSlide,
};

const mutations = {
  setPosts(state, { posts, type = postType.posts } = {}) {
    if(state.activeVideoId === null && posts.length && postType.flows === type) {
      state.activeVideoId = posts[0].id;
    }

    state.content[type].posts = posts;
  },

  updatePosts(state, { posts, type = postType.posts } = {}) {
    if(state.activeVideoId === null && posts.length && postType.flows === type) {
      state.activeVideoId = posts[0].id;
    }

    state.content[type].posts = [...state.content[type].posts, ...posts];
  },

  setMeta(state, { meta, type = postType.posts } = {}) {
    state.content[type].meta = meta;
  },

  setParams(state, { type = "flows", postId, key, value } = {}) {
    const updatedPost = state.content[type].posts.map((postItem) => {
      if (postItem.id === postId) {
        set(postItem, key, value);
      }

      return postItem;
    });

    state.content[type].posts = updatedPost;
    state.content[type].posts.splice(state.content[type].posts.length);
  },

  setActiveVideoIndex(state, index) {
    if (!state.activeVideoId) {
      state.activeVideoId = index;
    } else {
      state.prevVideoIndex = state.activeVideoId;
      state.activeVideoId = index;
    }
  },

  setContentSettings(state, { contentSettings } = {}) {
    state.contentSettings = contentSettings;
  },

  updateFlowSettings(state, { data, key } = {}) {
    if (!state.contentSettings.flow_settings) {
      state.contentSettings.flow_settings = {
        categories: [],
        sex_preference: null,
        orientation_preference: null,
      };
    }
    state.contentSettings.flow_settings[key] = data;
  },

  setSettings(state, { settings } = {}) {
    state.settings = settings;
  },

  setSettingsSex(state, { settingsSex } = {}) {
    state.settingsSex = settingsSex;
  },

  setStorageSlide(state, index) {
    state.storageSlide = index
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
