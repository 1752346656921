<template>
  <div class="w-100">
    <input type="file" @input="handleFileUpload" ref="fileInput" hidden />

    <div class="dropzone__swiper" v-if="isFlow && !slides.length">
      <div class="dropzone__card" @click="uploadChange">
        <div class="mock-image dz-preview">
          <div class="mock-image__image mock-image__image--content">
            <CIcon name="video" path="post-create" class="mock-image__icon" />
          </div>
        </div>
      </div>
    </div>

    <CSwiper
      :key="slides.length"
      :items="slides"
      :slider-config="swiperConfig"
      class="dropzone__swiper"
      :static-view="false"
    >
      <template #slide="{ itemData: item, index } = {}">
        <div class="dropzone__card" v-if="item.isTemp">
          <div class="mock-image dz-preview">
            <div class="mock-image__image">
              <CIcon name="video" path="post-create" class="mock-image__icon" />
            </div>

            <Progress
              v-if="item.progress"
              :size="item.progress"
              class="dropzone__progress"
            />
          </div>
        </div>

        <div class="dropzone__card" v-else>
          <div class="dz-preview dz-file-preview bg-light rounded">
            <div class="dz-image" v-if="item.image && !item.progress">
              <img data-dz-thumbnail :src="item.image" />
            </div>

            <Progress
              v-if="item.progress"
              :size="item.progress"
              class="dropzone__progress"
            />

            <button
              type="button"
              class="dropzone__button dropzone__button--remove"
              @click="remove(item)"
            >
              <CIcon
                name="remove-image"
                path="post-create"
                class="dropzone__icon dropzone__icon--remove"
              />
            </button>
          </div>

          <button
            v-if="media.length > 1"
            type="button"
            class="dropzone__button dropzone__button--prev"
            @click.stop="moveSlideToPrevPosition(index)"
          >
            <CIcon
              name="arrow"
              path="post-create"
              class="dropzone__icon dropzone__icon--prev"
            />
          </button>

          <button
            v-if="media.length > 1"
            type="button"
            class="dropzone__button dropzone__button--next"
            @click.stop="moveSlideToNextPosition(index)"
          >
            <CIcon
              name="arrow"
              path="post-create"
              class="dropzone__icon dropzone__icon--next"
            />
          </button>
        </div>
      </template>

      <template #appendSlide v-if="progressBar > 0">
        <button type="button" class="button dropzone__card">
          <div class="mock-image dz-preview">
            <div class="mock-image__image">
              <CIcon name="image" path="post-create" class="mock-image__icon" />
            </div>

            <Progress
              v-if="progressBar"
              :size="progressBar"
              class="dropzone__progress"
            />
          </div>
        </button>
      </template>
    </CSwiper>
  </div>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CSwiper from "@/features/ui/CSwiper.vue";
import CIcon from "@/features/ui/CIcon.vue";
import Progress from "@/features/ui/common/Progress.vue";

import Media from "@/components/models/Media";

import { moveElement } from "@/tools/helpers";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

const mediaSwiperConfig = {
  slidesPerView: 3.6,
  slidesOffsetBefore: 16,
  speed: 300,
  navigation: false,
  centeredSlides: false,
  observer: true,
};

export default {
  components: {
    Progress,
    CSwiper,
    CIcon,
  },
  mixins: [waitRequest, validationError],
  props: {
    value: {
      type: Array,
    },
    isFlow: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [String, Number],
      default: undefined,
    },
    isVault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
    media: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    slides() {
      return this.media.map((item) => {
        let image = null;
        if (item.type === Media.TYPE_VIDEO) {
          image = `${item?.screenshot?.url}?token=${item?.screenshot?.jwt}`;
        } else {
          image = item?.[0]?.url?.url || item?.url?.url;
        }

        return { ...item, image };
      });
    },
  },

  data() {
    return {
      swiperConfig: mediaSwiperConfig,
      swiper: null,
      screenshots: null,
      progressBar: 0,
    };
  },

  methods: {
    ...mapActions({
      removeMedia: "media/removeMedia",
    }),
    handleFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.submitFile(event.target.files[0]);
    },

    submitFile(file) {
      let formData = new FormData();
      formData.append("media", file);

      if (this.userId) {
        formData.append("user_id", this.userId);
      }

      if (this.isVault) {
        formData.append("is_vault", 1);
      }

      const url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/media`;
      let headers = {
        Authorization: "Bearer " + this.token,
        "Content-Type": "multipart/form-data",
      };

      return this.waitRequest(() => {
        return axios
          .post(url, formData, {
            headers,
            onUploadProgress: this.progress,
          })
          .then(this.mediaComplete)
          .catch(this.catchErr);
      });
    },

    catchErr(error) {
      const data = error?.response?.data;

      if (data) {
        this.$popup.open(
          "ErrorPopup",
          {
            title: data?.message,
            message: data?.errors?.media?.[0],
          },
          2
        );
      } else {
        this.$popup.open(
          "ErrorPopup",
          {
            title: "Something went wrong",
          },
          2
        );
      }

      this.$refs.fileInput.value = "";
      this.progressBar = 0;
    },

    moveSlideToPrevPosition(position) {
      this.media = moveElement(this.media, position, -1);
    },
    moveSlideToNextPosition(position) {
      this.media = moveElement(this.media, position, 1);
    },
    progress(data) {
      this.progressBar = parseInt(Math.round((data.loaded / data.total) * 100));
    },

    remove(item) {
      this.media = this.media.filter((file) => file.id !== item.id);
    },

    clean() {
      this.media = [];
    },

    mediaComplete(response) {
      this.$refs.fileInput.value = "";

      if (response.data) {
        this.media.push(response.data);
        this.progressBar = 0;

        this.media = [...this.media];
      }
    },

    uploadChange() {
      this.$emit("change-upload");
    },
  },
};
</script>

<style lang="scss" scoped>
.mock-image {
  &__image {
    width: em(92);
    height: em(92);
    border-radius: em(5);
    border: 1px solid $app-gray-2;
    background: $app-gray;
    display: flex;
    align-items: center;
    justify-content: center;

    &--content {
      filter: contrast(0.8);
    }
  }

  &__icon {
    position: absolute;
    width: em(28);
    height: em(28);
    color: $app-gray-2;
  }
}

.dropzone {
  &__swiper {
    width: 100%;
  }

  &__card {
    position: relative;
    width: em(92);
    height: em(110);
  }

  &__progress {
    width: 80px;
    position: absolute;
    bottom: em(7);
    left: em(7);
    display: inline-block;
  }

  &__buttons-wrapper {
  }

  &__button {
    &--remove {
      position: absolute;
      top: 5%;
      right: 5%;
      width: auto;
    }

    &--prev {
      position: absolute;
      bottom: 0;
      left: em(20);
      width: auto;
    }

    &--next {
      position: absolute;
      bottom: 0;
      right: em(20);
      width: auto;
    }
  }

  &__icon {
    &--remove {
      width: em(14);
      height: em(14);
    }

    &--prev {
      color: $app-gray-13;
      width: em(13);
      height: em(8);
    }

    &--next {
      color: $app-gray-13;
      width: em(13);
      height: em(8);
      transform: rotate(180deg);
    }
  }
}

.swiper-slide {
  width: unset;
  display: flex;
  flex: 0 0 auto;
}
.dropzonePreviewContent {
  display: flex;
  flex: 0 0 auto;
  min-width: 120px;
}
.dz-progress.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;
  right: unset;
}
.dz-preview {
  flex: 0 0 92px;
  width: 92px;
  height: 92px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}

.dz-image {
  width: 100%;
  height: 100%;
}

.dz-preview img {
  background-size: cover;
  width: 100% !important;
  height: 100% !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.dz-remove {
  position: absolute;
  top: 5%;
  right: 5%;
}
.dz-edit {
  position: absolute;
  bottom: 5%;
  right: 5%;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
}
.dz-preview.dz-complete .dz-edit {
  opacity: 1;
  transition: all 0.4s ease-in;
  visibility: visible;
}
.dz-progress {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
}
.playB {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s linear;
  z-index: 100;
}
.dz-preview.dz-processing {
  .dz-progress {
    opacity: 1;
    transition: all 0.2s linear;
  }
  .playB {
    opacity: 0;
  }
}
.dz-preview.dz-complete {
  .dz-progress {
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }
  .playB {
    opacity: 0.5;
  }
  &:hover {
    .playB {
      opacity: 1;
    }
  }
}
</style>
