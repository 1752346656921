// import axios from '@/plugins/axios'

import { cleanObj } from "@/tools/helpers";

const creatorTypes = {
  pending: "1",
  rejected: "2",
  active: "3",
};
const state = () => ({
  creators: {
    [creatorTypes.pending]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
    [creatorTypes.rejected]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
    [creatorTypes.active]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
  },
});

const getters = {
  creators: (store) => store.creators,
};

const actions = {};

const mutations = {
  setCreators(state, { creators, params } = {}) {
    const { status, page } = params;

    state.creators[status].value = creators.data || [];
    state.creators[status].count = creators.total;
    state.creators[status].limit = creators.per_page;
    state.creators[status].page = page;
  },

  addToCreatorList(state, { creators } = {}) {
    state.creators[creatorTypes.pending].value = [...state.creators[creatorTypes.pending].value, creators]
  },

  deleteCreator(state, { id, type }) {
    state.creators[type].value = state.creators[type]?.value?.filter(
      (item) => item?.id !== id
    );
  },

  updateCreatorsList(state, { user, type, status }) {
    const listDataIndex = state.creators[type]?.value?.findIndex(
      (item) => item?.id === user.id
    );

    if (listDataIndex === -1) return;

    const newUser = cleanObj(user);

    if(status) {
      state.creators[status].value[listDataIndex] = { ...newUser };
      state.creators[status].value.splice(state.creators[status].value?.length);
    } else {
      state.creators[type].value[listDataIndex] = { ...newUser };
      state.creators[type].value.splice(state.creators[type].value?.length);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
