const state = () => ({
  discover: {},
});

const actions = {};

const getters = {
  discover: (state) => state.discover,
};

const mutations = {
  setPosts(state, data) {
    state.discover = data;
  },

  updatePosts(state, data) {
    state.discover = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
