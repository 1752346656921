import {mapMutations} from "vuex";
import {viewType} from "@/config/viewPostType";
import {postType} from "@/config/postsType";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";


const postsCallbackType = {
  setPosts: "setPosts",
  updatePosts: "updatePosts",
};

export default {
  mixins: [waitRequest, validationError],
  data() {
    return {
      nextPage: {
        [postType.flows]: 1,
        [postType.posts]: 1,
      },
      activeView: viewType.solo,
      loadPostsCallback: {
        [postsCallbackType.setPosts]: this.setCurrentPosts,
        [postsCallbackType.updatePosts]: this.updateCurrentPosts,
      },
    }
  },
  methods: {
    ...mapMutations({
      setPosts: "posts/setPosts",
      updatePosts: "posts/updatePosts",
      setMeta: "posts/setMeta",
    }),

    initState() {
      this.initialLoadPost().then(() => {
        this.initialLoadMedia(); // from media mixin
      });

      this.category = this.postsCategories?.[0];
    },

    setCategory(category) {
      this.category = category;
    },

    changeView(value) {
      this.isGridView = value;
      this.activeView = this.isGridView ? viewType.grid : viewType.solo;
    },

    setCurrentPost(data) {
      this.setPosts({ posts: [data], type: this.selectedTab.postListType });
    },

    loadPosts(
      successCallbackType = postsCallbackType.setPosts,
      loadPostType = null
    ) {
      if (this.requestInProgress) return;
      const successCallback = (data) =>
        this.loadPostsCallback[successCallbackType](data, loadPostType);
      const nextPage = this.nextPage[loadPostType];

      const url = new URL(
        `/posts/user/${this.userId}`,
        process.env.VUE_APP_API_URL
      );
      url.searchParams.set("page", nextPage);
      url.searchParams.set("type", loadPostType);

      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadPost() {
      this.$showSpinner();

      this.loadPosts(postsCallbackType.setPosts, postType.flows).then(() => {
        this.loadPosts(postsCallbackType.setPosts, postType.posts);
      });
    },

    loadMore() {
      if (this.hasMorePosts)
        this.nextPage[this.tab.postListType] =
          this.nextPage[this.tab.postListType] + 1;

      return this.loadPosts(
        postsCallbackType.updatePosts,
        this.tab.postListType
      );
    },

    setCurrentPosts(data, type = postType.flows) {
      const { current_page, data: posts } = data;

      this.setPosts({ posts, type: type });
      this.setMeta({ meta: data, type: type });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data, type = postType.flows) {
      const posts = data.data;

      this.updatePosts({ posts, type: type });
      this.setMeta({ meta: data, data, type: type });
    },

    loadPostError(errors) {
      console.log(errors);
    },

    scrollTop() {
      this.$nextTick(function () {
        scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    },
  }
}
