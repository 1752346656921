import axios from "@/plugins/axios";

const state = () => ({
  // price: null,
});

const actions = {
  fetchVerificationStatus() {
    return axios({
      method: "GET",
      url: `/verification`,
    })
  },
  fetchStartVerification() {
    return axios({
      method: "POST",
      url: `/verification`,
    })
  },
};

const getters = {
  price: (state, getters) => getters["currentUser"].price,
  bundle: (state, getters) => getters["currentUser"].bundle,
};

const mutations = {
  setPrice(state, price) {
    state.price = price;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
