export default {
  general: {
    massMessage: {
      title: 'MASS MESSAGE',
      message: 'Message to {users} users a few seconds ago',
      unsend: '{counter} UNSEND',
      inProgress: 'In progress...',
      send: 'Sended',
      search: 'Search user',
      next: 'NEXT',
      include: 'Include',
      doNotInclude: 'Do Not Include',
      addUser: 'Add User',
      usersN: 'No users | 1 user | {amount} users',
      includeButton: 'INCLUDE',
      excludeButton: 'EXCLUDE',
      placeholder: 'Search user',
      addVariable: "Add Variables",
      addMassMessage: "Mass Massage",
    },
    queue: {
      title: 'QUEUE',
      addNew: 'NEW POST',
    },
    queueCard: {
      description: 'Posting time',
      statusScheduled: 'Scheduled',
    },
    vault: {
      title: 'Vault',
      addNew: 'ADD NEW MEDIA',
    },
    referrals: {
      title: "REFERRALS",
      referralLink: 'Your Referral Link ',
      referText: 'Refer new creators to FanFlow',
      referDescription: 'Earn {percent} of your referrals earnings for 1 year!',
    },
    referralsCard: {
      earnings: 'EARNINGS',
      commission: 'COMMISSION',
      joined: 'joined',
      pending: 'Pending Verification',
      verified: 'Verified',
      chat: 'Chat',
    },

    lockedPost: {
      subscribe: 'SUBSCRIBE TO UNLOCK POSTS',
      unlockFor: 'UNLOCK POST FOR ${price}',
    },
    referralSpoiler: {
      referralLink: 'Referral Link',
      howItWorks: 'HOW IT WORKS',
      allTime: 'All time',
      refEarnings: 'REFERRAL EARNINGS',
      yourReferrals: 'Your Referrals',
    },
    statisticsSpoiler: {
      filter: "FILTER",
      total: 'TOTAL',
      gross: "GROSS",
      net: "NET",
      subscriptions: "SUBSCRIPTIONS",
      tips: "TIPS",
      posts: "POSTS",
      messages: "MESSAGES",
      referrals: "REFERRALS",
      streams: "STREAMS",
      allTime: 'All time',
      earnings: 'EARNINGS',
      totalGross: 'TOTAL (GROSS)',
      totalNet: 'TOTAL (NET)',
    },
    copy: 'Copy',
    solo: 'Solo',
    grid: 'Grid',
    deletedUser: 'Deleted User',
    earningCard: {
      amount: 'AMOUNT',
      fee: "FEE",
      net: "NET",
      verified: "Verified",
    },
    payoutCard: {
      status: {
        process: 'Processing payout',
        sent: 'Payout sent'
      },
      invoiceId: 'INVOICE ID',
      amount: 'AMOUNT',
    },
    statements: {
      title: "Statements",
      tabs: {
        earnings: "EARNINGS",
        payouts: "PAYOUTS",
        statistics: "STATISTICS",
        referrals: "REFERRALS",
      },
      spoiler: {
        primaryBalance: "PRIMARY BALANCE",
        pendingBalance: "PENDING BALANCE",
        amount: "Amount ($)",
        addition: 'The minimum you can request is ${amount}',
        buttonRequest: 'REQUEST PAYOUT',
        requestPayout: "Request Payout",
        earningAddition: "Once your balance is over ${amount} next payout will be scheduled on {date}"
      },
      info: "YOU ARE IN THE TOP {underlineText} OF ALL CREATORS!"
    },
    promo: {
      title: 'PROMOTIONS',
      descriptionTitle: 'Promotional Campaign',
      description: 'Offer fans a free-trial or discounted subscription for a limited ime or for a limited amount of fans.',
      startButton: 'START PROMOTIONAL CAMPAIGN',
      currentTitle: 'Current Promo',
      promoCardTitle: 'PROMO OFFER · {type} for {limit}!',
      timeLeft: 'Only {left} left!',
      timeEnd: '· Ends {end}',
      started: 'started',
      claimed: 'TOTAL CLAIMED',
      stop: 'STOP',
      edit: 'EDIT'
    },
    "my-wallets": {
      title: "WALLET",
      wallet: "Wallet",
      history: "History",
      balance: 'Wallet balance',
      button: 'ADD FUNDS FROM CARD',
    },
    "my-subscription": {
      "title": "My Subscription",
      "active": "Active",
      "cancelled": "Cancelled",
      "chat": "Chat",
      "subscribed": "subscribed",
      "subscribed-again": "SUBSCRIBE AGAIN",
      "for": "for",
      // "subscribed": "subscribed",
      "perMonth": "per month",
      "nextBilling": "Next billing on",
      "ended": "Ended on",
    },
    "my-cards": {
      updateCardTitle: 'Update card',
      addNewCards: 'Add New Card',
      updatePaymentCardButton: 'Update card',
      title: 'Cards',
      "my-cards": 'My Cards',
      "my-payments": 'My Payments',
      'addPaymentCardButton': 'ADD PAYMENT CARD',
      'setDefault': 'Set as default',
      'deleteCurd': 'Delete payment card',
      'defaultMethod': 'Default method',
      'updateCard': 'UPDATE CARD INFORMATION',
      'amount': 'Amount',
      'card': 'Card',
      details: 'CARD DETAILS',
      billing: 'BILLING INFO',
      'description': "Flow Interactive, LLC · 131 Brickell Ave, Miami, FL 32018",
      'confirm': "I confirm I am at least 18 years old and the age of majority in my place of residence",
      info:  'There will be a one-time small charge of $0.10 to confirm your card. The charge on your statement will appear as “FanFlow”',
      input: {
        cvc: 'CVC / Security Code',
        expiration: 'Expiration',
        curdNumber: 'Card Number',
        nameCard: 'Name on card',
        street: 'Street',
        region: 'State / Region',
        country: 'Country',
        zip: 'Zip / Postal Code',
        city: 'City',
      }
    },
    postsCreate: {
      newPost: "COMPOSE",
      editPost: "COMPOSE",
      uploadVault: "Upload from Vault +",
      upload: "Upload +",
      optional: "optional",
      settings: {
        nsfw: "NSFW",
        allowTips: "Allow tips",
        allowComments: "Allow comments",
        location: "Location",
        preview: "Preview",
        category: "Category",
        scheduleTime: 'Schedule time',
        whoCanSeePost: "Who can view this post",
      },
      flowMessage:
        "All images and poll was removed on upload. In flow available max video length 30s",
      messagePlaceholder: "Share your thoughts within 2200 characters",
    },
    becomeCreator: {
      title: "Become a FanFlow Creator",
      verificationList: {
        email: "Email verified",
        complete: "Complete profile (picture, bio, header)",
        identity: "Identity verification",
      },
      topText: {
        text: {
          start:
            "We’ve built an {open} creator ecosystem with our Flows using AI & our TikTok-inspired algorithm",
          open: "open & viral",
          end: `We aspire to be the fastest-growing creator platform helping the most creators reach {benefit}`,
          benefit: "$25,000+ a month!",
        },
      },
      bottomText: {
        text: {
          start:
            "Our team is reviewing your Creator application. You should receive a decision from us within 24 hours.",
          end: `We do this to keep FanFlow safe for everyone 💙`,
        },
      },
      benefits: {
        title: "Creator Benefits",
        list: {
          one: "Easily go viral and maximize earnings",
          two: "Link viral Flows to full content",
          three: "#1 referral & affiliate program in the space",
          four: "Creator-first environment",
          fife: "More exposure & discovery",
        },
      },
      button: {
        start: "START — Verify Identity",
        submit: "SUBMIT APPLICATION",
        process: "PROCESSING APPLICATION",
      },
    },

    becomePartner: {
      title: "Become a FanFlow Partner",
      titleVerify: "Partner Application",
      verificationList: {
        email: "Email verified",
        identity: "Identity verification",
      },
      policy: {
        text: `By signing up, you agree to the {partners} Terms and our {policy}.`,
        fanPartnersProgram: "Fan Partner Program Terms",
        affiliatePolicy: "Affiliate Policy",
      },
      topText: {
        text: {
          start: "Join our Fan Partner Program to earn!",
          end: `Refer your favorite creator to FanFlow & earn {percent}% of their earnings!`,
          percent: "10",
        },
        textVerify: {
          start: "To join the program, you must",
          end: "meet the requirements below",
        },
      },
      bottomText: {
        text: {
          start:
            "Our team is reviewing your Creator application. You should receive a decision from us within 24 hours.",
          end: `We do this to keep FanFlow safe for everyone 💙`,
        },
      },
      benefits: {
        title: "Partner Benefits",
        list: {
          one: "Earn 10% of Creators’ earnings for 6 months",
          two: "#1 Fan Partner Program in the space",
          three: "Reliable trusted tracking system",
          four: "Fast & easy to get started",
          fife: "Refer another Fan and get 5% of their spendings",
        },
      },
      button: {
        start: "Join Program",
        submit: "START — Verify Identity",
        process: "VERIFIED! — Go to Partner Area",
      },
    },

    emailVerify: {
      toast: {
        title: "Email verified",
        titleError: "Email verify error",
        text: "Success",
        check: "Verification email has sand",
      },
    },

    auth: {
      login: {
        title: "Log in to explore your new favorite creators",
        button: {
          login: "log in",
          twitter: "log in with twitter",
          google: "log in with google",
          "sign-up": "Sign up for FanFlow",
          "forgot-password": "Forgot Password?",
        },
        form: {
          email: "Email",
          password: "Password",
        },
      },

      signUp: {
        title: "Signup to explore your new favorite creators",
        button: {
          base: "Sign Up",
          login: "Log in",
          twitter: "sign up with twitter",
          google: "sign up with google",
          "sign-up": "Sign up for FanFlow",
          "forgot-password": "Forgot Password?",
        },
        agree: {
          terms: "Terms of Service",
          policy: "Privacy Policy",
          "base-text": `By signing up, you agree to our {terms} and {policy}, and confirm that you are at least 18 years old and of age in your jurisdiction.`,
        },
        footer: "Already have an account?",
        form: {
          email: "Email",
          password: "Password",
          name: "Name",
        },
        benefits: {
          title: "Fan Benefits",
          list: {
            one: "Watch entertaining viral creator videos",
            two: "Find creators near you",
            three: "Interact more deeply with creators",
            four: "Algorithm tailored to your interests",
          },
        },
      },
      "reset-password": {
        title: "Reset your FanFlow password below",
        button: {
          "reset-password": "reset password",
        },
        form: {
          email: "Email",
          password: "Password",
        },
        popup: {
          title: "Reset Password",
          content: {
            "text-1":
              "If we’ve found a FanFlow account with this email, we have sent password reset instructions to it.",
            "text-2":
              "Make sure to check your spam folder if you do not see it.",
          },
          button: "OK",
        },
      },
    },

    expirationDate: {
      options: {
        EXPIRES_IN_DAYS: "Expires in {count} days",
        EXPIRES_IN_ONE_DAY: "Expires in 1 day",
        EXPIRES_IN_HOURS: "Expires in {count} hours",
        EXPIRES_SOON: "Expires soon",
        EXPIRED: "Expired",
      },
    },

    accessType: {
      followers: "Followers only",
      subscribers: "Subscribers only",
      paid: "Unlock for {price}",
    },

    followingPage: {
      title: "FOLLOWING",
    },
    fansPage: {
      title: "FANS",
    },

    followersPage: {
      title: "FOLLOWERS",
    },

    settingsMenu: {
      title: "SETTINGS",
      groupName: {
        general: "General",
        creatorTools: "Creator Tools",
        other: "Other",
      },
      button: {
        myProfile: "My Profile",
        account: "Account",
        privacy: "Privacy",
        notifications: "Notifications",
        becomePartner: "Become a Partner",
        discord: "Join Beta Discord",
        subscription: "Subscription",
        massMessage: 'New Mass Message',
      },
    },

    settingsAccount: {
      title: "ACCOUNT",
      groupName: {
        general: "General",
        other: "Other",
        security: "Security",
        linkedAccounts: "Linked Accounts",
      },
      button: {
        username: "Username",
        email: "Email",
        phone: "Phone",
        twitter: "Twitter",
        google: "Google",
        password: "Password",
        auth: "Two Step Authentication",
        deleteAcc: "Delete Account",
      },
    },

    privacyPage: {
      title: "PRIVACY",
      button: {
        activityStatus: "Show activity status",
        hideActivity: "Hide activity for non-subscribers",
        update: "UPDATE",
        verifyEmail: 'Verify email'
      },
    },

    notificationsPage: {
      title: "NOTIFICATIONS",
      button: {
        email: "Email Notifications",
        update: "UPDATE",
      },
    },

    profile: {
      button: {
        follow: "Follow",
        unfollow: "Unfollow",
        showMore: "more",
        less: "less",
        editProfile: "Edit Profile",
        manageSubscription: 'MANAGE SUBSCRIPTION',
        subscribe: 'SUBSCRIBE',
        perMonth: 'per month',
        free: ' ',
        subscriptionFailed: 'SUBSCRIPTION FAILED',
        updateCard: 'UPDATE CARD'
      },
      tabs: {
        flow: "Flows",
        posts: "Posts",
        media: "Media",
      },
    },

    comments: {
      placeholder: "Add a comment...",
      nocomments: "No comments",
    },

    linkedPost: {
      title: "Linked Post",
      loadMore: "Load More",
      placeholderText:
        "You haven’t posted anything yet! You can only link Flows to content you have posted",
    },

    menu: {
      header: {
        fans: "fans",
        followers: "followers",
        following: "following",
      },

      list: {
        home: "Home",
        flow: "Flows",
        chat: "Chat",
        "my-profile": "My Profile",
        collections: "Collections",
        "my-subscriptions": "My Subscriptions",
        settings: "Settings",
        statements: "Statements",
        promo: "Promotions",
        referrals: 'Referrals',
        queue: 'Queue',
        vault: 'Vault',

        "my-cards": {
          base: "My Cards",
          addition: "to subscribe",
        },
        "my-wallet": {
          base: "My Wallet",
          addition: "$138.08",
        },
        "become-a-creator": {
          base: "Become a Creator",
          addition: "to earn",
        },

        "help-&-support": "Help & Support",
        "dark-mode": "Dark mode",
        language: "English",

        logout: "Logout",
        login: "Login",
      },
    },

    whitelistPlague: {
      text1: 'Join the waitlist and we’ll email you when Creator applications are open.',
      button1: 'Join Waitlist',
      text2: 'You have joined the waitlist! We’ll email you when applications are open.',
      button2: 'Waitlist Joined'
    },

    "footer-menu": {
      home: "Home",
      chat: "Chat",
      flows: "Flows",
      menu: "Menu",
      post: "Post",
    },

    flow: {
      tabs: {
        following: "Following",
        forYou: "For You",
      },

      menu: {
        button: {
          tip: "Tip",
        },
      },

      info: {
        button: "SEE FULL VIDEO",
        more: "more",
      },
    },

    stat: {
      fans: "fans",
      followers: "followers",
      views: "views | view | views",
      likes: "likes | like | likes",
      following: "following",
    },

    chat: {
      title: "MESSAGES",
      placeholder: "Type a message....",
      noMessages: "Your chats will appear here",
      noMessagesText: "Get started by messaging a Creator",
    },

    ageConfirm: {
      title: "18+ Only",
      textStart: "FanFlow contains age-restricted content.",
      textMid:
        "To use FanFlow, you must be at least 18 years old and the age of majority and legal consent under the laws of the applicable jurisdiction from which you are accessing this website.",
      textTerm: `By clicking the "Enter FanFlow" button, and by entering FanFlow, you hereby (1) agree to our {terms}; and (2) under penalty of perjury, certify that you are above the age of 18 or the age of majority in your location, whichever is greater, or {leave}`,
      terms: "Terms of Use",
      leave: "Leave FanFlow",
      buttonOk: 'Enter FanFlow'
    },

    collections: {
      title: "COLLECTIONS",
      xUser: "user",
      list: "Lists",
      savedPosts: "Saved Posts",
      searchPlaceholder: "Search collections",
    },

    notFound: {
      title: "Page Not Found",
      message: "Oops we couldn’t find what you were looking for.",
    },

    myProfile: {
      title: "MY PROFILE",
      save: "SAVE",
      input: {
        username: "Username",
        name: "Name",
        bio: "Bio",
        location: "Location",
        bioLink: "Bio Link",
      },
    },

    settingsPages: {
      bundles: {
        title: "BUNDLES",
        button: {
          addNewButton: "ADD NEW BUNDLE",
          save: 'SAVE',
          leftText: "{amount} {month} ({discount}% off)",
          afterPricePreText: 'Only',
          add: 'ADD'
        },
        months: '{months} months',
        monthsPlaceholder: 'Months',
        discountPlaceholder: 'Months',
      },

      subscription: {
        title: "Subscription",
        amount: "Price per month",
        additionText: "Creator status verified",
        additionTextNot: "Creator status not verified",
        groupTitle: "Offer Discounts",
        button: {
          update: 'UPDATE',
          bundles: 'Set Discount Bundles'
        }
      },

      username: {
        title: "USERNAME",
        placeholder: "username",
      },

      email: {
        title: "EMAIL",
        placeholderEmail: "Email",
        placeholderPassword: "Password",
        additionText: "Email {email} is verified",
        additionTextNotVerified: "Email {email} is not verified",
      },

      phone: {
        title: "PHONE",
        placeholderPhone: "Phone number",
        additionText: "We will keep your number private",
      },
      deleteAccount: {
        title: "DELETE ACCOUNT",
        placeholder: "Enter Password",
        button: "DELETE ACCOUNT",
        warningText: "Your account will be permanently deleted!",
      },
      newPassword: {
        title: "PASSWORD",
        forgot: "Forgot password?",
        additionText: "Minimum 8 characters",
        placeholder: {
          currentPassword: "Current Password",
          newPassword: "New Password",
          confirmPassword: "Confirm New Password",
        },
      },
      twitter: {
        title: "TWITTER",
        subTitle: "Connect Twitter",
        button: "LINK TWITTER ACCOUNT",
        settings: {
          title: "Settings",
          loginWith: "Login with Twitter",
          autoTweet: "Auto-tweet Milestones",
          recommend: "Recommend FanFlow to Followers",
        },
      },
      google: {
        title: "GOOGLE",
        subTitle: "Connect Google",
        button: "LINK GOOGLE ACCOUNT",
        settings: {
          title: "Settings",
          loginWith: "Login with Google",
        },
      },
    },

    userNotifications: {
      title: "NOTIFICATIONS",
      radio: {
        all: "All",
        likes: "Likes",
        comments: "Comments",
        mentions: "Mentions",
        followers: "Followers",
      },
    },

    postComponent: {
      tooltip: {
        copyLink: "Copy link to post",
        edit: "Edit",
        report: "Report",
      },
    },

    discover: {
      title: "DISCOVER",
      searchPlaceholder: "Search posts, users, hashtags",
      trendingTopics: "Trending topics",
    },

    popups: {
      addMedia: {
        title: 'Add New Media',
        field: 'Upload Media',
        button: 'SUBMIT UPLOAD',
        upload: 'Click to Upload +'
      },
      howItWorks: {
        title: "How It Works",
        list: {
          item1: "1. Copy & share your referral link",
          item2: "2. Get new Creators to join FanFlow",
          item3: "3. Get new Fans to join FanFlow",
        },
        titleBenefits: "Benefits",
        benefits: {
          item1: 'Earn {percent} of referred Creators earnings',
          item2: 'Earn {percent} of referred Fans purchases',
        }
      },
      updatePostPrice: {
        title: "Update Price",
        priceField: "Pay Per View Price",
        priceFieldPlaceholder: "Enter amount",
        buttonSend: "Update Price",
      },
      createBundle: {
        title: "Add New Bundle",
        button: {
          addNewButton: "ADD NEW BUNDLE",
          save: 'SAVE',
          leftText: "{amount} {month} ({discount}% off)",
          afterPricePreText: 'Only',
          add: 'ADD'
        },
        months: '{months} months',
        monthsPlaceholder: 'Months',
        discountPlaceholder: 'Discount',
      },

      mediaPopup: {
        title: "Upload Media",
      },

      vault: {
        title: "Upload from Vault",
      },

      schedule: {
        title: "Schedule time",
        info: 'Schedule a time in the future to post this content '
      },

      createPromotion: {
        title: "New Promo Campaign",
        titleUpdate: "Update Promo Campaign",
        tab: {
          free: "Free Trial",
          firstMonth: "First Month Discount",
        },

        input: {
          limit: 'Limit',
          expiration: 'Expiration',
          firstMonth: 'First Month Price (min. $2.00)',
          message: 'Message (optional)',
          duration: 'Free Trial Duration',

        }
      },
      paymentMethod: {
        title: 'Choose Payment Method',
        button: 'CHOOSE METHOD',
        newPaymentMethod: "New Payment Method",
        available: "Available",
        wallet: "Wallet",
      },
      subscription: {
        title: 'Subscribe to {name} for {type}',
        titleUnlock: "Unlock this Post for ${price}",
        noPayments: 'No Payment Card Added',
        reasons: '',
        buttonConfirm: 'Confirm subscription',
        buttonChange: 'CHANGE',
        addNew: 'ADD',
        buttonUnlock: "PAY & UNLOCK",
        buttonAdd: 'ADD PAYMENT CARD',
        list1: 'Full access to subscriber-only content',
        list2: 'Unlock this user’s DMs',
        list3: 'Cancel subscription anytime you want',
        renews: 'Renews at ${amount} /month',
        unlockReason1: 'You will get instant access to this exclusive post',
        unlockReason2: 'You will be charged ${price}',
      },
      'cancel-subscription':{
        title: 'Cancel Subscription',
        text: 'Are you sure you want to cancel your subscription?',
        'reason-cancel': 'Reason for Canceling',
        placeholder: 'Select Reason',
        button: 'Unsubscribe',
      },
      'add-funds': {
        title: "Add Funds From Card",
        input: {
          amount: 'Amount ($)'
        },
        button: 'Add Funds',
      },
      addToCollections: {
        title: "Add to List",
        buttonClose: "New collections",
        buttonNewList: "NEW LIST",
        favorites: "Favorites",
        'x-users': "No users | 1 user | {count} users",
      },
      addNewCollections: {
        title: "Add new list",
        placeholder: "Enter new collection title",
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
      },
      expiration: {
        title: "Expiration Period",
        buttonClose: "Set Expiration Period",
        options: {
          24: "24h",
          3: "3days",
          7: "7days",
        },
      },
      location: {
        title: "Add location",
        inputPlaceholder: "Search location",
        info: "Only name of the location will be displayed. Your exact location will not be shown.",
      },
      error: {
        errorDefaultText: "An Error Occurred",
        somethingHappened: "Something happened, please try again.",
      },

      confirmReport: {
        title: "Report Submitted",
        text: "Thanks for helping keep FanFlow safe. Our team will review your report",
      },
      report: {
        title: "Report Post",
        buttonSend: "Send Report",
        field: {
          reason: {
            name: "Reason",
            placeholder: "Select Reason",
          },
          comment: {
            name: "Additional Comment",
            placeholder: "Enter more details about your report",
          },
        },
      },

      tip: {
        title: "Send Tip",
        buttonSend: "Confirm Tip",
        field: {
          tipAmount: {
            name: "Tip Amount",
            placeholder: "Enter tip amount",
            currency: "USD",
          },
          note: {
            name: "Note to Creator (optional)",
            placeholder: "Enter a special note...",
          },
          wallet: {
            name: "Wallet",
            available: "${amount} Available",
          },
          card: {
            name: "Visa...",
            change: "CHANGE",
          },
        },
      },

      language: {
        title: "Change Language",
        "input-placeholder": "Search language",
        languages: {
          english: "English",
          spanish: "Spanish",
          french: "French",
          chinese: "Chinese",
          hindi: "Hindi",
          bengali: "Bengali",
          german: "German",
        },
      },

      comments: {
        title: "Comments",
      },

      confirmFlowNSFW: {
        title: "Switch Flows",
        buttonClose: "OK, don’t show again",
        text: "Switch between normal & NSFW flows whenever you like!",
        clean: "CLEAN 🧼",
        nsfw: "🌶️ NSFW",
      },

      videoCount: `This Flow has {videos} views`,

      settingsFlow: {
        title: "Flow Settings",
        info: "Choose what you want to see in your Flow feed",
        preferences: "Preference",
        buttonFooter: "UPDATE PREFERENCES",
        buttonCreatorPreferences: "Creator Preferences",
        buttonContentPreferences: "Content Preferences",
      },

      contentPreferences: {
        title: "Content Preferences",
        buttonClose: "Set Preferences",
        text: "Select the content you mostly want to see in your For You feed",
        choices: {
          "b/g/nsfw": "B/G NSFW",
          "g/g/nsfw": "G/G NSFW",
          publicInterviews: "Public Interviews",
          tikTok: "TikTok Edits",
          behindTheScenes: "Behind The Scenes",
          dancesGoneWild: "Dances Gone Wild",
          teasingPreviews: "Teasing Previews",
          collegeAdventures: "College Adventures",
        },
      },

      creatorPreferences: {
        title: "Creator Preferences",
        buttonClose: "Set Preferences",
        text: "Select the type of Creators you mostly want to see in your For You feed",
        choices: {
          men: "🧔‍♂️ Men",
          women: "👱‍♀️ Women",
          transWomen: "👱‍♀️🏳️‍⚧️ Trans Women",
          transMen: "👱‍♀️🏳️‍⚧️ Trans Men",
          straight: "👫 Straight",
          lesbian: "👭 Lesbian",
          gay: "👬 Gay",
        },
      },

      flowCategories: {
        title: "Flow Categories",
        text: "Choose 2 categories your Flow falls under to get better exposure from the algorithm",
        buttonClose: "Set Categories",
        choices: {
          "b/g/nsfw": "B/G NSFW",
          "g/g/nsfw": "B/G NSFW",
          publicInterviews: "Public Interviews",
          tikTok: "TikTok Edits",
          behindTheScenes: "Behind The Scenes",
          dancesGoneWild: "Dances Gone Wild",
          teasingPreviews: "Teasing Previews",
          collegeAdventures: "College Adventures",
        },
      },

      lockPopup: {
        title: "Who can view this post",
        choices: {
          everyone: "Everyone",
          followers: "Followers",
          subscribersOnly: "Subscribers only",
          payersOnly: "Payers only",
        },
        buttons: {
          updatePrice: "UPDATE PRICE",
        },
      },

      flowComments: {
        title: "Comments",
        info: "To prevent spam while in beta, you can only see your comments",
      },

      previewPost: {
        title: "Previews",
        text: "Previews are only available on Flows.<br><br> Mark this Flow as a preview and link it to one of your posts.<br><br> A “see full video” button will be added.",
      },
    },

    subscribed: "Subscribed",
    "signup-slogan": "Sign up to support your favorite creators",
    email: "E-mail address",
    password: "Password",
    login: "Login",
    terms: "Terms of Use",
    privacy: "Privacy Policy",
    home: "Home",
    notifications: "Notifications",
    messages: "Messages",
    bookmarks: "Bookmarks",
    lists: "Lists",
    subscriptions: "Subscriptions",
    "my-profile": "My Profile",
    more: "More",
    "new-post": "New Post",
    settings: "Settings",
    cards: "Cards",
    bank: "Bank",
    banking: "Banking",
    logout: "Log out",
    post: "Post",
    poll: "Poll",
    "enter-option": "Enter option",
    "expiration-period": "Expiration Period",
    "x-days": "No limit | 1 day | {n} days",
    schedule: "Schedule",
    "add-option": "+ Add another option",
    date: "Date",
    time: "Time",
    price: "Price",
    "post-price": "Post Price",
    "select-screenshot": "Select Screenshot",
    free: "Free",
    "unlock-post-for-x": "Unlock Post for {0}",
    "unlock-message-for-x": "Unlock Message for {0}",
    "x-likes": "0 likes | 1 like | {count} likes",
    "x-comments": "0 comments | 1 comment | {count} comments",
    "copy-link-to-post": "Copy Link to Post",
    "copy-link-to-profile": "Copy Link to Profile",
    reply: "Reply",
    "add-a-comment": "Add a comment...",
    like: "Like",
    "view-replies": "View replies",
    "view-more-comments": "View more comments",
    "view-more-replies": "View more replies",
    "reply-to-x": "Reply to {0}",
    delete: "Delete",
    save: "Save",
    username: "Username",
    "full-name": "Full Name",
    name: "Name",
    bio: "Bio",
    location: "Location",
    website: "Website",
    "settings-saved": "Settings saved.",
    success: "Success",
    security: "Security",
    "login-sessions": "Login Sessions",
    "new-password": "New Password",
    "confirm-password": "Confirm Password",
    "password-not-match": "Passwords do NOT match",
    "old-password": "Current Password",
    signup: "Sign up for FanFlow",
    "signup-agree":
      "By signing up you agree to our {terms} and {privacy}, and confirm that you are at least 18 years old.",
    "terms-of-use": "Terms of Use",
    "privacy-policy": "Privacy Policy",
    "already-have-an-account": "Already have an account?",
    "forgot-password": "Forgot password?",
    "create-new-account": "Create new account",
    "restore-password": "Restore Password",
    "password-reset-link-sent":
      "Password reset link has been sent to your email.",
    "password-reset": "RESET PASSWORD",
    "add-description": "Add a description...",
    "add-to-list": "Add to List",
    "new-list": "New List",
    close: "Close",
    "enter-list-title": "Enter list title",
    "x-people": "Empty | 1 person | {n} people",
    "type-message": "Type a message...",
    send: "Send",
    "message-price": "Message Price",
    "x-votes": "0 votes | 1 vote | {n} votes",
    subscription: "Subscription",
    "price-bundles": "Price and Bundles",
    "price-per-month": "Price per Month",
    "subscription-bundles": "Subscription Bundles",
    add: "Add",
    "get-x-months-for-y-z-off": "Get {0} months for {1} (total) {2}% off",
    "add-bundle": "Add Bundle",
    discount: "Discount",
    months: "Months",
    "x-months": "0 months | 1 month | {n} months",
    "subscribe-to-see": "Subscribe to See User's Posts",
    payment: "Payment",
    "subscription-to-x": "Subscription to {0}'s channel. Period: {1} month(s).",
    "unlock-post": "Unlock Post",
    "unlock-message": "Unlock Message",
    tip: "Tip",
    "subscribe-for-x": "Subscribe for {0}",
    "subscribed-for-x": "Subscribed for {0}",
    "unlock-post-from-x-for-y": "Unlock Post from {0} for {1}",
    "unlock-message-from-x-for-y": "Unlock Message from {0} for {1}",
    "expires-on": "Expires on {0}",
    unsubscribe: "Unsubscribe",
    "are-you-sure-unsubscribe":
      "Are you sure you want to unsubscribe from {0} channel?",
    unsubscribed: "Unsubscribed",
    "re-subscribe": "Re-Subscribe",
    "noti-comment": "commented on your post: {0}",
    "noti-comment-like": "liked your comment: {0}",
    "noti-like": "liked your post: {0}",
    "noti-subscribe": "subscribed to your channel.",
    suggestions: "Suggestions",
    posts: "Posts",
    expired: "Expired",
    scheduled: "Scheduled",
    edit: "Edit",
    "post-saved": "Post Updated",
    "edit-post": "Edit Post",
    verification: "Verification",
    "first-name": "First Name",
    "last-name": "Last Name",
    country: "Country",
    address: "Address",
    city: "City",
    state: "State / Province",
    zip: "ZIP / Postal",
    "photo-id": "Photo ID",
    "file-uploaded": "File Uploaded",
    "verification-info":
      "In order to verify your account and allow you to accept payments for your content please provide your legal name and address along with a photo ID, such as passport or driver's license.",
    "verification-status": "Status of your verification request is: {0}",
    pending: "Pending",
    declined: "Declined",
    cancel: "Cancel",
    "bank-country": "Bank Country",
    "bank-name": "Bank Name",
    "bank-address": "Bank Address",
    swift: "SWIFT Code",
    "account-number": "Account Number",
    balance: "Balance",
    "minimum-payout": "Minimum Payout",
    payouts: "Payouts",
    "current-balance": "Current Balance: {0}",
    "minimum-withdrawal": "Minimum withdraw amount: {0}",
    "request-withdrawal": "Request Withdrawal",
    "pending-withdrawal": "Pending withdrawal request for {0}",
    amount: "Amount",
    "payout-method": "Payout Method",
    "paypal-address": "PayPal Address",
    "bank-info": "{0} (#{1})",
    paid: "Paid",
    earnings: "Earnings",
    "payment-subscription-to": "Subscription to {to}",
    "payment-subscription-from": "Subscription by {user}",
    "payment-subscription-renew-to": "Subscription renewed to {to}",
    "payment-subscription-renew-from": "Subscription renewed by {user}",
    "payment-post-unlocked": "{post} unlocked",
    "payment-message-unlocked": "{message} unlocked",
    "payment-post-unlocked-from": "{post} from {to} unlocked",
    "payment-message-unlocked-from": "{message} from {to} unlocked",
    "payment-post-unlocked-by": "{post} unlocked by {user}",
    "payment-message-unlocked-by": "{message} unlocked by {user}",
    "payment-tip-from-to": "Tip from {user} to {to}",
    "payment-tip-from": "Tip from {user}",
    "payment-tip-to": "Tip to {to}",
    fee: "Fee",
    net: "Net",
    "private-message": "Private message",
    total: "Total",
    payments: "Payments",
    cookies: "Cookies",
    "cookies-consent":
      "We use cookies to give you the best experience and to ensure the safety of our users. The only non-essential cookies we use are for any personal referrals you make. We do not track you across other sites. You can see our {privacy} here.",
    ok: "OK",
    error: "Error",
    "login-failed": "Login failed.",
    "sign-in-with-google": "LOG IN WITH GOOGLE",
    following: "Following",
    recent: "Recent",
    fans: "Fans",
    "new-message": "New Message",
    next: "Next",
    include: "Include",
    exclude: "Exclude",
    "mass-message": "Mass Message",
    "mass-message-complete":
      'Your recent mass message "{0}" is complete and it has been sent to {1} recipient(s).',
    "mass-message-sending":
      'Your recent mass message "{0}" has been sent to {1} recipient(s).',
    "mass-message-started":
      'Your recent mass message "{0}" is currently being sent.',
    yesterday: "Yesterday",
    today: "Today",
    history: "History",
    "make-main": "Make Main",
    "card-number": "Card Number",
    "payment-methods": "Payment Methods",
    "name-on-card": "Card Holder Name",
    "expiry-date": "Expiry Date",
    main: "Main",
    cvc: "CVC",
    "credit-card": "Credit Card",
    "search-posts": "Search Posts...",
    "message-optional": "Message (optional)",
    "send-tip": "Tip",
    "tip-sent": "Your tip has been sent. Thank you!",
    "tip-message-in": "User has sent {amount} tip.",
    "tip-message-out": "You have sent {amount} tip.",
    "tip-message-post-in": "User has sent {amount} tip for {post}.",
    "tip-message-post-out": "You have sent {amount} tip for {post}.",
    link: "Link",
    "link-copied": "Link copied.",
    subscribe: "Subscribe",
    one: "",
  },
  errors: {
    required: "This is a required field.",
    email: "Must be an email address.",
    "order-fail":
      "Unfortunately your order can not be processed. Please contact us if you believe this is an error.",
    "payment-method": "Error adding payment method.",
  },
  veeValidate: {
    custom: {},
    messages: {
      is: "Passwords do NOT match",
      length: (name, params = "") => {
        return `${params} categories should be selected`;
      },
      // "required": 'This field is required',
      numeric: "The field field may only contain numeric characters",
      // "password": 'Password is incorrect. Try again',
      uniqueFile: "Such file already exists",
      maxLength: (name, params = "") => {
        return `Max allowed length less then -  ${params}`;
      },
    },
  },

  admin: {
    contentPage: {
      title: "CONTENT",
      addButton: "ADD CONTENT",
      buttonsAction: {
        approve: "APPROVE",
        edit: "EDIT",
        reject: "REJECT",
      },
      tabs: {
        pending: "PENDING APPROVAL",
        rejected: "REJECTED",
        active: "ACTIVE",
      },
      table: {
        pending: {
          id: "ID",
          user: "USER",
          username: "USERNAME",
          categories: "CATEGORIES",
          bio: "BIO",
          metadata: "METADATA",
          socials: "SOCIALS",
          status: "STATUS",
          actions: "ACTIONS",
          description: "DESCRIPTION",
          caption: "CAPTION",
          preview: "PREVIEW",
        },
      },
    },
    creatorPage: {
      title: "PARODY CREATORS",
      addButton: "ADD CREATOR",
      buttonsAction: {
        approve: "APPROVE",
        edit: "EDIT",
        reject: "REJECT",
      },
      tabs: {
        pending: "PENDING APPROVAL",
        rejected: "REJECTED",
        active: "ACTIVE",
      },
      table: {
        pending: {
          id: "ID",
          user: "USER",
          username: "USERNAME",
          bio: "BIO",
          metadata: "METADATA",
          socials: "SOCIALS",
          status: "STATUS",
          actions: "ACTIONS",
        },
      },
      createForm: {},
    },

    "admin-console": "Admin Console",
    users: "Users",
    posts: "Posts",
    subscriptions: "Subscriptions",
    payments: "Payments",
    dashboard: "Dashboard",
    all: "All",
    creators: "Creators",
    id: "ID",
    avatar: "Avatar",
    name: "Name",
    username: "Username",
    search: "Search...",
    fans: "Fans",
    actions: "Actions",
    "edit-user": "Edit User",
    requests: "Requests",
    approve: "Approve",
    decline: "Decline",
    show: "Show",
    hide: "Hide",
    active: "Active",
    cancelled: "Cancelled",
    expired: "Expired",
    creator: "Creator",
    user: "User",
    created: "Created",
    expires: "Expires",
    cancel: "Cancel",
    unknown: "Unknown",
    status: "Status",
    resume: "Resume",
    completed: "Completed",
    pending: "Pending",
    refunded: "Refunded",
    date: "Date",
    details: "Details",
    "invoice-id": "Invoice ID",
    token: "Token",
    gateway: "Gateway",
    refund: "Refund",
    complete: "Complete",
    "posts-outsource":
      "Admin can edit and delete any post. Use normal user interface to browse through posts.",
    "open-home": "Open Home",
    "new-users": "New Users",
    "new-posts": "New Posts",
    fees: "Fees",
    sales: "Sales",
    earnings: "Earnings",
    payouts: "Payouts",
    batches: "Batches",
    processed: "Processed",
    "create-batch": "Create Payouts Batch",
    "from-all-pending-requests": "from all pending requests",
    export: "Export",
    batch: "Batch",
    none: "none",
    commission: "Commission",
    "leave-empty-for-default": "Leave empty for default commission: {0}",
    default: "Default",
    one: "one",
    settings: "Settings",
    "trending-topics": "Trending topics",
    "request-id": "Request ID",
    partners: "Partners",
  },
};
