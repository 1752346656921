const state = () => ({
  notifications: [],
  notificationsMeta: null,
});

const actions = {};

const getters = {
  notifications: (state) => state.notifications,
  notificationsMeta: (state) => state.notificationsMeta,
};

const mutations = {
  setPosts(state, { posts } = {}) {
    state.notifications = posts;
  },

  updatePosts(state, { posts } = {}) {
    state.notifications = [...state.notifications, ...posts];
  },

  setMeta(state, { meta } = {}) {
    state.notificationsMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
