import { expirationKeys } from "@/config/expirationKeys";
import { pickBy } from "lodash";

const userAgent =
  window.navigator.userAgent || window.navigator.vendor || window.opera;
export const isIPAD =
  /iPad/i.test(userAgent) ||
  /iPhone OS 3_1_2/i.test(userAgent) ||
  /iPhone OS 3_2_2/i.test(userAgent);
export function isMobile() {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(userAgent);
  return check || isIPAD;
}

export function toFlatObject(obj, concatenator = ".") {
  return Object.keys(obj || {}).reduce((acc, key) => {
    if (typeof obj[key] !== "object") {
      return {
        ...acc,
        [key]: obj[key],
      };
    }

    const flattenedChild = toFlatObject(obj[key], concatenator);

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
        }),
        {}
      ),
    };
  }, {});
}

export function nFormat(number, locales = "en") {
  const formatter = Intl.NumberFormat(locales, {
    notation: "compact",
    maximumFractionDigits: 1,
  });

  return formatter.format(number);
}

export function currencyFormat(number, locales = "en") {
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // No decimal places
    maximumFractionDigits: 2, // Maximum two decimal places
    useGrouping: true, // Use grouping separators (e.g., comma)
  };
  const formatter = Intl.NumberFormat(locales, options);

  return formatter.format(number);
}

export function formatScheduleDateTime(
  isoDateString = "2024-03-20T12:45:40.000000Z"
) {
  const currentDate = new Date(isoDateString);
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const [date, time] = formatter?.format(currentDate).split(", ");

  return { date, time };
}

export function formatSchedulePostDate(isoDateString) {
  if (!isoDateString) return null;

  const date = new Date(isoDateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatSchedulePostDateNoUTC(isoDateString) {
  if (!isoDateString) return null;

  const date = new Date(isoDateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatDateTime(
  isoDateString = "2024-03-20T12:45:40.000000Z",
  dateOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  }
) {
  const date = new Date(isoDateString);
  const dateFormat = new Intl.DateTimeFormat("en-US", dateOptions);
  const timeFormat = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const dateMontShort = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
  });

  return {
    date: dateFormat.format(date), // Mar 18, 2024
    time: timeFormat.format(date), //
    dateMontShort: dateMontShort.format(date), // Mar 18
  };
}

/**
 * Moves an element of the array one position to the left or right.
 * @param {Array} arr - The original array.
 * @param {number} index - The index of the element to be moved.
 * @param {number} direction - The direction of movement (1 for moving to the right, -1 for moving to the left).
 * @returns {Array} A new array with the element moved.
 */
export function moveElement(arr, index, direction) {
  if (index + direction < 0 || index + direction >= arr.length) {
    return arr;
  }

  const newArr = arr.slice();
  const [element] = newArr.splice(index, 1);

  const newIndex = index + (direction === 1 ? 1 : direction);

  newArr.splice(newIndex, 0, element);

  return newArr;
}

export function timeUntilExpiration(startDate, endDate) {
  const hoursLeft = endDate.diff(startDate, "hour");
  const daysLeft = endDate.diff(startDate, "day");

  if (daysLeft >= 2) {
    return { key: expirationKeys.EXPIRES_IN_DAYS, params: { count: daysLeft } };
  } else if (daysLeft === 1) {
    return { key: expirationKeys.EXPIRES_IN_ONE_DAY };
  } else if (hoursLeft >= 1) {
    return {
      key: expirationKeys.EXPIRES_IN_HOURS,
      params: { count: hoursLeft },
    };
  } else if (hoursLeft <= 0) {
    return { key: expirationKeys.EXPIRED };
  } else {
    return { key: expirationKeys.EXPIRES_SOON };
  }
}

export function formatDistance(distanceInMeters) {
  if (!distanceInMeters) return "";

  const distanceInFeet = distanceInMeters * 3.28084;

  const feetInMile = 5280;
  const distanceInMile = distanceInFeet / feetInMile;

  if (distanceInFeet < feetInMile) {
    return `${distanceInFeet.toFixed()} feet`;
  } else if (distanceInMile < 1000) {
    return `${distanceInMile.toFixed(1)} mi`;
  } else {
    const thousandsOfMile = (distanceInMile / 1000).toFixed(1);
    return `${thousandsOfMile}k mi`;
  }
}

export const cleanObj = (obj) =>
  pickBy(obj, (value) => ![null, undefined, "", 0].includes(value));

export function ArrayBufferToBinary(buffer) {
  // Convert an array buffer to a string bit-representation: 0 1 1 0 0 0...
  let dataView = new DataView(buffer);
  let response = "",
    offset = 8 / 8;
  for (let i = 0; i < dataView.byteLength; i += offset) {
    response += dataView.getInt8(i).toString(2);
  }
  return response;
}

export function objectToFormData(obj) {
  const formData = new FormData();
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      formData.append(key, obj[key]);
    }
  }
  return formData;
}

/**
 *
 * @param num {number}
 * @param expressions {string[]}
 * @returns {string}
 */
export function declension(num, expressions) {
  let result;
  let count = num % 100;
  if (count >= 5 && count <= 20) {
    result = expressions["2"];
  } else {
    count %= 10;
    if (count === 1) {
      result = expressions["0"];
    } else if (count >= 2 && count <= 4) {
      result = expressions["1"];
    } else {
      result = expressions["2"];
    }
  }
  return result;
}
