export const availableVideoTypes = ["video/mp4", "video/quicktime"];

const imageMimeTypes = {
    "JPEG": "image/jpeg",
    "JPG": "image/jpeg",
    "PNG": "image/png",
    "GIF": "image/gif",
    "HEIF": "image/heif",
    "HEVC": "video/hevc",
    "HEIC": "image/heic",
    "MP4": "video/mp4"
};
export const availableImagesTypes = Object.values(imageMimeTypes);
export const flowVideoMaxLength = 30;
export const postVideoMaxLength = 1200; // 20 min
