<template>
  <BasePopupLayout
    no-border
    :title="$t('general.popups.mediaPopup.title')"
    :custom-close="close"
  >
    <div class="media-popup">
      <div class="my-3">
        <div class="media-popup__uploads">
          <div class="media-popup__file">
            <div
              role="button"
              @click="selectValue"
              class="button media-popup__button media-popup__button--upload media-popup__button--upload--vault"
            />
            <span class="media-popup__font media-popup__font--upload">
              {{ $t("general.postsCreate.uploadVault") }}
            </span>
          </div>

          <div class="media-popup__file">
            <input
              ref="videoInput"
              type="file"
              :accept="mediaTypes"
              @change="uploadFromDeviceCallback"
              hidden
            />
            <button
              ref="file"
              type="button"
              @click="mediaClick"
              class="button media-popup__button media-popup__button--upload"
            />
            <span class="media-popup__font media-popup__font--upload">
              {{ $t("general.postsCreate.upload") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import uploadFromDevice from "@/mixins/uploadFromDevice";
export default {
  name: "MediaPopup",
  components: {
    BasePopupLayout,
  },
  mixins: [uploadFromDevice],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },

  methods: {

    uploadFromDeviceCallback(e) {
      this.data.uploadDeviceCallback(e);
    },
    selectValue() {
      this.$popup.open("VaultPopup", {
        ...this.data,
      });
    },

    close() {
      if (this.data.closeCallback) {
        this.data.closeCallback();
      } else {
        this.$popup.close(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-popup {
  &__uploads {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    //padding: 1em;
  }

  &__font {
    &--upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: $app-gray-12;
      text-align: center;

      font-size: em(12);
      font-weight: 400;
      pointer-events: none;
      white-space: nowrap;
    }
  }

  &__file {
    position: relative;
    width: width-with-gap();
  }

  &__button {
    &--header {
      height: em(28);
      text-transform: uppercase;
    }

    &--upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: em(53);
      border-radius: em(5);
      border: 1px dashed $app-gray-11;
      overflow: hidden;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: em(54) 0 0 0;
      position: relative;

      &--vault {
        background-color: $app-gray;
      }
    }
  }
}
</style>
