import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";
// import { cleanObj } from "@/tools/helpers";

const state = () => ({
  payout: {
    data: [],
    current_page: 1,
    links: {
      first: "",
      last: "",
      prev: "",
      next: "",
    },
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      links: [
        {
          url: "",
          label: "1",
          active: true,
        },
      ],
      path: "",
      per_page: 1,
      to: 1,
      total: 1,
    },
  },
});

const getters = {
  payout: (state) => state.payout,
};

const actions = {
  addPayout({ commit }, { amount } = {}) {
    console.log('amount', amount)

    return axios({ method: "POST", url: "/payouts", data: { amount } }).then(
      (response) => {
        commit(
          "addPayout",
          { payout: response.data.payout }
        );

        return response;
      }
    );
  },

  fetchPayout({ commit }, { page = 1 }) {
    const requestQuery = {
      page,
    };
    const params = cleanObj(requestQuery);

    console.log("fetchPayout");
    return axios({ method: "GET", url: "/payouts", params }).then(
      (response) => {
        commit("setPayout", { payout: response.data });

        return response;
      }
    );
  },
};

const mutations = {
  setCards(state, { cards } = {}) {
    state.cards = cards;
  },

  // deleteCard(state, { card, type = statementsTypes.active }) {
  //   state.cards[type].data = state.cards?.[type]?.data?.filter(
  //     (item) => item?.id !== card.id
  //   );
  // },
  //
  // updatePayout(state, { card }) {
  // const listDataIndex = state.cards?.[type]?.data?.findIndex(
  //   (item) => item?.id === card.id
  // );
  //
  // if (listDataIndex === -1) return;
  //
  // state.cards[type].data[listDataIndex] = { ...card };
  // state.cards[type]?.data.splice(state.cards[type]?.data?.length);
  // },

  addPayout(state, { payout }) {
    state.payout.data.push(payout);
  },

  setPayout(state, { payout } = {}) {
    if (payout.current_page === 1) {
      state.payout = payout;
      return;
    }

    state.payout = {
      ...payout,
      data: [...(state.payout.data || []), ...(payout.data || [])],
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
