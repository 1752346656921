<template>
  <div class="app-comments">
    <div class="app-comments__avatar">
      <b-avatar
        :text="comment.user.initials"
        :src="comment.user.avatar"
        :to="comment.user.url"
        size="28px"
        @click="$popup.close()"
      />
    </div>

    <div class="app-comments__main">
      <div class="app-comments__user">
        <span class="app-comments__font app-comments__font--user">
          {{ comment.user.name }}
        </span>
      </div>

      <div class="app-comments__message">
        <span
          class="app-comments__font app-comments__font--message"
          v-html="parsedMessage"
        />
      </div>

      <div class="app-comments__addition">
        <span
          v-if="comment.created_at"
          class="app-comments__font app-comments__font--addition"
        >
          {{ createdAt }}
        </span>

        <button
          type="button"
          @click.prevent="reply"
          class="app-comments__button"
        >
          <span class="app-comments__font app-comments__font--addition">
            Reply
          </span>
        </button>

        <button
          type="button"
          @click.prevent="del"
          v-if="isOwner"
          class="app-comments__button"
        >
          <span class="app-comments__font app-comments__font--addition">
            {{ $t("general.delete") }}
          </span>
        </button>
      </div>
    </div>

    <div class="app-comments__like">
      <button type="button" class="button" @click.prevent="addLike">
        <span class="app-comments__like-counter" v-if="likeObject.likesCount">
          <span class="app-comments__font app-comments__font--addition">
            {{ likeObject.likesCount }}
          </span>
        </span>

        <CIcon
          name="like"
          path="comments"
          class="app-comments__icon app-comments__icon--like"
          :class="{ 'app-comments__icon--like--active': likeObject.isLike }"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import dayjs from "dayjs";

import Comment from "@/components/models/Comment";
import User from "@/components/models/User";

import CIcon from "@/features/ui/CIcon.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import like, { likeBaseUrls } from "@/mixins/like";

export default {
  components: { CIcon },

  props: {
    item: {
      type: Comment,
      require: true,
    },
  },

  mixins: [waitRequest, validationError, like],

  data() {
    return {
      comment: this.item,
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),

    parsedMessage() {
      const regex = /@([0-9a-z|\-|_]+)/gi;
      return this.comment.message
          .replace(/[\u00A0-\u9999<>&]/g, function (i) {
            return "&#" + i.charCodeAt(0) + ";";
          })
          .replace(
              regex,
              "<a href='" + process.env.VUE_APP_APP_URL + "/$1'>$&</a>"
          );
    },

    isAdmin() {
      return this.user?.role === User.ROLE_ADMIN;
    },

    isOwner() {
      const isCommentOwner = this.comment?.user?.id === this.user.id;

      return this.isAdmin || isCommentOwner;
    },

    createdAt() {
      return dayjs(this.comment.created_at).fromNow();
    },
  },

  mounted() {
    this.initStat();
  },

  methods: {
    initStat() {
      const { id, isLiked, likesCount } = this.item;
      this.setLikeData({
        baseRoute: likeBaseUrls.comments,
        id,
        isLike: isLiked,
        likesCount,
      });
    },

    reply() {
      const {
        user: { username } = {},
        message,
      } = this.comment;
      this.$emit("reply", { username, message });
    },

    del() {
      this.$emit("delete-comment", this.comment);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-comments {
  display: flex;
  flex-wrap: wrap;
  padding: em(10) em(12);
  margin-bottom: em(20);
  width: 100%;
  pointer-events: auto;

  &__avatar {
    margin-right: em(11);
  }

  &__message {
    margin-top: em(3);
  }

  &__main {
    flex: 1;
  }

  &__user {
    justify-content: flex-start;
  }

  &__like-counter {
    display: inline-flex;
    margin-right: em(5);
  }

  &__like {
    display: flex;
  }

  &__button {
    display: inline;
    width: auto;

    &:not(:first-child) {
      &:before {
        content: " · ";
        color: $app-font-color-gray-6;
        font-size: em(10);
        position: relative;
      }
    }
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--message {
      font-size: em(12);
    }

    &--user {
      font-weight: 500;
    }

    &--addition {
      color: $app-font-color-gray-6;
      font-size: em(10);
    }
  }

  &__icon {
    &--like {
      width: em(13);
      height: em(12);
      color: $app-gray-7;
      transition: color $time-normal $ease, fill $time-normal $ease;

      &--active {
        color: $app-red;
        fill: $app-red;
      }
    }
  }
}
</style>
