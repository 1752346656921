<template>
<!--  TODO -->
<!--  <b-row>-->
<!--    <b-col class="page-home">-->
    <div
      v-if="list.length"
      class="infinity-scroll"
      :class="{
        'infinity-scroll--tile': isGrid,
        'infinity-scroll--gap': gap,
      }"
    >
      <div
        v-for="(item, index) in list"
        :key="`${item.id}_${index}`"
        class="infinity-scroll__view"
      >
        <slot :item="item" />
      </div>

      <div ref="observerElement" class="infinity-scroll__observer" />
    </div>
<!--    </b-col>-->
<!--  </b-row>-->
</template>

<script>
const viewType = {
  default: "default",
  tile: "tile",
};

export default {
  name: "InfinityScroll",
  props: {
    items: {
      type: Array,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: viewType.default,
      validator(value) {
        return [viewType.default, viewType.tile].includes(value);
      },
    },

    isGrid: {
      type: Boolean,
      default: false,
    },

    gap: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    items: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          this.$nextTick(() => {
            this.initObserver();
          });
        }
      },
    },
  },

  computed: {
    list() {
      return this.items || [];
    },
  },

  data() {
    return {
      observer: null,
    };
  },

  mounted() {},

  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$refs.observerElement);
    }
  },

  methods: {
    initObserver() {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && this.hasMore) {
          this.richEnd();
        }
      });

      this.observer.observe(this.$refs.observerElement);
    },

    richEnd() {
      this.$emit("rich-end");
    },
  },
};
</script>

<style scoped lang="scss">
.infinity-scroll {
  $parent: &;

  &--tile {
    display: grid;
    grid-auto-flow: row dense;
    grid-gap: em(2);
    grid-template: 1fr / repeat(3, 1fr);
  }

  &--gap {
    grid-gap: em(10);
  }

  &__observer {
    position: absolute;
    bottom: 150px;
  }
}
</style>
