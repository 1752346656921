const state = () => ({
    contains: [],
});

const actions = {};

const getters = {
  isContains: (state) => Boolean(state.contains.length),
};

const mutations = {
  setContains(state, { contains }) {
    state.contains = [...contains];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
