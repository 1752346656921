<template>
  <div class="popup-base-header">
    <button
      type="button"
      class="popup-base-header__close"
      @click="$emit('close')"
    >
      <CIcon name="close" />
    </button>

    <div class="popup-base-header__title">
      <span class="popup-base-header__font popup-base-header__font--title">
        {{ title }}
      </span>
    </div>

    <div class="popup-base-header__addition">
      <slot />
    </div>
  </div>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "PopupBaseHeader",
  components: { CIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.popup-base-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: em(24);

  &__title {
    font-family: $font-default;
    font-size: em(18);
  }

  &__addition {
  }

  &__close {
    width: em(18);
    height: em(18);
  }
}

</style>
