<template>
  <ConfirmPopupLayout
    class="add-new-collections-popup"
    :title="$t('general.popups.addNewCollections.title')"
    :show-bottom-button="false"
  >
    <form
      class="add-new-collections-popup__content"
      novalidate
      @submit.prevent="createNewList"
    >
      <div class="add-new-collections-popup__settings">
        <CInput
          ref="input"
          type="text"
          name="title"
          input-mode="text"
          theme="secondary"
          :custom-errors="errors"
          v-model="newCollectionsTitle"
          :placeholder="$t('general.popups.addNewCollections.placeholder')"
        />
      </div>
      <div class="add-new-collections-popup__footer">
        <button type="button" @click="customClose">
          {{ $t("general.popups.addNewCollections.buttons.cancel") }}
        </button>

        <button type="submit">
          {{ $t("general.popups.addNewCollections.buttons.ok") }}
        </button>
      </div>
    </form>
  </ConfirmPopupLayout>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";

export default {
  name: "AddNewCollectionsPopup",
  components: {
    CInput,
    ConfirmPopupLayout,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [waitRequest, validationError],

  data() {
    return {
      newCollectionsTitle: "",
      resp: null,
    };
  },

  methods: {
    customClose() {
      this.$popup.open("AddToCollectionsPopup", {
        addedUserId: this.data.addedUserId,
      });
    },

    async createNewList() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/lists",
          {
            title: this.newCollectionsTitle,
          },
          (data) => {
            this.resp = data;
          },
          () => {
            // this.errors = errors;
          },
          this.checkErrors
        );
      })
        .then(() => {
          this.addToCollection(this.resp).then(() => {
            this.customClose();
          });
        })
        .catch(this.checkErrors);
    },

    addToCollection(collection) {
      const collectionId = collection.id;

      return this.waitRequest(() => {
        return this.$post(
          "/lists/" + this.data.addedUserId + "/" + collectionId,
          {},
          () => {},
          () => {},
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-collections-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
    pointer-events: auto;
  }

  &__settings {
    //margin-top: em(30);
    margin-bottom: em(12);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-bottom: em(22);
  }

  &__button {
    margin-bottom: em(16);
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.add-new-collections-button {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
