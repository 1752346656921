import Vue from "vue";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

let lockCount = 0;

const bodyLock = {
  install() {
    Vue.prototype.$bodyLock = {
      lock(target, disablePadding = false) {
        const { body } = document;
        const scrollBarWidth = window.innerWidth - document.body.clientWidth;
        const needPadding = document.querySelectorAll(".need-padding-locked");

        if (lockCount === 0 && !disablePadding) {
          body.style.paddingRight = `${scrollBarWidth}px`;

          needPadding.forEach((el) => {
            el.style.paddingRight = `${scrollBarWidth}px`;
          });
        }

        disableBodyScroll(target, {
          allowTouchMove: (el) => {
            while (el && el !== document.body) {
              // Скролл для элементов внутри попапа
              if (el.getAttribute("body-scroll-lock-ignore") !== null) {
                return true;
              }

              el = el.parentElement;
            }
            return false;
          },
        });

        lockCount++;
      },
      unlock(target) {
        const { body } = document;
        const needPadding = document.querySelectorAll(".need-padding-locked");

        if (lockCount === 1) {
          body.style.paddingRight = null;

          needPadding.forEach((el) => {
            el.style.paddingRight = null;
          });
        }

        enableBodyScroll(target);

        if (lockCount > 0) lockCount--;
      },
      unlockAll() {
        clearAllBodyScrollLocks();
      },
    };
  },
};

export default bodyLock;
