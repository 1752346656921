<template>
  <div class="progress">
    <span class="progress__line" :style="`width: ${size}%;`"></span>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    size: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  width: 100%;
  max-width: em(296);
  height: em(4);
  background: $white;
  overflow: hidden;

  &__line {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $app-blue;
    width: 20%;
    transition: width 100ms ease-in-out;
  }
}
</style>
