<template>
  <BasePopupLayout :title="$t('general.popups.tip.title')">
    <div class="tip-popup">
      <form class="tip-popup__form" novalidate @submit.prevent="sendTip">
        <div class="tip-popup__field">
          <div class="tip-popup__field-name">
            <span class="tip-popup__font tip-popup__font--field-name">
              {{ $t("general.popups.tip.field.tipAmount.name") }}
            </span>
          </div>

          <CInput
            class="tip-popup__input"
            type="text"
            name="amount"
            input-mode="text"
            :custom-errors="errors"
            theme="comments"
            v-model.number="form.amount"
            :placeholder="$t('general.popups.tip.field.tipAmount.placeholder')"
          >
            <template #appendAfter>
              <span class="tip-popup__font tip-popup__font--addition">USD</span>
            </template>
          </CInput>
        </div>

        <div class="tip-popup__field">
          <div class="tip-popup__field-name">
            <span class="tip-popup__font tip-popup__font--field-name">
              {{ $t("general.popups.tip.field.note.name") }}
            </span>
          </div>

          <CInput
            class="tip-popup__input"
            type="text"
            name="message"
            input-mode="text"
            theme="comments"
            v-model.trim="form.message"
            :placeholder="$t('general.popups.tip.field.note.placeholder')"
          />
        </div>

        <div class="tip-popup__settings">
          <CRadio
            v-model="selected"
            v-for="(item, index) in paymentMethods"
            :key="`${index + '-group'}`"
            :name="item.name"
            :value="item"
            group-name="settings"
            class="tip-popup__radio-row"
          >
            <template #default="{ isActive, value }">
              <div
                class="button card-settings"
                :class="{ 'card-settings--active': isActive }"
              >
                <CIcon
                  :name="value.icon"
                  path="tip"
                  class="card-settings__icon"
                />

                <span class="card-settings__font">
                  {{ value.method }}
                </span>
              </div>
            </template>

            <template #appendAfter="{ value }">
              <button type="button" @click="value.action">
                <span class="card-settings__font card-settings__font--addition">
                  {{ value.buttonText }}
                </span>
              </button>
            </template>
          </CRadio>
        </div>

        <div class="tip-popup__footer">
          <CButton type="submit" variant="primary" class="tip-popup__button">
            <span class="tip-popup__font tip-popup__font--button">
              {{ $t("general.popups.tip.buttonSend") }}
            </span>
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CIcon from "@/features/ui/CIcon.vue";
import CRadio from "@/features/ui/CRadio.vue";
import { mapActions, mapGetters } from "vuex";
import Payment from "@/components/models/Payment";

export default {
  name: "TipPopup",
  components: { CRadio, CIcon, CButton, CInput, BasePopupLayout },
  mixins: [waitRequest, validationError],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      wallet: "wallet/wallet",
      paymentsMethods: "payments/cards",
      tempTip: "payments/tempTip",
    }),
    activeMethod() {
      return this.paymentsMethods.cards?.methods?.find((item) => item.main);
    },
    walletMethod() {
      return {
        id: 1,
        method: this.$t("general.popups.tip.field.wallet.name"),
        icon: "wallet-money",
        buttonText: this.$t("general.popups.tip.field.wallet.available", {
          amount: this.wallet?.balance || 0,
        }),
        methodData: { gateway: "wallet", id: null },
        action: () => {}
      };
    },
    paymentMethods() {
      return [this.walletMethod, this.activePaymentMethod];
    },

    activePaymentMethod() {
      if (this.data.changedMethod) {
        const { brand, title, id } = this.data.changedMethod;
        const number = title?.replaceAll("*", "") || "";
        const method = `${brand || ""}...${number}`;

        return {
          methodData: this.data.changedMethod,
          method,
          id,
          icon: "card",
          action: this.changePaymentMethod,
          buttonText:
            this.$t("general.popups.subscription.buttonChange") ||
            this.$t("general.popups.subscription.addNew") ||
            "",
        };
      }

      if (this.activeMethod) {
        const { brand, title, id } = this.activeMethod;
        const number = title?.replaceAll("*", "") || "";
        const method = `${brand || ""}...${number}`;

        return {
          methodData: this.activeMethod,
          method,
          id,
          icon: "card",
          action: this.changePaymentMethod,
          buttonText:
            this.$t("general.popups.subscription.buttonChange") ||
            this.$t("general.popups.subscription.addNew") ||
            "",
        };
      }

      return {
        icon: "card",
        methodData: this.activeMethod,
        method: this.$t("general.popups.subscription.noPayments"),
        action: this.addPaymentMethod,
        buttonText: this.$t("general.popups.subscription.addNew"),
      };
    },
  },

  data() {
    return {
      form: {
        amount: 1,
        message: "",
      },

      note: "",

      selected: [{
        id: 1,
        method: this.$t("general.popups.tip.field.wallet.name"),
        icon: "wallet-money",
        some: "some",
        buttonText: this.$t("general.popups.tip.field.wallet.available", {
          amount: this.wallet?.balance || 0,
        }),
        methodData: { gateway: "wallet", id: null },
        action: () => {}
      }],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapActions({
      fetchPaymentCards: "payments/fetchPaymentCards",
      createPayments: "payments/createPayments",
      fetchWallet: "wallet/fetchWallet",
      setTempTip: "payments/setTempTip",
    }),
    initState() {
      this.fetchPaymentCards().catch(this.checkErrors);
      this.fetchWallet().catch(this.checkErrors);
    },
    close() {
      this.setTempTip(null);
      this.$emit("close");
    },

    changePaymentMethod() {
      this.$popup.open("ChoosePaymentMethodPopup", {
        closeCallback: this.onAddPaymentCallback,
      });
    },

    addPaymentMethod() {
      this.$popup.close(1);
      this.$popup.open(
        "AddCardPopup",
        { closeCallback: this.onAddPaymentCallback },
        2
      );
    },

    onAddPaymentCallback(payload) {
      this.$popup.close(2);
      this.$popup.open("TipPopup", payload);
    },

    sendTip() {
      if (this.requestInProgress) return;
      this.$validator.errors.clear();
      const currentTipType = this.tempTip.tipType;

      const paymentMethod = this.selected.methodData;
      const gateway = paymentMethod.gateway;
      const payment_method_id = paymentMethod.id;

      let to_id,
        message_id,
        post_id = null;

      if (currentTipType === Payment.TYPE_TIP) {
        to_id = this.tempTip?.user?.id;
      } else if (currentTipType === Payment.TYPE_POST) {
        post_id = this.tempTip?.id;
      } else if (currentTipType === Payment.TYPE_MESSAGE) {
        message_id = this.tempTip?.id;
      }

      let data = {
        type: currentTipType,
        amount: this.form?.amount || 0,
        message: this.form?.message || "",

        gateway,
        payment_method_id,
        to_id,
        message_id,
        post_id,
      };

      return this.waitRequest(() => {
        return this.createPayments(data)
          .then(() => {
            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    action() {
      console.log("super");
    },

    failureReport(error) {
      console.log("error", error);
    },

    successReport() {
      this.$popup.open("ConfirmPopup", {
        button: "Ok",
        title: this.$t("general.popups.confirm.title"),
        text: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-popup {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__field {
    margin-bottom: em(6);
  }

  &__settings {
    //display: flex;
  }

  &__radio-row {
    margin-bottom: em(20);
  }

  &__input {
    margin-top: em(10);
  }

  &__button {
    margin-bottom: em(10);
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--button {
      color: $white;
      font-weight: 700;
    }

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
