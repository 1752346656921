import axios from "@/plugins/axios";
// import { cleanObj } from "@/tools/helpers";

const state = () => ({
  countries: null,
});

const getters = {
  countries: (state) => state.countries,
};

const actions = {
  fetchCountriesWithStates({ commit }) {
    return axios({ method: "GET", url: "/collect/countries-with-states" }).then(
      (response) => {
        commit("setCountries", response.data?.countries);
        return response;
      }
    );
  },
};

const mutations = {
  setCountries(state, countries) {
    state.countries = countries;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
