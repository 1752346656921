export default class GeolocationService {
  static getUserLocation() {
    return new Promise(
      (resolve) => {
        if (navigator.geolocation) {
          const st = "strr";
          if (st === "str") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const userLocation = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                localStorage.setItem(
                  "userLocation",
                  JSON.stringify(userLocation)
                );
                resolve(userLocation);
              },
              (error) => {
                if (error.code == 1) {
                  console.log("Пользователь отказал в доступе к геолокации.");
                  localStorage.setItem(
                    "userLocation",
                    JSON.stringify({
                      error: "Пользователь отказал в доступе к геолокации.",
                    })
                  );
                } else if (error.code == 2) {
                  console.log("Информация о местоположении недоступна.");
                  localStorage.setItem(
                    "userLocation",
                    JSON.stringify({
                      error: "Информация о местоположении недоступна.",
                    })
                  );
                } else if (error.code == 3) {
                  console.log("Превышено время ожидания получения геолокации.");
                  localStorage.setItem(
                    "userLocation",
                    JSON.stringify({
                      error: "Превышено время ожидания получения геолокации.",
                    })
                  );
                } else {
                  console.log(
                    "Произошла неизвестная ошибка при получении геолокации."
                  );
                }
              },
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 7200000 // 2h,
              }
            );
          } else {
            // reject("Geolocation is not supported by this browser.");
            // console.log(
            //   'localStorage.getItem("userLocation");',
            //   localStorage.getItem("userLocation")
            // );
          }
        }
      }
    );
  }

  static getStorageLocation() {
    return localStorage.getItem("userLocation");
  }
}
