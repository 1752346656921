<template>
  <ConfirmPopupLayout
    class="confirm-popup"
    :showBottomButton="false"
    :custom-close="close"
  >
    <div class="how-it-works-popup">
      <div class="how-it-works-popup__content">
        <div class="how-it-works-popup__title">
          <span
            class="how-it-works-popup__font how-it-works-popup__font--title"
          >
            {{ $t("general.popups.howItWorks.title") }}
          </span>
        </div>
        <div class="how-it-works-popup__list">
          <div class="how-it-works-popup__item">
            <span
              class="how-it-works-popup__font how-it-works-popup__font--item"
            >
              {{ $t("general.popups.howItWorks.list.item1") }}
            </span>
          </div>
          <div class="how-it-works-popup__item">
            <span
              class="how-it-works-popup__font how-it-works-popup__font--item"
            >
              {{ $t("general.popups.howItWorks.list.item2") }}
            </span>
          </div>
          <div class="how-it-works-popup__item">
            <span
              class="how-it-works-popup__font how-it-works-popup__font--item"
            >
              {{ $t("general.popups.howItWorks.list.item3") }}
            </span>
          </div>
        </div>

        <div class="how-it-works-popup__benefits">
          <div
            class="how-it-works-popup__title how-it-works-popup__title--benefits"
          >
            <span
              class="how-it-works-popup__font how-it-works-popup__font--benefits"
            >
              {{ $t("general.popups.howItWorks.titleBenefits") }}
            </span>
          </div>

          <div
            class="how-it-works-popup__list how-it-works-popup__list--benefits"
          >
            <div class="how-it-works-popup__item how-it-works-popup__item--benefits">
              <CIcon name="check" class="how-it-works-popup__icon" />

              <i18n
                path="general.popups.howItWorks.benefits.item1"
                tag="span"
                class="how-it-works-popup__font how-it-works-popup__font--benefit-item"
              >
                <template v-slot:percent>
                  <span
                    class="how-it-works-popup__font how-it-works-popup__font--benefit-item how-it-works-popup__font--benefit-item--strong"
                  >
                    10%
                  </span>
                </template>
              </i18n>
            </div>
            <div class="how-it-works-popup__item how-it-works-popup__item--benefits">
              <CIcon name="check" class="how-it-works-popup__icon" />

              <i18n
                path="general.popups.howItWorks.benefits.item2"
                tag="span"
                class="how-it-works-popup__font how-it-works-popup__font--benefit-item"
              >
                <template v-slot:percent>
                  <span
                    class="how-it-works-popup__font how-it-works-popup__font--benefit-item how-it-works-popup__font--benefit-item--strong"
                  >
                    5%
                  </span>
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "WhatReferralPopup",
  components: {
    CIcon,
    ConfirmPopupLayout,
  },
  methods: {
    close() {
      if (this.data.callback) {
        this.data.callback();
        return;
      }

      this.$popup.close(2);
    },
  },
};
</script>

<style scoped lang="scss">
.how-it-works-popup {
  $parent: &;

  //width: em(420);
  width: 100%;

  &__content {
    padding: em(32) em(25) em(25);
  }

  &__title {
    margin-top: em(-7);
  }

  &__list {
    margin-top: em(18);

    &--benefits {
      margin-top: em(21);
    }
  }

  &__item {
    &--benefits {
      &:not(:first-child) {
        margin-top: em(11);
      }
    }
  }

  &__benefits {
    margin-top: em(33);
  }


  &__font {
    font-family: $font-default;
    text-align: center;
    font-style: normal;

    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: $black;
    }

    &--item {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 171%;
      text-align: center;
      color: $app-gray-34;
    }

    &--benefit {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: $black;
    }
    &--benefit-item {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 114%;
      color: $black;

      &--strong {
        font-weight: 700;
      }
    }
  }

  &__icon {
   width: em(14);
   min-width: em(14);
   height: em(14);
   min-height: em(14);

    &--success {
      width: em(49);
      height: em(49);
    }
  }
}
</style>
