import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";
import { FormStorageService } from "@/services/FormStorageService";
import { massMessage } from "@/config/messages";

const state = () => ({
  searchedUsers: [],
  userSendingAmount: 0,
  massMessage: {
    message: "",
    media: [],
    price: null,
    include: [],
    exclude: [],
    include_users: [],
    exclude_users: [],
  },
});

const getters = {
  searchedUsers: (state) => state.searchedUsers,
  massMessage: (state) => state.massMessage,
  userSendingAmount: (state) => state.userSendingAmount,
};

const actions = {
  fetchSearchedUsers({ commit }, { searchString = "" } = {}) {
    const requestQuery = {
      q: searchString,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: `/messages/search`, params }).then(
      (response) => {
        commit("setSearchedUsers", { users: response.data?.users ?? [] });

        return response;
      }
    );
  },

  createMassMessage({ commit }, payload = undefined) {
    return axios({
      method: "POST",
      url: `/messages`,
      data: payload,
    }).then((response) => {
      commit("setUsersAmount", response.data?.count);

      if (!payload?.validate_only) {
        commit("setUsersAmount", 0);

        FormStorageService.clearForm(massMessage.form);
        FormStorageService.clearForm(massMessage.users);
        commit("setMassMessageData", {
          message: "",
          media: [],
          price: null,
          include: [],
          exclude: [],
          include_users: [],
          exclude_users: [],
        });
      }

      return response;
    });
  },
};

const mutations = {
  setSearchedUsers(state, { users = [] } = {}) {
    state.searchedUsers = users;
  },
  setMassMessageData(state, massMessageData) {
    state.massMessage = massMessageData;
  },
  setUsersAmount(state, amount) {
    state.userSendingAmount = amount;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
