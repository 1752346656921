import { debounce } from "lodash";

export default {
  data() {
    return {
      locationList: [],
    };
  },
  methods: {
    onLocationInput(input) {
      this.fetchLocation(input);
    },

    fetchLocation: debounce(function (input) {
      if (this.requestInProgress) return;

      const path = `/posts/location`;
      const url = new URL(path, process.env.VUE_APP_API_URL);
      url.searchParams.set("query", input);
      const searchUrl = path + url.search;

      return this.waitRequest(() => {
        return this.$get(
          searchUrl,
          this.successLoad,
          this.failureLoad,
          this.checkErrors
        );
      }).catch(this.checkErrors);
    }, 500),

    successLoad(locationData) {
      this.locationList = locationData?.addresses;
    },

    failureLoad(locationLoadError) {
      console.log("locationLoadError", locationLoadError);
    },
  },
};
