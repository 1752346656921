const state = () => ({
  selectedCollections: [],
  selectedCollectionsMeta: null,
});

const actions = {};

const getters = {
  selectedCollections: (state) => state.selectedCollections,
  selectedCollectionsMeta: (state) => state.selectedCollectionsMeta,
};

const mutations = {
  setSelectedCollections(state, { selectedCollections } = {}) {
    state.selectedCollections = selectedCollections;
  },

  updateSelectedCollections(state, { selectedCollections } = {}) {
    state.selectedCollections = [
      ...state.selectedCollections,
      ...selectedCollections,
    ];
  },

  setMeta(state, { meta } = {}) {
    state.selectedCollectionsMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
