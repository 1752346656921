import { subscriptionTypes } from "@/config/subscriptionTypes";
import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";

const state = () => ({
  cards: {
    [subscriptionTypes.active]: {
      data: [],
      current_page: 1,
    },
    [subscriptionTypes.cancelled]: {
      data: [],
      current_page: 1,
    },
  },
  tempSubscriptionObject: null,
});

const actions = {
  fetchSubscription(
    { commit },
    { page = 1, type = subscriptionTypes.active } = {}
  ) {
    const requestQuery = {
      page,
      type,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/subscriptions", params }).then(
      (response) => {
        commit("setCards", {
          cards: response.data.subs,
          type,
        });

        return response;
      }
    );
  },

  fetchMoreSubscription(
    { commit },
    { page = 1, type = subscriptionTypes.active } = {}
  ) {
    const requestQuery = {
      page,
      type,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/subscriptions", params }).then(
      (response) => {
        commit("updateCards", { cards: response.data.subs, type });

        return response;
      }
    );
  },

  createSubscription(
    store,
    { userId, data: { payment_method_id = undefined, bundle_id = undefined, promo_id = undefined } }
  ) {
    const data = cleanObj({ payment_method_id, bundle_id, promo_id });

    return axios({
      method: "POST",
      url: `/subscribe/${userId}`,
      data,
    }).then((response) => {

      return response;
    });
  },

  deleteSubscription(store, { userId, reason = 1 }) {
    const params = cleanObj({ reason });

    return axios({
      method: "DELETE",
      url: `/subscriptions/${userId}`,
      params,
    }).then((response) => {

      return response;
    });
  },
};

const getters = {
  cards: (state) => state.cards,
  tempSubscriptionObject: (state) => state.tempSubscriptionObject,
};

const mutations = {
  setCards(state, { cards, type = subscriptionTypes.active } = {}) {
    state.cards[type] = cards;
  },

  deleteCard(state, { card, type = subscriptionTypes.active }) {
    state.cards[type].data = state.cards?.[type]?.data?.filter(
      (item) => item?.id !== card.id
    );
  },

  updateCard(state, { card, type = subscriptionTypes.active }) {
    const listDataIndex = state.cards?.[type]?.data?.findIndex(
      (item) => item?.id === card.id
    );

    if (listDataIndex === -1) return;

    state.cards[type].data[listDataIndex] = { ...card };
    state.cards[type]?.data.splice(state.cards[type]?.data?.length);
  },

  updateCards(state, { cards, type } = {}) {
    state.cards[type] = {
      ...cards,
      data: [...(state.cards[type]?.data || []), ...(cards.data || [])],
    };
  },

  setCurrentSubscription(state,  subscription) {
    state.tempSubscriptionObject = subscription
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
