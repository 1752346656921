const state = () => ({
  collection: [],
});

const actions = {};

const getters = {
  collection: (state) => state.collection,
};

const mutations = {
  setCollections(state, { collection } = {}) {
    state.collection = collection;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
