<template>
  <div
    class="c-radio"
    :class="{
      'c-radio--disabled': disabled,
    }"
  >
    <label class="c-radio__label">
      <!--        v-validate="rules"-->
      <input
        v-validate="rules"
        class="c-radio__input"
        :type="inputType"
        :name="groupName"
        :value="value[valueKey] || value || (isCheckbox && groupName)"
        :checked="isActive"
        @change="changeInput"
        @click="clickHandler"
      />
      <slot :isActive="isActive" :value="value"  :error="errors"/>
    </label>

    <span class="c-radio__append c-radio__append--after">
      <slot name="appendAfter" :value="value" />
    </span>
  </div>
</template>

<script>
export default {
  name: "CRadio",
  inject: ["$validator"],
  model: {
    prop: "checkedValue",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: undefined,
    },
    checkedValue: {
      type: [String, Number, Array, Boolean, Object],
      default: undefined,
    },
    valueKey: {
      type: String,
      default: "id",
    },
    groupName: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "radio",
      validator(value) {
        return ["radio", "checkbox"].includes(value);
      },
    },
    allowUncheck: {
      type: Boolean,
      default: false,
    },
    deferredChange: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    isCheckbox() {
      return this.inputType === "checkbox";
    },

    isRadio() {
      return this.inputType === "radio";
    },

    isActive() {
      let isActive = false;

      if (this.isRadio) {
        if (
          typeof this.value === "object" &&
          typeof this.checkedValue === "object"
        ) {
          isActive =
            this.value[this.valueKey] === this.checkedValue?.[this.valueKey];
        } else {
          isActive = this.value === this.checkedValue;
        }
      }

      if (this.isCheckbox) {
        if (typeof this.checkedValue === "boolean") {
          isActive = this.checkedValue;
        }

        if (typeof this.checkedValue === "object") {
          isActive = this.checkedValue?.some(
            (el) => el[this.valueKey] === this.value[this.valueKey]
          );
        }
      }

      return isActive;
    },
  },
  mounted() {},
  methods: {
    clickHandler(event) {
      this.deferredChange
        ? this.handleDeferredChange(event)
        : this.uncheckRadio();
    },

    changeInput() {
      let value = this.checkedValue;
      this.$emit("changed", this.value);

      if (this.isRadio) {
        value = this.value;
      }

      if (this.isCheckbox) {
        if (typeof this.checkedValue === "boolean") {
          value = !this.checkedValue;
        }

        if (typeof this.checkedValue === "object") {
          if (this.isActive) {
            value = value.filter(
              (el) => el[this.valueKey] !== this.value[this.valueKey]
            );
          } else {
            value.push(this.value);
          }
        }
      }

      this.$emit("change", value);
    },

    uncheckRadio() {
      if (this.isRadio && this.isActive && this.allowUncheck) {
        this.$emit("change", "");
      }
    },

    handleDeferredChange(event) {
      event.preventDefault();

      this.deferredChange({
        value: this.value,
        prevValue: this.checkedValue,
        isActive: this.isActive,
      })
        .then(() => {
          // эмитим value только для чекбокса и вновь выбранного radiobutton
          // для уже активного radiobutton эмитится пустая строка, чтобы его отключить (метод uncheckRadio)
          if (this.isCheckbox || (this.isRadio && !this.isActive)) {
            this.changeInput();
          } else {
            this.uncheckRadio();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.c-radio {
  position: relative;
  display: flex;
  align-items: center;

  &__input {
    display: none;
  }

  &__label {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    cursor: pointer;
    width: 100%;
  }

  &__append {
    position: relative;
    //top: 50%;
    display: flex;
    line-height: 1;
    //transform: translateY(-50%);

    &--after {
      //right: em(16);
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
  }
}


input,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

</style>
