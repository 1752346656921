<template>
  <ConfirmPopupLayout class="confirm-popup" :custom-close="close">
    <div class="error-popup">
      <div class="error-popup__content">
        <div class="error-popup__icon-wrapper">
          <CIcon
              :name="icon"
              class="error-popup__icon"
              :class="{[`error-popup__icon--${icon}`]: icon}"
          />
        </div>

        <div class="error-popup__title">
          <span  class="error-popup__font error-popup__font--title">
            {{ data.title || $t('general.popups.error.errorDefaultText') }}
          </span>
        </div>

        <div class="error-popup__message">
          <div class="error-popup__text">
            <span
                class="error-popup__font error-popup__font--message"
                v-html="data.message || $t('general.popups.error.somethingHappened')"
            />
          </div>
        </div>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "ErrorPopup",
  components: { CIcon, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appear: false,
    };
  },
  computed: {
    icon() {
      return this.data?.icon || 'error';
    }
  },
  methods: {
    close() {
      if(this.data.callback) {
        this.data.callback()
        return;
      }

      this.$popup.close(2);
    },
  },
};
</script>

<style scoped lang="scss">
.error-popup {
  $parent: &;

  //width: em(420);
  width: 100%;

  &__content {
    padding: em(32) em(25) em(25);
  }

  &__title {
    margin-top: em(23);
  }

  &__message {
    margin-top: em(11);
  }

  &__font {
    font-family: $font-default;
    text-align: center;
    font-style: normal;

    &--title {
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }

    &--message {
      color: $app-font-color-gray-3;
      font-size: em(14);
      font-weight: 400;
    }
  }

  &__icon {
    &--error {
      width: em(50);
      height: em(47);
    }

    &--success {
      width: em(49);
      height: em(49);
    }
  }
}
</style>
