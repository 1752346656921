<template>
  <BasePopupLayout
    no-border
    :title="$t('general.popups.createBundle.title')"
    :custom-close="close"
  >
    <div class="create-promotion">
      <AppInfo
        class="mb-3"
        text=""
        type="error"
        v-if="errors.collect('_').length"
      >
        <ul class="pl-0">
          <li
            class="list-unstyled"
            v-for="errorItem in errorsList"
            :key="errorItem"
          >
            {{ errorItem }}
          </li>
        </ul>
      </AppInfo>

      <form>
        <b-row class="mb-3">
          <b-col>
            <div class="v-select-profile mt-1">
              <v-select
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected': form.month,
                }"
                name="months"
                v-model="form.month"
                label="label"
                :options="months"
                :reduce="(option) => option"
                placeholder="months"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.month,
                }"
              >
                {{ $t("general.popups.createBundle.monthsPlaceholder") }}
              </span>

              <small
                v-if="errors && errors.has('months')"
                class="create-promotion__font create-promotion__font--error"
              >
                {{ errors.first("months") }}
              </small>
            </div>
          </b-col>

          <b-col>
            <div class="v-select-profile mt-1">
              <v-select
                :key="form.discount"
                class="vs-theme-app"
                :class="{
                  'vs-theme-app--selected': form.discount,
                }"
                name="discount"
                v-model="form.discount"
                label="label"
                :options="discounts"
                :reduce="(option) => option"
                placeholder="discount"
              />

              <span
                class="v-select-placeholder"
                :class="{
                  'v-select-placeholder--active': form.discount,
                }"
              >
                {{ $t("general.popups.createBundle.discountPlaceholder") }}
              </span>

              <small
                v-if="errors && errors.has('discount')"
                class="create-promotion__font create-promotion__font--error"
              >
                {{ errors.first("discount") }}
              </small>
            </div>
          </b-col>
        </b-row>

        <div class="create-promotion__button-wrapper">
          <button
            type="submit"
            @click.prevent="create"
            class="base-button create-promotion__button"
          >
            <span class="base-button__font">
              {{ $t("general.popups.createBundle.button.add") }}
            </span>
          </button>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import vSelect from "vue-select";
import { mapActions } from "vuex";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import AppInfo from "@/features/ui/common/AppInfo.vue";
export default {
  name: "CreateBundlePopup",
  components: {
    AppInfo,
    vSelect,
    BasePopupLayout,
  },
  mixins: [validationError, waitRequest],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    const months = [
      {
        label: this.$t("general.settingsPages.bundles.months", { months: 3 }),
        value: 3,
      },
      {
        label: this.$t("general.settingsPages.bundles.months", { months: 6 }),
        value: 6,
      },
      {
        label: this.$t("general.settingsPages.bundles.months", { months: 12 }),
        value: 12,
      },
    ];

    const discounts = new Array(10).fill(null).map((item, index) => {
      const percent = (index + 1) * 10;
      return {
        label: `${percent}%`,
        value: percent,
      };
    });

    return {
      months,
      discounts,

      form: {
        months: null,
        discount: null,
      },
    };
  },
  computed: {
    errorsList() {
      if (!this.errors.collect("_").length) return [];

      return new Set(this.errors.collect("_"));
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      createBundle: "price/createBundle",
    }),

    async create() {
      if (this.requestInProgress) return;
      this.$validator.errors.clear()

      this.waitRequest(() => {
        return this.createBundle({
          months: this.form.month?.value,
          discount: this.form.discount?.value,
        })
          .then(() => {
            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    close() {
      if (this.data.closeCallback) {
        this.data.closeCallback();
      } else {
        this.$popup.close(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-promotion {
  //margin: em(0) em(-13) em(-17) em(-14);
  //height: 500px;
  overflow-y: auto;
  overflow-x: clip;
  @include hideScroll();

  &__tabs {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 18px;
  }

  &__title {
    margin-top: 19px;
    margin-bottom: 19px;
  }

  &__info {
    margin: em(0) em(13) em(17) em(14);
  }

  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__image {
    margin-top: em(29);
  }

  &__input {
    &--bottom {
    }
  }

  &__info {
    margin: 17px 0 0;
  }

  &__description {
    margin-top: em(8);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__field {
    &--name {
    }

    &--bio-link {
    }
    &--radio {
      margin-top: em(17);
    }
  }

  &__icon {
    color: $app-gray-18;

    &--camera {
      width: em(26);
      min-width: em(26);
      height: em(23);

      &--2 {
        width: em(20);
        min-width: em(20);
        height: em(18);
      }
    }

    &--cross {
      width: em(35);
      min-width: em(35);
      height: em(35);

      &--2 {
        width: em(25);
        min-width: em(25);
        height: em(25);
      }
    }

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }

  &__counter {
  }

  &__font {
    &--addition {
    }
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $black;
    }
    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: $app-font-color-placeholder;
      text-align: center;
    }

    &--confirm {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      color: $app-font-color-placeholder;
    }

    &--error {
      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
      color: $app-red;
      margin-top: 11px;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__button {
    margin-left: auto;
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }

  &--disabled {
    &:before {
      content: "";
      display: inline-block;
      width: em(16);
      min-width: em(16);
      height: em(16);
      min-height: em(16);
      background-color: transparent;
      border: 1px solid $app-gray-18;
      border-radius: 50%;
      margin-right: em(17);
      transition: background-color $time-normal $ease, border $time-normal $ease;
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.promotion-tab {
  text-align: center;

  &__item {
    padding: 8px 8px 10px;
    border-radius: 5px;
    background-color: $app-gray-7;

    &--active {
      background-color: $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--active {
      color: $white;
    }
  }
}
</style>
