import Vue from "vue";
import VueRouter from "vue-router";

import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";
import axios from "axios";

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_API_URL, // Your API domain
  providers: {
    twitter: {
      clientId: process.env.VUE_APP_TWITTER_CLIENT_ID,
      redirectUri: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/callback`, // Ваш URL-адрес перенаправления
      codeChallengeMethod: "S256",
    },
  },
});

import store from "./store";

import App from "./App.vue";

import { BootstrapVue } from "bootstrap-vue";

import VueClipboard from "vue-clipboard2";
import VueMask from "v-mask";

import Misc from "./plugins/Misc";
import eBus from "./plugins/event-bus";
import bodyScrollLock from "./plugins/body-lock";
import "@/plugins/vue-select";

import "@/assets/scss/app.scss";

import "@mux/mux-player";

import VueI18n from "vue-i18n";
import i18nConfig from "@/config/i18n";

Vue.use(VueI18n);
const i18n = new VueI18n(i18nConfig);

import DecimalPlugin from '@/plugins/decimal'
Vue.use(DecimalPlugin)

import router from "@/router/routerConfig";

import "./registerServiceWorker";

import "@/plugins/vee-validate";
import "@/plugins/vue-tippy";
import "@/plugins/touch";
import '@/plugins/axios'

Vue.config.productionTip = false;
Vue.use(eBus);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(Misc);
Vue.use(bodyScrollLock);
Vue.use(VueMask);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
