<template>
  <b-row>
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.settings") }}
          </h5>
          <b-nav pills>
            <b-button @click.prevent="saveSettings">{{
                $t("general.save")
              }}</b-button>
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-form @submit="saveSettings" class="w-100">
          <b-row>
            <b-col md="12">
              <b-card :header="$t('admin.trending-topics')" class="m-3 d-block">
                <b-form-select v-model="hashtagSelectedValues" :options="hashtagsSelectOptions" multiple></b-form-select>
                <p class="mt-1 text-secondary">Selected: {{ hashtagSelectedNames.join(', ') }}</p>
              </b-card>
            </b-col>
          </b-row>

        </b-form>
      </b-row>
    </b-col>
  </b-row>
</template>
<style scoped>
</style>
<script>
import TrendingTopic from "../../models/TrendingTopic";
export default {
  data: function () {
    return {
      settings: [],
      hashtags: [],
      hashtagSelectedValues: [],
      hashtagSelectedNames: [],
    };
  },
  computed: {
    hashtagsSelectOptions() {
      return this.hashtags.map(hashtag => ({
        value: hashtag.id,
        text: hashtag.name
      }));
    }
  },
  watch: {
    hashtagSelectedValues: {
      handler(newVal) {
        if (this.hashtags.length > 1) {
          const filteredSettings = this.hashtags.filter(hashtag => newVal.includes(hashtag.id));
          this.hashtagSelectedNames = filteredSettings.map(hashtag => hashtag.name);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.loadSettings();
    this.loadHashtags();
  },
  methods: {
    loadSettings() {
      let url = "/settings";
      this.$aGet(
        url,
        (data) => {
          for (let topic of data.trendingTopics) {
            let trendingTopic = new TrendingTopic(topic);
            this.settings.push(trendingTopic);
          }

          this.hashtagSelectedValues = this.settings.map(setting => setting.hashtag_id);
          this.hashtagSelectedNames = this.settings.map(setting => setting.hashtag.name);
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadHashtags() {
      let url = "/hashtags";
      this.$get(
        url,
        (data) => {
          this.hashtags = data.hashtags
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    saveSettings() {
      this.$aPost(
        "/settings/",
        {
          trendingTopics: this.hashtagSelectedValues
        },
        () => {
          this.$bvToast.toast(this.$t("general.settings-saved"), {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            toaster: "b-toaster-bottom-left",
          });
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>

<style>
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: 150px;
}
</style>
