<template>
  <ConfirmPopupLayout
    class="confirm-flow-nsfw-popup"
    :title="$t('general.popups.confirmFlowNSFW.title')"
    :button-text="$t('general.popups.confirmFlowNSFW.buttonClose')"
  >
    <div class="confirm-flow-nsfw-popup__content">
      <div>
        <span
          class="confirm-flow-nsfw-popup__font confirm-flow-nsfw-popup--text"
        >
          {{ $t("general.popups.confirmFlowNSFW.text") }}
        </span>
      </div>

      <div class="confirm-flow-nsfw-popup__toggle">
        <span>{{ $t("general.popups.confirmFlowNSFW.clean") }}</span>

        <CButtonToggle
          class="confirm-flow-nsfw-popup__button"
          :is-active="isNSFWContent"
          @click="toggle"
        />

        <span>{{ $t("general.popups.confirmFlowNSFW.nsfw") }}</span>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CButtonToggle from "@/features/ui/CButtonToggle.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters } from "vuex";

export default {
  name: "ConfirmFlowNSFWPopup",
  mixins: [waitRequest, validationError],
  components: { CButtonToggle, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      isNSFWContent: "flows/isNSFWContent",
      user: 'user',
    }),
  },

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggle(value) {
      if (this.requestInProgress) return;

      const nsfwState = value;

      this.$saveUser({...this.user, nsfw_enabled: nsfwState });

      this.waitRequest(() => {
        return this.$post(
          // some url,
          "/profile/nsfw",
          { nsfw_enabled: nsfwState },
          this.successCallback,
          () => this.failureCallback(!nsfwState),
          this.checkErrors
        );
      });
    },

    successCallback(data) {
      this.$saveUser(data);
    },

    failureCallback(nsfwState) {
      this.$saveUser({...this.user, nsfw_enabled: nsfwState });
    },
  },
};
</script>

<style scoped lang="scss">
.confirm-flow-nsfw-popup {
  $parent: &;

  &__content {
    width: em(268);
    margin: 0 auto;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: em(29);
    margin-bottom: em(31);
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}
</style>
