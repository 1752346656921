import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      isAnonymousUser: 'isAnonymousUser'
    }),
  },
  methods: {
    anonymousHandler() {
      this.$popup.open('LoginPopup', null, 2)
    }
  }
}
