<template>
  <ConfirmPopupLayout
      class="confirm-popup"
      :title="data.title"
      :button-text="data.button || $t('general.auth.confirm.popup.button')"
      :custom-close="data.customClose"
  >
    <div class="confirm-popup__content" v-if="data.text">
      <span class="confirm-popup__font">{{ data.text }}</span>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";

export default {
  name: "ConfirmPopup",
  components: { ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  },
};
</script>

<style scoped lang="scss">
.confirm-popup {
  $parent: &;

  &__content {
    width: em(268);
    margin: 0 auto;
    padding: 0 em(10) em(16) em(10);
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}
</style>
