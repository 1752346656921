export const postType = {
  posts: "posts",
  flows: "flows",
};

export const followType = {
  following: "following",
  recommend: "recommend",
};

export const postStatuses = {
  active: 0,
  scheduled: 1,
  expired: 2,
};
