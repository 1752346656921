import { statementsTypes } from "@/config/statementsTypes";
import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";

const state = () => ({
  statements: null,
  cards: {
    [statementsTypes.earning]: {
      current_page: 1,
      data: [],
    },
  },
  referrals: {
    info: {
      all_time: "0.00",
      link: ""
    },
    data: {
      current_page: 1,
      data: [],
    },
  },
  statistics: {
    data: {},
    all_time: {
      stat_amount: "0.00",
      stat_net: "0.00"
    }
  }
});

const getters = {
  cards: (state) => state.cards,
  statements: (state) => state.statements,
  statistics: (state) => state.statistics,
  referrals: (state) => state.referrals,
};

const actions = {
  fetchStatements({ commit }) {
    return axios({ method: "GET", url: "/statements" }).then((response) => {
      commit("setStatements", {
        statements: response.data,
      });

      return response;
    });
  },
  fetchEarnings({ commit }, { page = 1 } = {}) {
    const requestQuery = {
      page,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/statements/earnings", params }).then(
      (response) => {
        commit("setCards", {
          cards: response.data,
          type: statementsTypes.earning,
        });

        return response;
      }
    );
  },

  fetchReferrals({ commit }, { page = 1 } = {}) {
    const requestQuery = {
      page,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/statements/referrals-statistics", params }).then(
      (response) => {
        commit("setReferrals", {
          referrals: response.data,
        });

        return response;
      }
    );
  },

  fetchStatistics({ commit }, { page = 1 } = {}) {
    const requestQuery = {
      page,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/statements/earnings-statistics?start_date=2024-04-01&end_date=2025-05-31", params }).then(
      (response) => {
        commit("setStatistics", {
          statistics: response.data,
          type: statementsTypes.statistics,
        });

        return response;
      }
    );
  },
};

const mutations = {
  setCards(state, { cards, type = statementsTypes.earning } = {}) {
    if (cards?.current_page === 1) {
      state.cards[type] = cards;
      return;
    }

    state.cards[type] = {
      ...cards[type],
      current_page: cards.current_page || 1,
      next_page_url: cards.next_page_url || '',
      data: [...(state.cards[type].data || []), ...(cards.data || [])],
    };
  },

  updateCards(state, { cards, type } = {}) {
    state.cards[type] = {
      ...cards,
      data: [...(state.cards[type]?.data || []), ...(cards.data || [])],
    };
  },

  setStatements(state, { statements } = {}) {
    state.statements = statements;
  },

  setStatistics(state, { statistics } = {}) {
    state.statistics = statistics;
  },

  setReferrals(state, { referrals } = {}) {
    state.referrals.info = referrals.info;

    if (referrals.data?.current_page === 1) {
      state.referrals.data = referrals.data;
      return;
    }

    state.referrals.data = {
      ...referrals.data,
      current_page: referrals.data.current_page || 1,
      next_page_url: referrals.data.next_page_url || '',
      data: [...(state.referrals.data.data || []), ...(referrals.data.data || [])],
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
