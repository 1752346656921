import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import GeolocationService from "@/services/geolocationService";

export default {
    mixins: [waitRequest, validationError],
    methods: {
        sendUserLocation() {
            GeolocationService.getUserLocation().then((location) => {
                this.sendLocation(location);
            });
        },
        sendLocation(location) {
            if (this.requestInProgress) return;

            const fields = {
                current_location: {
                    lat: location.latitude,
                    lon: location.longitude,
                },
            }

            return this.waitRequest(() => {
                return this.$post(
                    "/profile/update-current-location",
                    fields,
                    () => {},
                    (errors) => {
                        console.log(errors);
                    },
                    this.checkErrors
                );
            });
        },
    }
}
