const state = () => ({
  settings: {},
  selectedCategories: [],
  lockOption: 0,
  location: null,
  expirations: 0,
  lockPrice: null,
});

const actions = {};

const getters = {
  settings: (state) => state.settings,
  selectedCategories: (state) => state.selectedCategories,
  lockOption: (state) => state.lockOption,
  location: (state) => state.location,
  expirations: (state) => state.expirations,
  lockPrice: (state) => state.lockPrice,
};

const mutations = {
  setSettings(state, { settings } = {}) {
    state.settings = settings;
  },

  setCategories(state, { categories } = {}) {
    state.selectedCategories = categories;
  },

  setLockOption(state, { lockOption } = {}) {
    state.lockOption = lockOption;
  },

  setLocation(state, { location } = {}) {
    state.location = location;
  },

  setExpirations(state, { expirations } = {}) {
    state.expirations = expirations;
  },
  setLockPrice(state, { lockPrice } = {}) {
    state.lockPrice = lockPrice;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
