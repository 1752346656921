import axios from "@/plugins/axios";

const state = () => ({
  // price: null,
});

const actions = {
  createPrice({ commit }, { price }) {
    return axios({
      method: "POST",
      url: `/price`,
      data: { price },
    }).then((response) => {
      commit("setUser", response.data, { root: true });

      return response;
    });
  },

  createBundle({ commit }, bundle) {
    console.log("bundle", bundle);
    return axios({
      method: "POST",
      url: `/price/bundle`,
      data: bundle,
    }).then((response) => {
      commit("setUser", response.data, { root: true });

      return response;
    });
  },

  deleteBundle({ commit }, { bundleId }) {
    return axios({
      method: "DELETE",
      url: `/price/bundle/${bundleId}`,
    }).then((response) => {
      commit("setUser", response.data, { root: true });

      return response;
    });
  },
};

const getters = {
  price: (state, getters) => getters["currentUser"].price,
  bundle: (state, getters) => getters["currentUser"].bundle,
};

const mutations = {
  setPrice(state, price) {
    state.price = price;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
