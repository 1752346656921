<template>
  <ConfirmPopupLayout
    class="flow-preferences-popup"
    :title="$t('general.popups.flowCategories.title')"
    :button-text="$t('general.popups.flowCategories.buttonClose')"
    :custom-close="customClose"
  >
    <div class="flow-preferences-popup__content">
      <div>
        <span class="flow-preferences-popup__font flow-preferences-popup--text">
          {{ $t("general.popups.flowCategories.text") }}
        </span>
      </div>

      <transition name="fade">
        <div class="flow-preferences-popup__settings" v-if="settings.length">
          <ValidationProvider
            rules="required|length:2"
            name="categories"
            ref="categories"
            v-slot="{ errors }"
            mode="passive"
          >
            <CRadio
              v-model="selectedPreferences"
              v-for="(item, index) in settings"
              :key="`${index + '-group'}`"
              :name="item.name"
              :value="item"
              group-name="categories"
              input-type="checkbox"
              class="flow-preferences-popup__button"
              :rules="'required'"
            >
              <template #default="{ isActive, value }">
                <div
                  class="button flow-preferences-button"
                  :class="{ 'flow-preferences-button--active': isActive }"
                >
                  <img
                    v-if="value.image"
                    :src="value.image"
                    alt=""
                    class="flow-preferences-button__image"
                  />
                  &nbsp;
                  <span class="flow-preferences-button__font">
                    {{ value.name }}
                  </span>
                </div>
              </template>
            </CRadio>

            <span v-if="errors[0]" class="flow-preferences-popup__error">
              <span
                class="flow-preferences-popup__font flow-preferences-popup__font--error"
              >
                {{ errors[0] }}
              </span>
            </span>
          </ValidationProvider>
        </div>
      </transition>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CRadio from "@/features/ui/CRadio.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";
import { ValidationProvider } from "vee-validate";
export default {
  name: "FlowPreferencesPopup",
  mixins: [waitRequest, validationError],
  components: { CRadio, ConfirmPopupLayout, ValidationProvider },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      flowSettings: "postCreate/settings",
      selectedCategories: "postCreate/selectedCategories",
    }),
  },

  data() {
    return {
      selectedPreferences: [],
      settings: [],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setCategories: "postCreate/setCategories",
    }),

    async customClose() {
      const provider = this.$refs.categories;
      const validator = await provider.validate();

      if (!validator.valid) return;

      if (this.settings.length) {
        const categories = this.selectedPreferences.map((item) => item.id);
        this.setCategories({ categories });
      }

      this.$popup.close();
    },

    initState() {
      this.settings = this.flowSettings?.categories || [];
      this.selectedPreferences = this.settings.filter((item) =>
        this.selectedCategories.includes(item.id)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.flow-preferences-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
  }

  &__settings {
    margin-top: em(30);
    margin-bottom: em(12);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }

  &__button {
    margin-bottom: em(16);
  }

  &__error {
    //position: absolute;
    //bottom: em(-20);
    bottom: auto;
    left: 0;
    display: block;
    width: 100%;
    color: $red;
    font-weight: $font-medium;
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    &--error {
      @include text-overflow;

      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
      color: $app-red;
    }
  }
}

.flow-preferences-button {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__image {
    max-width: em(14);
    max-height: em(14);
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
