import Vue from 'vue';
import Emitter from 'tiny-emitter';

const emitter = new Emitter();

const $eventBus = {
  $emit: emitter.emit,
  $on: emitter.on,
  $off: emitter.off,
  $once: emitter.once,
};

const eBus = {
  install() {
    Vue.prototype.$popup = {
      open(name, data = {}, level = 1) {
        $eventBus.$emit(`popup:open${level}`, {
          name,
          data,
        });
      },

      addOpen({ level = 1, callback }) {
        $eventBus.$on(`popup:open${level}`, callback);
      },

      removeOpen({ level = 1, callback }) {
        $eventBus.$off(`popup:open${level}`, callback);
      },

      close(level = 1, callback = null) {
        $eventBus.$emit(`popup:close${level}`, callback);
      },

      addClose({ level = 1, callback }) {
        $eventBus.$on(`popup:close${level}`, callback);
      },

      removeClose({ level = 1, callback }) {
        $eventBus.$off(`popup:close${level}`, callback);
      },
    };
  },
};

export default eBus;
