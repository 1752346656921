<template>
  <BasePopupLayout :title="$t('general.popups.language.title')">
    <CInput
      type="text"
      name="text"
      input-mode="text"
      theme="secondary"
      v-model.trim="language"
      :placeholder="$t('general.popups.language.input-placeholder')"
    />

    <ul class="language-list">
      <li
        v-for="language in selectedLanguages"
        :key="language.id"
        class="language-list__item"
      >
        <button
          type="button"
          @click.prevent="language.action"
          class="language-list__button"
        >
          <span class="language-list__font language-list__font--text">
            {{ language.text }}
          </span>
        </button>
      </li>
    </ul>
  </BasePopupLayout>
</template>

<script>
import CInput from "@/features/ui/СInput.vue";
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";

export default {
  name: "LanguagePopup",
  components: { BasePopupLayout, CInput },

  computed: {
    selectedLanguages() {
      return this.languagesList.filter((language) => {
        const text = language.text.toLowerCase();
        const selectedText = this.language.toLowerCase();

        return text.includes(selectedText);
      });
    },
  },

  data() {
    return {
      language: "",
      languagesList: [
        {
          id: 1,
          text: this.$t("general.popups.language.languages.english"),
          action: this.close,
        },
        {
          id: 2,
          text: this.$t("general.popups.language.languages.spanish"),
          action: this.close,
        },
        {
          id: 3,
          text: this.$t("general.popups.language.languages.french"),
          action: this.close,
        },
        {
          id: 3,
          text: this.$t("general.popups.language.languages.german"),
          action: this.close,
        },
        // {
        //   id: 4,
        //   text: this.$t("general.popups.language.languages.chinese"),
        //   action: this.close,
        // },
        // {
        //   id: 5,
        //   text: this.$t("general.popups.language.languages.hindi"),
        //   action: this.close,
        // },
        // {
        //   id: 6,
        //   text: this.$t("general.popups.language.languages.bengali"),
        //   action: this.close,
        // },
      ],
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.language-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: em(500);
  overflow: scroll;
  @include hideScroll();

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;
  }

  &__item {
    display: flex;
    position: relative;
    padding: 0;
    margin-left: em(0);
    margin-bottom: em(21);
  }

  &__font {
    &--text {
      color: $app-black;
      font-family: $font-default;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
