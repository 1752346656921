<template>
  <BasePopupLayout
    no-border
    :title="$t('general.popups.vault.title')"
    :custom-close="close"
  >
    <div class="vault-popup">
      <div class="my-3 linked-post">
        <InfinityScroll
          :key="Math.random()"
          v-if="vaultList && vaultList.length"
          :items="vaultList"
          :has-more="Boolean(vault.next_page_url)"
          @rich-end="loadMore"
          is-grid
          gap
        >
          <template #default="{ item }">
            <PostTile
              :post="item"
              inactive
              is-vault
              selectable
              :selected-item="item.isActive"
              @select="onSelect"
              @unselect="onUnselect"
            />
          </template>
        </InfinityScroll>
      </div>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import { mapActions, mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  name: "VaultPopup",
  components: {
    InfinityScroll,
    PostTile,
    BasePopupLayout,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedPost: [],
    };
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      vault: "media/vault",
    }),
    vaultList() {
      return (
        this.vault?.data.map((item) => {
          return {
            ...item,
            isActive: Boolean(
              this.data.media.find((post) => item.id === post.id)
            ),
          };
        }) || []
      );
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchVault: "media/fetchVault",
    }),

    initState() {
      this.fetchVault({ flow_compatible: this.data.flowRequestType }).catch(
        this.checkErrors
      );
    },

    createQueryConfig(config) {
      return {
        page: this.vault.current_page || 1,
        limit: this.vault.per_page || 30,
        flow_compatible: this.data.flowRequestType,
        ...config,
      };
    },

    loadMore() {
      const queryConfig = this.createQueryConfig({
        page: this.vault.current_page + 1,
      });
      return this.waitRequest(() => {
        return this.fetchVault(queryConfig).catch(this.checkErrors);
      });
    },
    onSelect(post) {

      let modifiedMediaObject;

      if (!post?.screenshot?.url) {
        // TODO: fix backend response
        modifiedMediaObject = {
          ...post,
          screenshot: { ...post?.thumbs?.[0]?.url },
        };
      } else {
        modifiedMediaObject = post;
      }

      this.selectedPost.push({ ...modifiedMediaObject, isVault: true });
      this.data.addMediaCallback({ ...modifiedMediaObject, isVault: true });
    },
    onUnselect(post) {
      this.selectedPost = this.selectedPost.filter(
        (item) => item.id !== post.id
      );
      this.data.removeMediaByIdCallback(post.id);
    },

    close() {
      if (this.data.closeCallback) {
        this.data.closeCallback();
      } else {
        this.$popup.close(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vault-popup {
  //max-height: 90vh;
}
</style>
