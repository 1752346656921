var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasePopupLayout',{attrs:{"no-border":"","title":_vm.config.title,"custom-close":_vm.close}},[_c('div',{staticClass:"create-promotion"},[_c('div',{staticClass:"create-promotion__tabs"},_vm._l((_vm.tabs),function(item,index){return _c('CRadio',{key:`${index + '-tab'}`,staticClass:"promotion-tab",attrs:{"name":item.name,"value":item,"group-name":"promotion-tab"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive, value: tab }){return [_c('div',{staticClass:"promotion-tab__item",class:{ 'promotion-tab__item--active': isActive }},[_c('span',{staticClass:"promotion-tab__font",class:{ 'promotion-tab__font--active': isActive }},[_vm._v("\n              "+_vm._s(tab.content)+"\n            ")])])]}}],null,true),model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}})}),1),_vm._v(" "),(_vm.errors.collect('_').length)?_c('AppInfo',{staticClass:"mb-3",attrs:{"text":"","type":"error"}},[_c('ul',{staticClass:"pl-0"},_vm._l((_vm.errorsList),function(errorItem){return _c('li',{key:errorItem,staticClass:"list-unstyled"},[_vm._v("\n          "+_vm._s(errorItem)+"\n        ")])}),0)]):_vm._e(),_vm._v(" "),_c('form',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('div',{staticClass:"v-select-profile"},[_c('v-select',{staticClass:"vs-theme-app",class:{
                'vs-theme-app--selected': _vm.form.limit,
              },attrs:{"name":"billing_info.country","label":"label","options":_vm.limits,"reduce":(option) => option,"placeholder":"countries"},model:{value:(_vm.form.limit),callback:function ($$v) {_vm.$set(_vm.form, "limit", $$v)},expression:"form.limit"}}),_vm._v(" "),_c('span',{staticClass:"v-select-placeholder",class:{
                'v-select-placeholder--active': _vm.form.limit,
              }},[_vm._v("\n              "+_vm._s(_vm.$t("general.popups.createPromotion.input.limit"))+"\n            ")]),_vm._v(" "),(_vm.errors && _vm.errors.has('limit'))?_c('small',{staticClass:"create-promotion__font create-promotion__font--error"},[_vm._v("\n              "+_vm._s(_vm.errors.first("limit"))+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('b-col',[_c('div',{staticClass:"v-select-profile"},[_c('v-select',{key:_vm.form.expiration,staticClass:"vs-theme-app",class:{
                'vs-theme-app--selected': _vm.form.expiration,
              },attrs:{"name":"billing_info.region","label":"label","options":_vm.expiration,"reduce":(option) => option,"placeholder":"region"},model:{value:(_vm.form.expiration),callback:function ($$v) {_vm.$set(_vm.form, "expiration", $$v)},expression:"form.expiration"}}),_vm._v(" "),_c('span',{staticClass:"v-select-placeholder",class:{
                'v-select-placeholder--active': _vm.form.expiration,
              }},[_vm._v("\n              "+_vm._s(_vm.$t("general.popups.createPromotion.input.expiration"))+"\n            ")]),_vm._v(" "),(_vm.errors && _vm.errors.has('expiration'))?_c('small',{staticClass:"create-promotion__font create-promotion__font--error"},[_vm._v("\n              "+_vm._s(_vm.errors.first("expiration"))+"\n            ")]):_vm._e()],1)])],1),_vm._v(" "),(_vm.selectedTab.type === 2)?_c('b-row',[_c('b-col',[_c('div',{staticClass:"create-promotion__input"},[_c('CInput',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],staticClass:"create-promotion__field create-promotion__field--name",attrs:{"type":"text","name":"discount_price","custom-errors":_vm.errors,"custom-field-name":_vm.$t('general.popups.createPromotion.input.firstMonth'),"theme":"settings","label":_vm.$t('general.popups.createPromotion.input.firstMonth'),"rules":'required|max:40'},model:{value:(_vm.form.discount_price),callback:function ($$v) {_vm.$set(_vm.form, "discount_price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.discount_price"}})],1)])],1):_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('div',{staticClass:"v-select-profile"},[_c('v-select',{key:_vm.form.trial_duration_days,staticClass:"vs-theme-app",class:{
                'vs-theme-app--selected': _vm.form.trial_duration_days,
              },attrs:{"name":"billing_info.duration","label":"label","options":_vm.duration,"reduce":(option) => option,"placeholder":"region"},model:{value:(_vm.form.trial_duration_days),callback:function ($$v) {_vm.$set(_vm.form, "trial_duration_days", $$v)},expression:"form.trial_duration_days"}}),_vm._v(" "),_c('span',{staticClass:"v-select-placeholder",class:{
                'v-select-placeholder--active': _vm.form.trial_duration_days,
              }},[_vm._v("\n              "+_vm._s(_vm.$t("general.popups.createPromotion.input.duration"))+"\n            ")]),_vm._v(" "),(_vm.errors && _vm.errors.has('trial_duration_days'))?_c('small',{staticClass:"create-promotion__font create-promotion__font--error"},[_vm._v("\n              "+_vm._s(_vm.errors.first("trial_duration_days"))+"\n            ")]):_vm._e()],1)])],1),_vm._v(" "),_c('b-row',[_c('b-col',[_c('div',{staticClass:"create-promotion__input"},[_c('CInput',{staticClass:"create-promotion__field create-promotion__field--name",attrs:{"disabled":_vm.isUpdateCard,"type":"text","name":"message","custom-field-name":_vm.$t('general.popups.createPromotion.input.message'),"theme":"settings","custom-errors":_vm.errors,"label":_vm.$t('general.popups.createPromotion.input.message')},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.message"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"create-promotion__button-wrapper"},[_c('button',{staticClass:"base-button create-promotion__button",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c('span',{staticClass:"base-button__font"},[_vm._v("\n            "+_vm._s(_vm.$t("general.myProfile.save"))+"\n          ")])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }