<template>
  <ConfirmPopupLayout
    class="preview-post-popup"
    :title="$t('general.popups.previewPost.title')"
    :custom-close="customClose"
  >
    <div class="preview-post-popup__content">
      <div>
        <span
          v-html="$t('general.popups.previewPost.text')"
          class="preview-post-popup__font preview-post-popup--text"
        />
      </div>

      <div class="preview-post-popup__image-wrapper">
        <img src="/img/preview.webp" alt="" class="preview-post-popup__image">
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";

export default {
  name: "PreviewPostPopup",
  components: { ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    customClose() {
      return () => this.$popup.close(2);
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.preview-post-popup {
  $parent: &;

  &__content {
    padding: 0 em(21);
  }

  &__settings {
    margin-top: em(30);
    margin-bottom: em(12);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }

  &__button {
    margin-bottom: em(16);
  }

  &__image-wrapper {
    padding: em(22) 0 em(24);
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}
</style>
