<template>
  <div class="unsubscribe-popup" @click.stop>
    <div class="unsubscribe-popup__wrapper">
      <div class="unsubscribe-popup__content">
        <div class="unsubscribe-popup__title">
          <span class="unsubscribe-popup__font unsubscribe-popup__font--title">
           {{ $t('general.popups.cancel-subscription.title') }}
          </span>
        </div>
        <div class="unsubscribe-popup__text">
          <span class="unsubscribe-popup__font unsubscribe-popup__font--text">
            {{ $t('general.popups.cancel-subscription.text') }}
          </span>
        </div>
      </div>

      <div class="unsubscribe-popup__title">
        <span class="unsubscribe-popup__font unsubscribe-popup__font--title">
          {{ $t('general.popups.cancel-subscription.reason-cancel') }}
        </span>
      </div>

      <div class="v-select-profile mt-3">
        <v-select
          class="vs-theme-app"
          :class="{
            'vs-theme-app--selected': true,
          }"
          name="billing_info.region"
          v-model="form.reason"
          label="name"
          :options="reasonList"
          :reduce="(option) => option"
          :placeholder="$t('general.popups.cancel-subscription.placeholder')"
        />

        <span
          class="v-select-placeholder"
          :class="{
            'v-select-placeholder--active': form.reason,
          }"
        >
          {{ $t('general.popups.cancel-subscription.placeholder') }}
        </span>

        <small
          v-if="errors && errors.has('billing_info.region')"
          class="new-card__font new-card__font--error"
        >
          {{ errors.first("billing_info.region") }}
        </small>
      </div>

      <div class="unsubscribe-popup__footer">
        <CButton @click="unsubscribe" class="mt-3" align="center">
          <span class="card-button__font card-button__font--white">
            {{ $t('general.popups.cancel-subscription.button') }}</span
          >
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";
import vSelect from "vue-select";
import {mapActions, mapGetters, mapMutations} from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import {subscriptionTypes} from "@/config/subscriptionTypes";

export default {
  name: "UnsubscribePopup",
  components: { vSelect, CButton },
  mixins: [waitRequest, validationError],
  props: {
   data: () => ({}),
  },

  computed: {
    ...mapGetters({ subscriptionCancelReason: 'settings/subscription_cancel_reason' }),
    reasonList() {
      return Object.entries(this.subscriptionCancelReason).map(([key, name]) => {
        return { id: key, name }
      })
    },
  },

  data() {
    return {
      form: {
        reason: null,
      }
    }
  },

  methods: {
    ...mapMutations({ updateCards: 'subscriptions/updateCards', deleteCard: 'subscriptions/deleteCard' }),
    ...mapActions({ deleteSubscription: 'subscriptions/deleteSubscription' }),
    close() {
      if (this.customClose) return this.customClose();

      this.$popup.close();
    },
    unsubscribe() {
      if(this.requestInProgress) return
      this.waitRequest(() => {
        return this.deleteSubscription({ reason: this.form.reason?.id, userId: this.data.userId })
          .then((response) => {
            this.updateCards({
              cards: { data: [response.data?.subscription]},
              type: subscriptionTypes.cancelled,
            });

            if(this.data?.card) {
              this.deleteCard( {
                card: this.data?.card,
                type: subscriptionTypes.active,
              });
            }

            if(this.data.callback) {
              this.data.callback()
            }
            this.close()
          })
          .catch(this.checkErrors)
      })
    }
  },
};
</script>

<style scoped lang="scss">
.unsubscribe-popup {
  $parent: &;

  width: em(330);

  &__wrapper {
    position: relative;
    z-index: 2;
    background: $white;
    border-radius: em(10);
    padding: 18px 17px 22px;
  }

  &__title {
    //padding: em(19) em(10) em(18);
  }

  &__text {
    margin: 9px 0px 22px 0px;
  }

  &__content {
  }

  &__footer {
    width: 100%;
  }

  &__button {
    text-align: center;
    padding: em(16) em(10) em(17);
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-style: normal;
    font-weight: 500;
    line-height: 1;

    &--title {
      font-size: 16px;
      line-height: 112%;
    }

    &--text {
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
    }

    &--button {
      font-size: 16px;
      line-height: 112%;
    }
  }
}
</style>
