var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"c-input c-input--textarea",class:{
    'c-input--disabled': _vm.disabled,
    'c-input--active': _vm.focused || _vm.value,
    'c-input--error': _vm.errors.has(_vm.name),
    'c-input--accent': _vm.isAccent,
    [`c-input--textarea--theme--${_vm.theme}`]: _vm.theme,
  }},[(_vm.label)?_c('span',{staticClass:"c-input__label",class:{ 'c-input__label--active': _vm.focused || _vm.value }},[_c('span',{staticClass:"c-input__label-text"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_vm._v(" "),_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules),expression:"rules"}],ref:"textarea",staticClass:"c-input__field",class:{
      [`c-input__field--theme--${_vm.theme}`]: _vm.theme,
    },style:(` height: ${_vm.textareaHeight}px;`),attrs:{"type":_vm.typeLocal,"name":_vm.name,"autocomplete":"off","required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.setFocus(true)},"blur":function($event){return _vm.setFocus(false)},"input":_vm.emitInput}}),_vm._v(" "),_c('span',{staticClass:"c-input__append c-input__append--after"},[_vm._t("appendAfter")],2),_vm._v(" "),(_vm.errors.has(_vm.name))?_c('span',{staticClass:"c-input__error"},[_c('span',{staticClass:"c-input__font c-input__font--error"},[_vm._v("\n      "+_vm._s(_vm.errors.first(_vm.name))+"\n    ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }