<template>
  <button
    :class="{
      'c-button-toggle--active': isActiveProp,
    }"
    class="c-button-toggle"
    @click="clickHandler"
  >
    <span class="c-button-toggle__arm" />
  </button>
</template>

<script>
export default {
  name: "CButtonToggle",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActiveProp() {
      return this.isActive;
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click", !this.isActiveProp);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-button-toggle {
  $parent: &;

  $arm-size: em(20);
  $arm-side-gap: em(4);

  position: relative;

  display: inline-flex;
  align-items: center;

  width: em(55);
  height: calc(#{$arm-size} + #{$arm-side-gap} + #{$arm-side-gap});

  border-radius: em(80);
  background-color: $app-gray-3;

  &__arm {
    position: absolute;
    left: $arm-side-gap;
    display: inline-block;
    width: $arm-size;
    height: $arm-size;
    background: $white;
    border-radius: 50%;
    transition: left $time-normal $bounce, right $time-normal $bounce;
  }

  &--active {
    background-color: $black;

    #{$parent} {
      &__arm {
        left: calc(100% - #{$arm-size} - #{$arm-side-gap});
      }
    }
  }
}
</style>
