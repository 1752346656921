<template>
  <BasePopupLayout :title="$t('general.popups.paymentMethod.title')">
    <div class="choose-payment-method">
      <form
        class="choose-payment-method__form"
        novalidate
        @submit.prevent="setActivePaymentMethod"
      >
        <div class="choose-payment-method__settings">
          <CRadio
            v-model="selected"
            v-for="(item, index) in cardsList"
            :key="`${index + '-group'}`"
            :name="item.name"
            :value="item"
            allow-uncheck
            group-name="settings"
            class="choose-payment-method__radio-row"
          >
            <template #default="{ isActive, value }">
              <div
                v-if="value.wallet"
                class="button card-settings"
                :class="{ 'card-settings--active': isActive }"
              >
                <div class="card-settings__wallet">
                  <span
                    class="choose-payment-method__font choose-payment-method__font--base"
                  >
                    {{ value.wallet }}
                  </span>
                  <span
                    class="choose-payment-method__font choose-payment-method__font--addition"
                  >
                    {{ value.balance }}
                  </span>
                </div>
              </div>

              <div
                v-else
                class="button card-settings"
                :class="{ 'card-settings--active': isActive }"
              >
                <span
                  class="choose-payment-method__font choose-payment-method__font--base"
                >
                  <img
                    :src="value.image"
                    class="choose-payment-method__image"
                  />
                  ...{{ value.title }}
                </span>
              </div>
            </template>
          </CRadio>
        </div>

        <div class="button button-plus" @click="openAddCardPopup">
          <CIcon
            name="plus"
            class="choose-payment-method__icon choose-payment-method__icon--plus"
          />
          <div class="choose-payment-method__plus">
            <span class="transaction-card__font transaction-card__font--base">
              {{ $t("general.popups.paymentMethod.newPaymentMethod") }}
            </span>
          </div>

          <small
            class="text-danger button-plus__error"
            v-if="errors.has('payment_method_id')"
          >
            {{ errors.first("payment_method_id") }}
          </small>
        </div>

        <div class="choose-payment-method__footer">
          <CButton
            type="submit"
            align="center"
            variant="primary"
            class="choose-payment-method__button"
          >
            <span
              class="choose-payment-method__font choose-payment-method__font--button"
            >
              {{ $t("general.popups.paymentMethod.button") }}
            </span>
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CButton from "@/features/ui/СButton.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CRadio from "@/features/ui/CRadio.vue";
import { mapActions, mapGetters } from "vuex";

import CIcon from "@/features/ui/CIcon.vue";
import { currencyFormat } from "@/tools/helpers";
import { paymentMethods } from "@/config/paymentMethods";

export default {
  name: "ChoosePaymentMethodPopup",
  components: { CIcon, CRadio, CButton, BasePopupLayout },
  mixins: [waitRequest, validationError],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters({
      wallet: "wallet/wallet",
      cards: "payments/cards",
      tempSubscriptionObject: "subscriptions/tempSubscriptionObject",
    }),
    isSubscription() {
      return Boolean(this.tempSubscriptionObject?.sub);
    },
    balance() {
      const balance = parseFloat(this.wallet?.balance || 0);

      return this.currencyFormat(balance);
    },

    cardsList() {
      const cards =
        this.cards?.cards?.methods.map((item) => {
          return {
            ...item,
            image: this.cardImage(item.brand),
            title: this.curdNumber(item.title),
          };
        }) || [];

      if (!this.isSubscription) {
        const wallet = {
          wallet: this.$t("general.popups.paymentMethod.wallet"),
          balance: `${this.balance} ${this.$t(
            "general.popups.paymentMethod.available"
          )}`,
        };

        return [wallet, ...cards];
      }

      return cards;
    },
  },
  mounted() {
    this.initState();
  },

  methods: {
    currencyFormat,
    ...mapActions({
      fetchPaymentCards: "payments/fetchPaymentCards",
      fetchWallet: "wallet/fetchWallet",
      addFunds: "wallet/addFunds",
      fetchWalletHistory: "wallet/fetchWalletHistory",
    }),
    initState() {
      this.loadWallet();
      this.fetchPaymentCards().catch(this.checkErrors);
    },

    loadWallet() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.fetchWallet().catch(this.checkErrors);
      });
    },
    curdNumber(string) {
      return string.replaceAll("*", "") || "WALLET";
    },
    cardImage(brand) {
      switch (brand) {
        case paymentMethods.Visa:
        case paymentMethods.VisaDebit:
          return "/img/payments/visa-small.webp";
        case paymentMethods.MasterCard:
        case paymentMethods.MasterCardDebit:
          return "/img/payments/mastercard-small.webp";
        case paymentMethods.Discover:
          return "/img/payments/discover-small.webp";
        case paymentMethods.AMEX:
          return "/img/payments/mastercard-small.webp";
        default:
          return "/img/payments/default-small.webp";
      }
    },
    close() {
      this.$emit("close");
    },
    openAddCardPopup() {
      this.$popup.open("AddCardPopup", {
        closeCallback: () => {
          this.$popup.close(1);
          this.$popup.open("ChoosePaymentMethodPopup");
        },
      });
    },
    setActivePaymentMethod() {
      if (this.data?.closeCallback) {
        this.data?.closeCallback({
          changedMethod: this.selected,
          card: this.data.card,
        });

        return;
      }

      this.$popup.open("SubscriptionPopup", {
        changedMethod: this.selected,
        card: this.data.card,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-payment-method {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 500px;
    overflow: auto;
    @include hideScroll;
  }

  &__field {
    margin-bottom: em(6);
  }

  &__settings {
    //display: flex;
  }

  &__radio-row {
    margin-bottom: em(20);
  }

  &__input {
    margin-top: em(10);
  }

  &__button {
    margin-bottom: em(10);
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__image {
    display: inline-block;
    width: 29px;
    height: 20px;
  }

  &__plus {
    margin-left: em(12);
    margin-bottom: em(37);
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-bottom: em(37);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--base {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $app-black;
    }

    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--button {
      color: $white;
      font-weight: 700;
    }

    &--addition {
      color: $app-gray-18;
      font-size: em(12);
      font-weight: 600;
    }
  }
}

.button-plus {
  display: flex;
  align-items: center;

  &__error {
    position: absolute;
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(10);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-gray-18;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }

  &__wallet {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>
