<template>
  <label
    class="c-input c-input--textarea"
    :class="{
      'c-input--disabled': disabled,
      'c-input--active': focused || value,
      'c-input--error': errors.has(name),
      'c-input--accent': isAccent,
      [`c-input--textarea--theme--${theme}`]: theme,
    }"
  >
    <span
      v-if="label"
      class="c-input__label"
      :class="{ 'c-input__label--active': focused || value }"
    >
      <span class="c-input__label-text">{{ label }}</span>
    </span>

    <textarea
      ref="textarea"
      v-validate="rules"
      class="c-input__field"
      :class="{
        [`c-input__field--theme--${theme}`]: theme,
      }"
      :type="typeLocal"
      :name="name"
      :value="value"
      autocomplete="off"
      :required="required"
      :disabled="disabled"
      :style="` height: ${textareaHeight}px;`"
      :placeholder="placeholder"
      @focus="setFocus(true)"
      @blur="setFocus(false)"
      @input="emitInput"
    />

    <span class="c-input__append c-input__append--after">
      <slot name="appendAfter" />
    </span>

    <span v-if="errors.has(name)" class="c-input__error">
      <span class="c-input__font c-input__font--error">
        {{ errors.first(name) }}
      </span>
    </span>
  </label>
</template>

<script>
const themes = {
  primary: "primary",
  secondary: "secondary",
  comments: "comments",
  "create-post": "create-post",
  settings: "settings",
};
export default {
  name: "Textarea",
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    isAccent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    areaClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isAdaptive: {
      type: Boolean,
      default: true,
    },
    textareaMinHeight: {
      type: Number,
      default: 74,
    },
    theme: {
      type: String,
      default: themes.primary,
      validator(value) {
        return Object.values(themes).includes(value);
      },
    },
  },
  data() {
    return {
      focused: false,
      typeLocal: this.type,
      textareaHeight: this.textareaMinHeight || 66,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.isAdaptive) this.setElementHeight();
      },
    },
  },

  methods: {
    setFocus(focused) {
      this.focused = focused;
      this.$emit("blur", this.$refs.textarea);
    },
    setElementHeight() {
      const refElement = this.$refs.textarea;

      if (!refElement) return;

      if (!this.value) {
        refElement.removeAttribute("style");
      } else {
        this.textareaHeight = refElement.scrollHeight;
      }
    },
    emitInput(e) {
      this.$validator.reset({ name: this.name });
      this.$emit("input", e.target.value);

      if (this.autoHeight) {
        this.contentHeight = 0;
        this.$nextTick(() => {
          this.contentHeight = this.$refs.textarea.scrollHeight;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/helpers";

.c-input {
  $parent: &;

  position: relative;
  width: 100%;
  margin-bottom: em(20);

  &__label {
    display: block;
    margin-bottom: em(20);

    position: absolute;
    top: 50%;
    left: em(16);
    transform: translateY(-50%);
    transition: top 0.3s, font-size 0.3s;
  }

  &__label-text {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    font-size: em(18);
    line-height: em(23);

    letter-spacing: em(16 * -0.04);

    color: $app-font-color-placeholder;
    transition: font-size 0.3s;
    padding-right: em(14);
    display: inline-block;
  }

  &__field {
    @include autoFill($app-blue);

    width: 100%;
    //height: 100%;
    min-height: em(64, 16);
    padding: 0 em(16, 16);
    color: $app-blue;
    font-weight: normal;
    font-size: em(16);
    font-family: $font-default;
    font-style: normal;
    line-height: 1.2;
    border: 1px solid $app-border-gray-1;
    border-radius: em(8);
    outline: none;

    &::placeholder {
      color: $app-font-color-placeholder;
    }

    &:focus {
      border: 1px solid $app-blue-2;
    }

    &--password {
      padding-right: em(38, 14);
    }
  }

  &__button {
    position: absolute;
    right: em(8);
    bottom: em(5);
    display: block;
    width: em(24);
    height: em(24);
    padding: 0;
    background: none;
    border: none;

    @include hover {
      opacity: 0.8;
    }

    &:focus {
      #{$parent} {
        &__icon {
          color: rgba($app-border-gray-1, 0.7);
        }
      }
    }
  }

  &__append {
    position: absolute;
    top: 50%;
    display: flex;
    line-height: 1;
    transform: translateY(-50%);

    &--after {
      right: em(16);
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: em(16);
    color: $app-font-color-gray-1;
  }

  &__error {
    position: absolute;
    //bottom: em(-20);
    bottom: auto;
    left: 0;
    display: block;
    width: 100%;
    color: $red;
    font-weight: $font-medium;
  }

  &__font {
    &--error {
      color: $red;
      //@include text-overflow;

      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
    }
  }

  &--error {
    #{$parent} {
      &__label-text {
        color: $red;
      }

      &__field {
        color: $red;
        border-color: $red;
      }

      &__font {
        &--error {
          color: $red;
          @include text-overflow;

          display: inline-block;
          width: 100%;
          font-weight: normal;
          font-size: em(12);
          font-style: normal;
          line-height: 1.2;
        }
      }
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;

    #{$parent} {
      &__field {
        border-color: rgba($app-border-gray-1, 0.15);

        @include hover {
          border-color: rgba($app-border-gray-1, 0.15);
        }
      }
    }
  }

  &--active {
    #{$parent} {
      &__label {
        top: 20%;
        left: em(16);
      }

      &__label-text {
        font-family: $font-default;
        font-size: em(10);
        line-height: em(13);
      }
    }
  }

  &--textarea {
    min-height: em(74);
    margin-bottom: 0;

    #{$parent} {
      &__field {
        height: 100%;
        padding: em(12, 14) em(12, 14);
        overflow: hidden auto;
        resize: none;
        min-height: em(44);
        max-height: em(300);

        &::placeholder {
          padding-top: em(-2);
          color: $app-font-color-placeholder-secondary;
        }

        @include hideScroll;

        &--theme {
          &--primary {
          }

          &--secondary {
            &::placeholder {
              color: $app-font-color-placeholder-secondary;
            }

            background-color: $app-gray;
          }

          &--comments {
            min-height: em(74);
            background-color: $app-gray;

            &::placeholder {
              color: $app-font-color-placeholder-secondary;
            }
          }

          &--create-post {
            min-height: em(78);
            background-color: $app-gray;

            &::placeholder {
              color: $app-font-color-placeholder-secondary;
            }
          }

          &--settings {
            color: $black;
            max-height: em(150);
          }
        }
      }

      &__error {
        bottom: em(-20);
        padding: 0 em(12);
      }

      &__label {
        position: relative;
        width: 100%;

        top: 30%;
        left: em(16);

        transform: translateY(0);
      }

      &__append {
        position: absolute;
        top: auto;
        bottom: em(5);
        display: flex;
        line-height: 1;
        transform: translateY(-50%);

        &--after {
          right: em(16);
        }
      }

      &__label {
        top: 8%;
        left: em(16);

        &--active {
          top: 2%;
          left: em(16);
        }
      }

      &__font {
        &--error {
          color: $red;
          @include text-overflow;

          display: inline-block;
          width: 100%;
          font-weight: normal;
          font-size: em(12);
          font-style: normal;
          line-height: 1.2;
        }
      }
    }

    &--theme {
      &--settings {
        #{$parent} {
          &__label {
            margin: 0;
            padding: 0 em(6);
            width: auto;
            position: absolute;
            background-color: $white;

            &--active {
              top: 0;
              left: em(16);
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  &--required {
    #{$parent} {
      &__label-text::after {
        content: " *";
        color: $app-blue-2;
      }
    }
  }
}
</style>
