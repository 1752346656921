const state = () => ({
  linkedPosts: [],
  linkedPostsMeta: null,
});

const actions = {};

const getters = {
  linkedPosts: (state) => state.linkedPosts,
  linkedPostsMeta: (state) => state.linkedPostsMeta,
};

const mutations = {
  setPosts(state, { posts } = {}) {
    state.linkedPosts = posts;
  },

  updatePosts(state, { posts } = {}) {
    state.linkedPosts = [...state.linkedPosts, ...posts];
  },

  setMeta(state, { meta } = {}) {
    state.linkedPostsMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
