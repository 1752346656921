import { render, staticRenderFns } from "./UnsubscribePopup.vue?vue&type=template&id=205bbfdd&scoped=true&"
import script from "./UnsubscribePopup.vue?vue&type=script&lang=js&"
export * from "./UnsubscribePopup.vue?vue&type=script&lang=js&"
import style0 from "./UnsubscribePopup.vue?vue&type=style&index=0&id=205bbfdd&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205bbfdd",
  null
  
)

export default component.exports