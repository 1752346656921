<template>
  <div class="info-popup">
    <div class="info-popup__content">
      <slot name="close" class="info-popup__close" />

      <div class="info-popup__title">
        <h3 class="">
          {{ data.title || 'Error' }}
        </h3>
      </div>

      <div class="info-popup__message">
        <div class="info-popup__text">
          <span
            class=""
            v-html="data.description || 'Something went wrong....'"
          />
        </div>
      </div>

      <div class="info-popup__footer">
        <button type="button" class="info-popup__button" @click="close">
          <span class=""> Close </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPopup',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appear: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.info-popup {
  $parent: &;

  width: em(420);

  &__content {
    position: relative;
    z-index: 2;
    background: $white;
    border: em(1) solid grey;
    border-radius: em(20);
    box-shadow: 0 0 em(18) em(-3) rgba(0, 0, 0, 0.5);
  }
}
</style>
