import User from "@/components/models/User";

const state = () => ({
  swiperItems: [],
  swiperOptions: {},
  currentUser:
    localStorage.currentUser != null
      ? new User(JSON.parse(localStorage.currentUser))
      : null,
  token: localStorage.token != null ? localStorage.token : null,
  addToListUser: null,
  buyItem: null,
  updates: {
    notifications: 0,
    messages: 0,
    likes: 0,
    subscribes: 0
  },
  spinner: false,
  pusher: null,
  partnerRole: false,
});

const actions = {
  logout() {

  }
};

const getters = {
  user: (state) => state.currentUser,
  token: (state) => state.token,
  isAuth: (state) => Boolean(state.token),
  isPartnerRole: (state) => Boolean(state.partnerRole),
  isAnonymousUser: (state) => {
    const user = state?.currentUser

    return user && user?.is_anonymous;
  },
  updates:(state) => state.updates,
};

const mutations = {
  setPartner(state, isPartner) {
    state.partnerRole = isPartner;
  },
  logout(state) {
    state.token = null;
    state.currentUser = null
  },

  setUser(state, user) {
    state.currentUser = new User(user)
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
