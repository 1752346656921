const state = () => ({
  mediaPost: [],
  mediaPostMeta: null,
});

const actions = {};

const getters = {
  mediaPost: (state) => state.mediaPost,
  mediaPostMeta: (state) => state.mediaPostMeta,
};

const mutations = {
  setMedia(state, { posts } = {}) {
    state.mediaPost = posts;
  },

  updateMedia(state, { posts } = {}) {
    state.mediaPost = [...state.mediaPost, ...posts];
  },

  setMetaMedia(state, { meta } = {}) {
    state.mediaPostMeta = meta;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
