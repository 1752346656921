import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      lockPrice: "postCreate/lockPrice",
    }),
    lockSettings() {
      return [
        {
          id: 0,
          name: this.$t("general.popups.lockPopup.choices.everyone"),
          icon: "wallet-money",
        },
        {
          id: 1,
          name: this.$t("general.popups.lockPopup.choices.followers"),
          icon: "card",
        },
        {
          id: 2,
          name: this.$t("general.popups.lockPopup.choices.subscribersOnly"),
          icon: "card",
        },
        {
          id: 3,
          name: this.$t("general.popups.lockPopup.choices.payersOnly"),
          icon: "card",
          hasPriceField: true,
          price: `$${this.lockPrice || 0}`,
          buttonText: this.$t("general.popups.lockPopup.buttons.updatePrice"),
          action: this.updatePrice,
        },
      ];
    },
  },
};
