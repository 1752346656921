<template>
  <BasePopupLayout :title="$t('general.popups.updatePostPrice.title')">
    <div class="update-post-price-popup">
      <form
        class="update-post-price-popup__form"
        novalidate
        @submit.prevent="sendPrice"
      >
        <div class="update-post-price-popup__field">
          <div class="update-post-price-popup__field-name">
            <span
              class="update-post-price-popup__font update-post-price-popup__font--field-name"
            >
              {{ $t("general.popups.updatePostPrice.priceField") }}
            </span>
          </div>

          <CInput
            class="update-post-price-popup__input"
            type="text"
            name="amount"
            input-mode="text"
            theme="comments"
            :rules="'required|min_value:1'"
            @focus="onFocus"
            v-model="amount"
            :placeholder="
              $t('general.popups.updatePostPrice.priceFieldPlaceholder')
            "
          >
            <template #appendAfter>
              <span
                class="update-post-price-popup__font update-post-price-popup__font--addition"
                >USD</span
              >
            </template>
          </CInput>
        </div>

        <div class="update-post-price-popup__footer">
          <CButton
            :disabled="!isValid"
            type="submit"
            variant="primary"
            class="update-post-price-popup__button"
          >
            <span
              class="update-post-price-popup__font update-post-price-popup__font--button"
            >
              {{ $t("general.popups.updatePostPrice.buttonSend") }}
            </span>
          </CButton>
        </div>
      </form>
    </div>
  </BasePopupLayout>
</template>

<script>
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "UpdatePostPricePopup",
  components: { CButton, CInput, BasePopupLayout },
  mixins: [waitRequest, validationError],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      lockPrice: "postCreate/lockPrice",
    }),
    isValid() {
      return this.amount > 0
    },
    price: {
      get() {
        return this.lockPrice || 0;
      },
      set(val) {
        this.form.amount = val;
      },
    },
  },

  data() {
    return {
      amount: 1,
    };
  },
  mounted() {
      this.initState()
  },
  methods: {
    ...mapMutations({
      setLockPrice: "postCreate/setLockPrice",
    }),

    initState() {
      this.amount = this.lockPrice ? this.lockPrice : 1
    },

    close() {
      this.$emit("close");
    },
    onFocus() {
      this.amount = this.isValid ?  this.amount : '';
    },

    sendPrice() {
      this.setLockPrice({ lockPrice: this.amount });
      this.$popup.close(1);
      this.$nextTick(() => {
        this.$popup.open("LockPopup");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-post-price-popup {
  margin-bottom: 0;
  padding-left: 0;

  &__title {
    margin-top: em(21);
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__field {
    margin-bottom: em(6);
  }

  &__settings {
    //display: flex;
  }

  &__radio-row {
    margin-bottom: em(20);
  }

  &__input {
    margin-top: em(10);
  }

  &__button {
    margin-bottom: em(10);
  }

  &__footer {
    margin: em(0) 0 em(8);
  }

  &__font {
    color: $black;
    font-family: $font-default;

    font-style: normal;
    line-height: 1;

    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--button {
      color: $white;
      font-weight: 700;
    }

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(17);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }
}
</style>
