<template>
  <CLink
    class="c-button"
    :class="[`c-button--theme--${theme}`, `c-button--align--${align}`]"
    :type="type"
    :to="to"
    :target="target"
    :disabled="isDisabled"
    @click.native="emitClick"
  >
    <span class="c-button__wrapper">
      <span class="c-button__inner">
        <slot name="icon" />

        <span class="c-button__font c-button__font--text">
          <slot />
        </span>
      </span>
    </span>
  </CLink>
</template>

<script>
import CLink from "@/features/ui/CLink.vue";

const themes = ["primary", "outlined", "black", "danger"];
const aligns = ["left", "center", "space"];

export default {
  name: "CButton",
  components: {
    CLink,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },

    to: {
      type: String,
      default: undefined,
    },

    target: {
      type: String,
      default: undefined,
    },

    theme: {
      type: String,
      default: "primary",
      validator(value) {
        return themes.includes(value);
      },
    },

    align: {
      type: String,
      default: "center",
      validator(value) {
        return aligns.includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
  },
  methods: {
    emitClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/helpers";

.c-button {
  $parent: &;
  padding: 0;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: background 250ms ease-in-out, transform 150ms ease,
    filter 250ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  z-index: 0;

  &__wrapper {
    position: relative;
    display: block;
    height: 100%;
  }

  &__inner {
    display: flex;
    align-items: center;
    color: $white;
    transition: transform $time-normal, color $time-normal;
    padding: em(12) em(10) em(13);
    position: relative;
    height: 100%;
    z-index: 2;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__font {
    &--text {
      flex-grow: 1;
      font-size: em(12);
    }
  }

  &--theme {
    &--black {
      #{$parent} {
        &__wrapper {
        }

        &__inner {
          background-color: $black;
          border-radius: em(100);
        }
      }

      @include hover {
        #{$parent} {
          &__inner {
            transform: translateY(#{em(-1)});
          }
        }
      }

      &:active {
        #{$parent} {
          &__inner {
            transform: translateY(#{em(2)});
          }
        }
      }

      &:disabled {
        pointer-events: none;

        #{$parent} {
          &__inner {
            background-color: rgba($black, 0.7);
          }
        }
      }
    }

    &--primary,
    &--danger {
      #{$parent} {
        &__wrapper {
          //&:before {
          //  top: em(4);
          //  left: 0;
          //  content: '';
          //  position: absolute;
          //  width: 100%;
          //  height: 100%;
          //  background-color: $app-blue;
          //  z-index: 1;
          //}
        }

        &__inner {
          background-color: $app-blue;
          border-radius: em(100);
        }
      }

      @include hover {
        #{$parent} {
          &__inner {
            transform: translateY(#{em(-1)});
          }
        }
      }

      &:active {
        #{$parent} {
          &__inner {
            transform: translateY(#{em(2)});
          }
        }
      }

      &:disabled {
        pointer-events: none;

        #{$parent} {
          &__inner {
            background-color: rgba($app-blue, 0.7);
          }
        }
      }
    }

    &--danger {
      #{$parent} {
        &__inner {
          background-color: $app-red-5;
        }
      }

      &:disabled {
        pointer-events: none;

        #{$parent} {
          &__inner {
            background-color: rgba($app-red-5, 0.7);
          }
        }
      }
    }

    &--outlined {
      border-radius: em(100);
      border: 1.5px solid $app-blue;
      background: transparent;

      #{$parent} {
        &__inner {
          padding: em(0) em(15);
          transition: background-color $time-normal, transform $time-normal,
            color $time-normal;
        }
      }

      @include hover {
        #{$parent} {
          &__inner {
            background-color: transparent;
          }
        }
      }

      &:active {
        #{$parent} {
          &__inner {
            color: $app-black;
          }
        }
      }

      &:disabled {
        border: none;

        #{$parent} {
          &__inner {
            background-color: transparent;
            color: $app-black;
          }
        }
      }
    }
  }

  &--align {
    &--center {
      #{$parent} {
        &__inner {
          text-align: center;
        }
      }
    }

    &--left {
      #{$parent} {
        &__inner {
          text-align: left;
        }
      }
    }
    &--space {
      #{$parent} {
        &__font--text {
          display: inline-flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
