import { cardsTypes } from "@/config/cardsTypes";
import axios from "@/plugins/axios";
import { cleanObj } from "@/tools/helpers";

const state = () => ({
  tempTip: null,
  cards: {
    cards: { methods: [] },
    history: { data: [] },
  },
});

const actions = {
  fetchPaymentHistory({ commit }, { page = 1, limit = 30 } = {}) {
    const requestQuery = {
      page,
      pre_page: limit,
    };
    const params = cleanObj(requestQuery);

    return axios({ method: "GET", url: "/payments", params }).then(
      (response) => {
        commit("setCards", { cards: response.data, type: "history" });

        return response;
      }
    );
  },

  createPayments(store, paymentData) {
    const data = cleanObj(paymentData);

    return axios({ method: "POST", url: "/payments", data }).then(
      (response) => {

        return response;
      }
    );
  },

  createPaymentMethod({ commit }, form) {
    return axios({ method: "POST", url: "/payments/method", data: form }).then(
      (response) => {
        commit("updateCards", response.data);

        return response;
      }
    );
  },

  updatePaymentMethod({ commit }, { form, id }) {
    return axios({
      method: "PUT",
      url: `/payments/method/${id}`,
      data: form,
    }).then((response) => {
      commit("updateCards", response.data);

      return response;
    });
  },

  fetchPaymentCards({ commit }) {
    return axios({ method: "GET", url: "/payments/method" }).then(
      (response) => {
        commit("setCards", { cards: response.data, type: "cards" });

        return response;
      }
    );
  },

  setDefaultCards({ commit }, id) {
    return axios({ method: "PATCH", url: `/payments/method/${id}` }).then(
      (response) => {
        commit("setCards", { cards: response.data, type: "cards" });

        return response;
      }
    );
  },
  deleteCard({ commit }, id) {
    return axios({ method: "DELETE", url: `/payments/method/${id}` }).then(
      (response) => {
        commit("setCards", { cards: response.data, type: "cards" });

        return response;
      }
    );
  },
};

const getters = {
  cards: (state) => state.cards,
  tempTip: (state) => state.tempTip,
};

const mutations = {
  setCards(state, { cards, type = cardsTypes.cards } = {}) {
    state.cards[type] = cards;
  },

  updateCards(state, card) {
    const listDataIndex = state.cards?.cards?.methods?.findIndex(
      (item) => item?.id === card.id
    );

    if (listDataIndex === -1) return;

    state.cards.cards.methods[listDataIndex] = { ...card };
    state.cards?.cards?.methods.splice(state.cards?.cards?.methods?.length);
  },
  setTempTip(state, tip) {
    state.tempTip = tip
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
