<template>
  <BasePopupLayout :title="$t('general.popups.location.title')">
    <CInput
      type="text"
      name="text"
      input-mode="text"
      theme="secondary"
      v-model.trim="location"
      @input="fetchLocation"
      :placeholder="$t('general.popups.location.inputPlaceholder')"
    />

    <ul class="location-list">
      <li
        v-for="location in locationList"
        :key="location.id"
        class="location-list__item"
      >
        <button
          type="button"
          @click.prevent="selectPlace(location)"
          class="location-list__button"
        >
          <span class="location-list__font location-list__font--text">
            {{ location.address }}
          </span>
        </button>
      </li>
    </ul>

    <AppInfo
      type="error"
      class="location-list__info"
      :text="$t('general.popups.location.info')"
    />
  </BasePopupLayout>
</template>

<script>
import CInput from "@/features/ui/СInput.vue";
import BasePopupLayout from "@/features/components/popups/layout/BottomPopupLayout.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { debounce, pick } from "lodash";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LocationPopup",
  components: { AppInfo, BasePopupLayout, CInput },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      storeLocation: "postCreate/location",
    }),
  },

  data() {
    return {
      location: "",
      locationList: [
        {
          id: 1,
          lat: 40.7127281,
          lon: -74.0060152,
          address: "New York, NY, United States of America",
          action: this.close,
        },
      ],
    };
  },

  mounted() {
    this.initState();
  },
  methods: {
    ...mapMutations({
      setLocation: "postCreate/setLocation",
    }),
    close() {
      this.$emit("close");
    },

    initState() {
      if (this.storeLocation?.address) {
        this.locationList = [{ ...this.storeLocation, id: 1,  action: this.close,}];
      }
    },

    selectPlace(location) {
      const currentLocation = pick(location, ["lat", "lon", "address"]);
      this.setLocation({ location: currentLocation });

      this.close();
    },

    fetchLocation: debounce(function () {
      if (this.requestInProgress) return;

      const path = `/posts/location`;
      const url = new URL(path, process.env.VUE_APP_API_URL);
      url.searchParams.set("query", this.location);

      const searchUrl = path + url.search;

      return this.waitRequest(() => {
        return this.$get(
          searchUrl,
          this.successLoad,
          this.failureLoad,
          this.checkErrors
        );
      }).catch(this.checkErrors);
    }, 500),

    successLoad(locationData) {
      this.locationList = locationData?.addresses?.map((point) => {
        const id = `${point.lat}` + `${point.lon}`;

        return { ...point, id };
      });
    },

    failureLoad(locationLoadError) {
      console.log("locationLoadError", locationLoadError);
    },
  },
};
</script>

<style scoped lang="scss">
.location-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: em(500);
  overflow: scroll;

  @include hideScroll();

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;
  }

  &__item {
    display: flex;
    position: relative;
    padding: 0;
    margin-left: em(0);
    margin-bottom: em(21);
  }

  &__font {
    &--text {
      color: $app-black;
      font-family: $font-default;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
