import Bundle from "./Bundle";

export default class User {

  static ROLE_USER = 'User';
  static ROLE_CREATOR = 'Creator';
  static ROLE_CONTENT_MOD = 'ContentMod';
  static ROLE_MODERATOR_MOD = 'ModeratorMod';
  static ROLE_SUPERUSER = 'SuperAdmin'

  static CHANNEL_EMAIL = 0;
  static CHANNEL_GOOGLE = 1;
  static CHANNEL_TWITTER = 2;

  id = 0;
  name = "";
  username = "";
  avatar = null;
  cover = null;
  role = [User.ROLE_USER];
  bio = null;
  location = null;
  website = null;
  price = 0;
  bundles = [];
  isSubscribed = false;
  is_anonymous = null

  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.name = data.name;
    this.username = data.username || 'no name';
    this.face_price = data.face_price || '0';
    this.avatar = data.avatar;
    this.cover = data.cover;
    this.role = data.role;
    this.bio = data.bio ? data.bio : null;
    this.location = data.location ? data.location : null;
    this.website = data.website ? data.website : null;
    this.price = data.price ? data.price / 100 : null;
    this.isSubscribed = data.is_subscribed;
    this.is_followed = data.is_followed;
    this.promo = data.promo;
    this.discountBundles = data.bundles;
    this.subscription = data.subscription;
    this.nsfw_enabled = data.nsfw_enabled;
    this.is_email_verified = data.is_email_verified;
    this.stat = data.stat;
    this.email = data.email;
    this.is_anonymous = data.is_anonymous;

    if (data.bundles) {
      for (let b of data.bundles) {
        this.bundles.push(new Bundle(b));
      }
    }
  }

  get initials() {
    const a = this.username?.charAt(0)?.toUpperCase() || '';
    const b = this.username?.charAt(1) || '';
    return a + b;
  }

  get url() {
    return `/${this.username}`;
  }

  get isFree() {
    return !this.price && true;
  }

  get priceFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.price;
  }

  get isAdmin() {
    return this.role.includes(User.ROLE_SUPERUSER) || this.role.includes(User.ROLE_CONTENT_MOD) || this.role.includes(User.ROLE_MODERATOR_MOD);
  }

  get canModerate() {
    return this.role.includes(User.ROLE_SUPERUSER) || this.role.includes(User.ROLE_MODERATOR_MOD);
  }

  get isCreator() {
    return this.role.includes(User.ROLE_CREATOR);
  }
}
