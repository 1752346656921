<template>
  <div>
    <b-container>
      <b-row>
        <b-col lg="3" md="2" sm="2" class="d-none d-sm-block">
          <b-nav vertical class="sticky-top">
            <b-nav-item to="/admin"  link-classes="m-0 py-0">
              <h5 class="text-uppercase p-0 mx-0 my-3">
                <span class="d-none d-lg-block">
                  {{ $t("admin.admin-console") }}
                </span>
                <span class="d-lg-none"> AC </span>
              </h5>
            </b-nav-item>

            <b-nav-item
              v-for="(item, index) in filteredByPermissionItems"
              :key="index"
              v-bind="{
                to: item.url ? item.url : null,
              }"
              exact exact-active-class="router-link-active"
              @click.prevent="item.action ? this[item.action]() : null"
              link-classes="my-1 mx-0 py-0"
            >
              <i :class="item.icon" />
              <span class="d-none d-lg-inline ml-3">
                {{ $t(item.text) }}
              </span>
            </b-nav-item>

            <b-nav-item @click.prevent="logOut()">
              <i class="bi-box-arrow-left" />
              <span class="d-none d-lg-inline ml-3">
                {{ $t("general.logout") }}
              </span>
            </b-nav-item>
          </b-nav>
        </b-col>

        <b-col sm="9" cols="12" class="border-left border-right content">
          <router-view></router-view>
          <div class="spinner" v-if="spinner">
            <b-spinner variant="secondary" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import adminNavigation from "@/config/adminNavigation";

export default {
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },

    filteredByPermissionItems() {
      // Retrieve the roles of the current user, defaulting to an empty array if none are found
      const currentUserRoles = this.$store.state.currentUser?.role || [];

      // Iterate over all navigation items to determine which items the user has permission to access
      return this.navItems.filter((navItem) => {
        // Check if the navigation item has specified permissions
        if (navItem.permissions && navItem.permissions.length) {
          // Return true if any of the user's roles are included in the item's permissions
          // This means the user has the required permission for this nav item
          return currentUserRoles.some(userRole => navItem.permissions.includes(userRole));
        } else {
          // If no permissions are defined for an item, it is accessible to all users
          return true;
        }
      });
    },
  },

  data() {
    return {
      navItems: adminNavigation,
    };
  },

  methods: {
    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );
      this.$saveToken(null);
      this.$saveUser(null);
      //location = process.env.VUE_APP_APP_URL + "/admin";
      this.$router.push("/admin");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
@include media-breakpoint-down(md) {
  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
a.nav-link {
  font-size: 120%;
  padding: 0.75rem 1rem;
}
.icon {
  position: relative;
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
</style>
