export const FormStorageService = {
  saveForm(key, formData) {
    sessionStorage.setItem(key, JSON.stringify(formData))
  },
  loadForm(key) {
    const data = sessionStorage.getItem(key)
    return data ? JSON.parse(data) : null
  },
  clearForm(key) {
    sessionStorage.removeItem(key)
  },
}
