import axios from "axios";
// import Vue from 'vue'
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/" + process.env.VUE_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (config) {
    if (config.data.updates) {
      store.state.updates = { ...config.data.updates.notifications };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    const code = error.response?.status;

    switch (code) {
      case 400:
        console.log(error, "400");
        break;
      case 403:
        console.log(error, "403");
        break;
      case 401:
        store.dispatch("logout");
        break;
      case 405:
        console.log(error, "Method Not Allowed");
        break;
      case 409:
        console.log(error, "Conflict.");
        break;
      case 422:
        console.log(error, "invalid credentials");
        break;
      case 500:
      case 503:
        break;
      default:
        console.log("no errors");
        break;
    }

    return Promise.reject(error);
  }
);

export default instance;
