<template>
  <ConfirmPopupLayout
    class="creator-preferences-popup"
    :title="$t('general.popups.creatorPreferences.title')"
    :button-text="$t('general.popups.creatorPreferences.buttonClose')"
    :custom-close="customClose"
  >
    <div class="creator-preferences-popup__content">
      <div>
        <span
          class="creator-preferences-popup__font creator-preferences-popup--text"
        >
          {{ $t("general.popups.creatorPreferences.text") }}
        </span>
      </div>

      <div class="creator-preferences-popup__settings">
        <CRadio
          v-model="selected"
          v-for="(item, index) in settings"
          :key="`${index + '-group-2'}`"
          :name="item.name"
          :value="item"
          allow-uncheck
          input-type="checkbox"
          group-name="settings"
          class="creator-preferences-popup__button"
        >
          <template #default="{ isActive, value }">
            <div
              class="button creator-preferences-button"
              :class="{ 'creator-preferences-button--active': isActive }"
            >
              <template  v-if="value.value.image">
                <img
                  :src="value.value.image"
                  alt=""
                  class="creator-preferences-button__image"
                />&nbsp;
              </template>

              <img
                v-if="value.value.image_second"
                :src="value.value.image_second"
                alt=""
                class="creator-preferences-button__image"
              />

              <span class="creator-preferences-button__font">
               &nbsp; {{ value.value.label }}
              </span>
            </div>
          </template>
        </CRadio>

        <CRadio
          v-model="selectedOrientation"
          v-for="(item, index) in settingsOrientation"
          :key="`${index + '-group'}`"
          :name="item.name"
          :value="item"
          allow-uncheck
          input-type="checkbox"
          group-name="settingsOrientation"
          class="creator-preferences-popup__button"
        >
          <template #default="{ isActive, value }">
            <div
              class="button creator-preferences-button"
              :class="{ 'creator-preferences-button--active': isActive }"
            >
              <template  v-if="value.value.image">
                <img
                  :src="value.value.image"
                  alt=""
                  class="creator-preferences-button__image"
                />&nbsp;
              </template>

              <img
                v-if="value.value.image_second"
                :src="value.value.image_second"
                alt=""
                class="creator-preferences-button__image"
              />
              <span class="creator-preferences-button__font">
              &nbsp;  {{ value.value.label }}
              </span>
            </div>
          </template>
        </CRadio>
      </div>
    </div>
  </ConfirmPopupLayout>
</template>

<script>
import ConfirmPopupLayout from "@/features/components/popups/layout/ConfirmPopupLayout.vue";
import CRadio from "@/features/ui/CRadio.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CreatorPreferencesPopup",
  mixins: [waitRequest, validationError],
  components: { CRadio, ConfirmPopupLayout },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      isNSFWContent: "flows/isNSFWContent",
      settingsSex: "flows/settingsSex",
      contentSettings: "flows/contentSettings",
    }),
  },

  data() {
    return {
      isActive: false,
      selected: [],
      settings: [],
      selectedOrientation: [],
      settingsOrientation: [],
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      updateFlowSettings: "flows/updateFlowSettings",
    }),

    async customClose() {
      if (this.settings.length) {
        const categories = this.selected?.map((item) => item.id);
        this.updateFlowSettings({
          data: categories,
          key: "sex_preference",
        });
      }

      if (this.settingsOrientation.length) {
        const categories = this.selectedOrientation?.map((item) => item.id);
        this.updateFlowSettings({
          data: categories,
          key: "orientation_preference",
        });
      }

      this.setSettings();
      this.$popup.close();
    },

    initState() {
      this.settings =
        Object.entries(this.settingsSex.sex).map(([key, value]) => {
          return {
            id: parseInt(key),
            value,
          };
        }) || [];

      this.selected =
        this.settings.filter((item) =>
          this.contentSettings.flow_settings?.sex_preference.includes(item.id)
        ) || [];

      this.settingsOrientation =
        Object.entries(this.settingsSex.orientation).map(([key, value]) => {
          return {
            id: parseInt(key),
            value,
          };
        }) || [];

      this.selectedOrientation =
        this.settingsOrientation.filter((item) =>
          this.contentSettings.flow_settings?.orientation_preference.includes(
            item.id
          )
        ) || [];
    },

    setSettings() {
      if (this.requestInProgress) return;

      const settings = {
        sex_preference: this.selected?.map((item) => item.id) || [],
        orientation_preference:
          this.selectedOrientation?.map((item) => item.id) || [],
      };

      return this.waitRequest(() => {
        return this.$post(
          "/profile/settings",
          settings,
          () => {},
          () => {
            this.settings = {};
          },
          this.checkErrors
        );
      });
    },

    toggle(value) {
      if (this.requestInProgress) return;

      const state = value;
      this.updateContentPreferences(state);
    },

    successCallback() {
      console.log("successCallback");
    },

    failureCallback(value) {
      this.updateContentPreferences(value);
    },
  },
};
</script>

<style scoped lang="scss">
.creator-preferences-popup {
  $parent: &;

  &__content {
    padding: 0 em(17);
  }

  &__settings {
    margin-top: em(32);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__button {
    margin-bottom: em(17);

    $gap: 13px;
    $n-items-per-row: 2;
    $n-items-on-last-row: 3;
    $n-gap-row: inner-gap($n-items-per-row, $gap);
    $n-gap-last-row: inner-gap($n-items-on-last-row, $gap);

    width: calc((100% - $n-gap-row) / $n-items-per-row);

    // change size on last 3 items
    //&:nth-last-child(-n + #{$n-items-on-last-row}) {
    //  width: calc((100% - $n-gap-last-row) / $n-items-on-last-row);
    //}
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }
}

.creator-preferences-button {
  $parent: &;

  display: flex;
  justify-content: center;
  border-radius: em(5);
  background-color: $app-gray-4;
  padding: em(14);
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &__image {
    max-width: em(14);
    max-height: em(14);
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }

  &--active {
    background-color: $app-blue;

    #{$parent} {
      &__font {
        color: $white;
      }
    }
  }
}
</style>
