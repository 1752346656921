import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

export default {
  mixins: [waitRequest, validationError],
  data() {
    return {
      settings: {},
      sexSettings: [],
      metadataSettings: {},
      settingsCategories: [],
      selectedCategories: [],
    }
  },

  computed: {
    adminRoles() {
      return Object.entries(this.settings.roles || {}).map(([key, name]) => {
        return { id: key, name }
      }).filter(role => role.id !== 'SuperAdmin')
    }
  },

  methods: {
    getSettingsCategories() {
      return this.waitRequest(() => {
        const url = `/collect/settings`;

        return this.$get(
          url,
          this.successSettingsListCallback,
          this.failureSettingsCallback,
          this.checkErrors
        );
      });
    },

    getSettingsSex() {
      return this.waitRequest(() => {
        const url = `/collect/sex`;

        return this.$get(
          url,
          this.successSettingsSexCallback,
          this.failureSettingsCallback,
          this.checkErrors
        );
      });
    },

    getSettingsMetadata() {
      return this.waitRequest(() => {
        const url = `/collect/metadata`;

        return this.$get(
          url,
          this.successSettingsMetadataCallback,
          this.failureSettingsCallback,
          this.checkErrors
        );
      });
    },

    successSettingsListCallback(data) {
      this.settings = data?.settings;

      this.settingsCategories = this.settings?.categories || [];
    },

    successSettingsSexCallback(data) {
      this.sexSettings = data
    },

    successSettingsMetadataCallback(data) {
      this.metadataSettings = data
    },

    failureSettingsCallback(error) {
      console.log('error', error)
    },
  }
}
