<template>
  <label
    class="c-input"
    :class="{
      'c-input--disabled': disabled,
      'c-input--active': isActive,
      'c-input--error': errors.has(name),
      [`c-input--theme--${theme}`]: theme,
    }"
  >
    <span v-if="label" class="c-input__label">
      <span class="c-input__label-text">
        {{ label }}
      </span>
    </span>

    <input
      v-bind="$attrs"
      class="c-input__field"
      :class="{
        'c-input__field--password': isPassword,
        [`c-input__field--theme--${theme}`]: theme,
      }"
      :data-vv-as="customFieldName"
      :type="typeLocal"
      :value="value"
      mask="#### #### #### ####"
      :inputmode="inputMode"
      :name="name"
      autocomplete="off"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      @focus="setFocus(true)"
      @blur="setFocus(false)"
      @input="emitInput"
      v-validate="rules"
    />

    <span class="c-input__append c-input__append--after">
      <slot name="appendAfter" :errors="errors.has(name)"/>
    </span>

    <button
      v-if="isPassword && !$slots.appendAfter"
      type="button"
      class="c-input__button"
      @click="showPassword"
    >
      <CIcon
        :name="typeLocal === 'password' ? 'eye-closed' : 'eye-open'"
        class="c-input__icon c-input__icon--password"
      />
    </button>

    <button
      v-if="clearButton"
      type="button"
      class="c-input__button"
      @click="showPassword"
    >
      <CIcon name="close" class="c-input__icon" />
    </button>

    <span v-if="isPreloader" class="c-input__preloader"></span>

    <span v-if="errors.has(name) && !isCustomErrors"  class="c-input__error">
      <span class="c-input__font c-input__font--error">
        {{ errors.first(name) }}
      </span>
    </span>

    <span v-if="customErrors && customErrors.has(name)" class="c-input__error">
      <span class="c-input__font c-input__font--error">
        {{ customErrors.first(name) }}
      </span>
    </span>

    <span class="c-input__append c-input__append--addition">
      <slot name="appendAddition" :has-errors="errors.has(name)" :errors="errors" />
    </span>
  </label>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "CInput",
  components: { CIcon },
  inject: ["$validator"],
  props: {
    type: {
      default: "text",
      validator(value) {
        return [
          "text",
          "email",
          "tel",
          "number",
          "password",
          "search",
          "url",
          'datetime-local'
        ].includes(value);
      },
    },
    inputMode: {
      default: "text",
      validator(value) {
        return [
          "text",
          "none",
          "decimal",
          "numeric",
          "tel",
          "search",
          "email",
          "url",
        ].includes(value);
      },
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      required: true,
      type: String,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearButton: {
      type: Boolean,
      default: false,
    },
    isPreloader: {
      type: Boolean,
      default: false,
    },
    customErrors: {
      type: Object,
      default: null
    },
    customFieldName: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", "comments", "settings"].includes(value);
      },
    },
  },
  data() {
    return {
      focused: false,
      typeLocal: this.type,
    };
  },
  computed: {
    isPassword() {
      return this.type === "password";
    },
    isActive() {
      return this.placeholder || this.focused || this.value;
    },
    isCustomErrors() {
      return Boolean(this.customErrors) && this.isCustomErrors.has(this.name)
    }
  },
  methods: {
    setFocus(focused) {
      this.focused = focused;
      this.$emit('focus', focused)
      if(!focused) {
        this.$emit("input", this.value);
      }
    },
    emitInput(e) {
      this.$validator.reset({ name: this.name });

      this.$emit("input", e.target.value);
    },
    showPassword() {
      this.typeLocal = this.typeLocal === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/helpers";

.c-input {
  $parent: &;

  position: relative;
  width: 100%;
  margin-bottom: em(20);

  &__label {
    display: block;
    margin-bottom: em(20);

    position: absolute;

    transform: translateY(-50%);
    transition: top 0.3s, font-size 0.3s;

    // default
    //top: 50%;
    //left: em(16);

    // always active
    top: 20%;
    left: em(16);
  }

  &__label-text {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    // default inline
    //font-size: em(18);
    //line-height: em(23);

    // always active
    font-size: em(10);
    line-height: em(13);

    letter-spacing: em(16 * -0.04);

    color: $app-font-color-placeholder;
    transition: font-size 0.3s;
    padding-right: em(14);
    display: inline-block;
  }

  &__field {
    @include autoFill($app-blue);

    width: 100%;
    //height: 100%;
    min-height: em(64, 16);
    padding: 0 em(16, 16);
    color: $app-blue;
    font-weight: normal;
    font-size: em(16);
    font-family: $font-default;
    font-style: normal;
    line-height: 1.2;
    border: 1px solid $app-border-gray-1;
    border-radius: em(8);
    outline: none;

    &::placeholder {
      color: $app-font-color-placeholder;
    }

    &:focus {
      border: 1px solid $app-blue-2;
    }

    &--password {
      padding-right: em(38, 14);
    }

    &--theme {
      &--primary {
      }

      &--secondary {
        &::placeholder {
          color: $app-font-color-placeholder-secondary;
        }

        background-color: $app-gray;
      }

      &--comments {
        min-height: em(44);
        background-color: $app-gray;

        &::placeholder {
          color: $app-font-color-placeholder-secondary;
        }
      }

      &--settings {
        min-height: em(46);
        color: $black;

        &::placeholder {
          color: $app-font-color-placeholder-secondary;
        }


      }
    }
  }

  &__button {
    position: absolute;
    right: em(8);
    bottom: em(5);
    display: block;
    width: em(24);
    height: em(24);
    padding: 0;
    background: none;
    border: none;

    @include hover {
      opacity: 0.8;
    }

    &:focus {
      #{$parent} {
        &__icon {
          color: rgba($app-border-gray-1, 0.7);
        }
      }
    }
  }

  &__append {
    position: absolute;
    top: 50%;
    display: flex;
    line-height: 1;
    transform: translateY(-50%);

    &--after {
      right: em(16);
    }

    &--addition {
      top: 105%;
      right: 0;
      width: 100%;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: em(16);
    color: $app-font-color-gray-1;

    &--password {
      transform: translateY(-65%);
    }
  }

  &__error {
    position: absolute;
    //bottom: em(-20);
    bottom: auto;
    left: 0;
    display: block;
    width: 100%;
    color: $red;
    font-weight: $font-medium;
  }

  &__font {
    &--error {
      //@include text-overflow;

      display: inline-block;
      width: 100%;
      font-weight: normal;
      font-size: em(12);
      font-style: normal;
      line-height: 1.2;
    }
  }

  &--theme {
    &--settings {
      #{$parent} {
        &__label {
          top: 50%;
          left: em(16);
        }

        &__label-text {
          color: $app-font-color-placeholder;
          background-color: $white;
          padding: 0 em(6);
          font-family: $font-default;
          font-size: em(14);
          line-height: em(23);
        }

        &__icon {
          &--password {
            transform: translateY(-25%);
          }
        }
      }
    }
  }

  &--error {
    #{$parent} {
      &__field {
        color: $red;
        border-color: $red;
      }

      &__label-text {
        color: $red;
      }
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;

    #{$parent} {
      &__field {
        border-color: rgba($app-border-gray-1, 0.15);

        @include hover {
          border-color: rgba($app-border-gray-1, 0.15);
        }
      }
    }
  }

  &--active {
    #{$parent} {
      &__label {
        top: 20%;
        left: em(16);
      }

      &__label-text {
        font-family: $font-default;
        font-size: em(10);
        line-height: em(13);
      }
    }
  }

  &--theme--settings#{$parent}--active {
    #{$parent} {
      &__label {
        top: 0%;
        left: em(16);
      }

      &__label-text {
        font-family: $font-default;
        font-size: em(10);
        line-height: em(13);
      }
    }
  }

  &--required {
    #{$parent} {
      &__label-text::after {
        content: " *";
        color: $app-blue-2;
      }
    }
  }
}
</style>
