import User from "@/components/models/User";

export default class Request {

  static PENDING = 0;
  static APPROVED = 1;
  static CANCELED = 2;

  static STATUS_NAMES = {
    0: 'PENDING',
    1: 'APPROVED',
    2: 'CANCELED'
  };

  id = 0;
  user_id = 0;
  status = 0;
  user = new User(null);

  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.user_id = data.user_id;
    this.status = +data.status;
    this.user = data.user;
  }

  getStatusName() {
    return Request.STATUS_NAMES[this.status] || 'Unknown';
  }

  isPending() {
    return this.status === Request.PENDING;
  }

  isApproved() {
    return this.status === Request.APPROVED;
  }
}
