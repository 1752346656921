<template>
  <div class="subscription-popup" @click.stop>
    <div class="subscription-popup__wrapper">
      <img :src="image" class="subscription-popup__image" />
      <div></div>
      <div class="subscription-popup__content">
        <div class="subscription-popup__title">
          {{ title }}
        </div>

        <div class="subscription-popup__text">
          <div
            v-for="item in reasons"
            :key="item.name"
            class="button check-list"
          >
            <CIcon
              name="check"
              class="check-list__icon check-list__icon--check"
            />

            <span class="check-list__font">
              {{ item.name }}
            </span>
          </div>
        </div>

        <div class="subscription-popup__renews" v-if="this.data.promo">
          <span
            class="subscription-popup__font subscription-popup__font--renews"
          >
            {{ renewsPromo }}
          </span>
        </div>
      </div>

      <div class="subscription-popup__card">
        <div class="card-settings">
          <CIcon name="wallet-money" path="tip" class="card-settings__icon" />

          <span class="card-settings__font">
            {{ activePaymentMethod.method }}
          </span>
        </div>

        <button
          class="subscription-popup__change"
          type="button"
          @click="activePaymentMethod.action"
        >
          <span class="card-settings__font card-settings__font--addition">
            {{ activePaymentMethod.buttonText }}
          </span>
        </button>
      </div>

      <div class="subscription-popup__footer">
        <CButton @click="mainButton.action" class="mt-3" align="center">
          <span class="card-button__font card-button__font--white">
            {{ mainButton.text }}</span
          >
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CIcon from "@/features/ui/CIcon.vue";
import { subscriptionTypes } from "@/config/subscriptionTypes";
import Post from "@/components/models/Post";
import Payment from "@/components/models/Payment";
// import Message from "@/components/models/Message";

const promoTypes = {
  discount: 2,
  trial: 1,
};

export default {
  name: "SubscriptionPopup",
  components: { CIcon, CButton },
  mixins: [waitRequest, validationError],
  props: {
    data: undefined,
  },

  computed: {
    ...mapGetters({
      reasonsList: "settings/reasonsList",
      paymentsMethods: "payments/cards",
      tempSubscriptionObject: "subscriptions/tempSubscriptionObject",
    }),
    image() {
      if (this.isUnlockPost || this.isUnlockMessage)
        return "/img/subscription/hero-unlock.webp";

      return "/img/subscription/hero.webp";
    },
    title() {
      let title = null;
      const baseTitle = `$${this.amount} month`;
      const trialTitle = `Free Trial ${
        (this.isPromoActive && this.promo?.trial_duration_days) || 0
      } Days`;
      const discountTitle = `$${
        (this.isPromoActive && this.promo?.face_discount_price) || 0
      } month`;
      const bundleTitle = `$${
        (this.isBundleActive && this.bundle?.face_price) || 0
      } bundle month`;

      if (this.isPromoActive) {
        if (this.isPromoDiscount) {
          title = discountTitle;
        } else {
          title = trialTitle;
        }
      } else if (this.isBundleActive) {
        title = bundleTitle;
      } else if (this.isUnlockPost || this.isUnlockMessage) {
        return this.$t("general.popups.subscription.titleUnlock", {
          price: this.unlockPostData.price,
        });
      } else {
        title = baseTitle;
      }

      return this.$t("general.popups.subscription.title", {
        name: this.name,
        type: title,
      });
    },
    renewsPromo() {
      return this.$t("general.popups.subscription.renews", {
        amount: `${this.amount}$`,
      });
    },
    name() {
      return `@${
        this.tempSubscriptionObject?.sub?.username ||
        this.tempSubscriptionObject?.username ||
        ""
      }`;
    },
    amount() {
      if (this.isPromoActive) {
        if (this.isPromoDiscount) {
          return this.tempSubscriptionObject?.sub?.face_discount_price;
        } else {
          return this.tempSubscriptionObject?.sub?.face_price;
        }
      }
      if (this.isBundleActive) {
        return this.tempSubscriptionObject?.sub?.face_price;
      }

      return (
        this.tempSubscriptionObject?.face_amount ||
        this.tempSubscriptionObject?.face_price ||
        0
      );
    },

    promo() {
      return this.tempSubscriptionObject?.promo;
    },

    isPromoActive() {
      return this.promo && this.promo.is_active;
    },

    isPromoDiscount() {
      return this.isPromoActive && this.promo?.type === promoTypes.discount;
    },

    isUnlockPost() {
      return (
        this.tempSubscriptionObject?.access_type ===
          Post.ACCESS_TYPE_PAID_POST || false
      );
    },

    isUnlockMessage() {
      return this.tempSubscriptionObject?.access_type === "message" || false;
    },

    activeMethod() {
      return this.paymentsMethods.cards?.methods?.find((item) => item.main);
    },

    bundle() {
      return this.tempSubscriptionObject.sub;
    },

    unlockPostData() {
      return {
        postId: this.tempSubscriptionObject.id,
        price: this.tempSubscriptionObject.price || 0,
        buttonText: this.$t("general.popups.subscription.buttonUnlock"),
      };
    },

    unlockMessageData() {
      return {
        messageId: this.tempSubscriptionObject.id,
        price: this.tempSubscriptionObject.price || 0,
        buttonText: this.$t("general.popups.subscription.buttonUnlock"),
      };
    },

    isBundleActive() {
      return this.tempSubscriptionObject?._activationType === "bundle";
    },

    activePaymentMethod() {
      if (this.data.changedMethod) {
        const { brand, title, id, gateway } = this.data.changedMethod;
        const number = title?.replaceAll("*", "") || "";
        const method = `${brand || ""}...${number}`;

        return {
          gateway,
          method,
          id,
          action: this.changePaymentMethod,
          buttonText:
            this.$t("general.popups.subscription.buttonChange") ||
            this.$t("general.popups.subscription.addNew") ||
            "",
        };
      }

      if (this.activeMethod) {
        const { brand, title, id, gateway } = this.activeMethod;
        const number = title?.replaceAll("*", "") || "";
        const method = `${brand || ""}...${number}`;

        return {
          gateway,
          method,
          id,
          action: this.changePaymentMethod,
          buttonText:
            this.$t("general.popups.subscription.buttonChange") ||
            this.$t("general.popups.subscription.addNew") ||
            "",
        };
      }

      return {
        method: this.$t("general.popups.subscription.noPayments"),
        action: this.addPaymentMethod,
        buttonText: this.$t("general.popups.subscription.addNew"),
      };
    },

    mainButton() {
      if (!this.activeMethod) {
        return {
          text: this.$t("general.popups.subscription.buttonAdd"),
          action: this.addPaymentMethod,
        };
      }

      if (this.isUnlockPost) {
        return {
          text: this.unlockPostData.buttonText,
          action: this.confirmSubscription,
        };
      }

      if (this.isUnlockMessage) {
        return {
          text: this.unlockPostData.buttonText,
          action: this.confirmSubscription,
        };
      }

      return {
        text: this.$t("general.popups.subscription.buttonConfirm"),
        action: this.confirmSubscription,
      };
    },
    reasons() {
      if (this.isUnlockPost || this.isUnlockMessage) {
        return [
          { name: this.$t("general.popups.subscription.unlockReason1") },
          {
            name: this.$t("general.popups.subscription.unlockReason2", {
              price: this.unlockPostData?.price || 0,
            }),
          },
        ];
      }
      return [
        { name: this.$t("general.popups.subscription.list1") },
        { name: this.$t("general.popups.subscription.list2") },
        { name: this.$t("general.popups.subscription.list3") },
      ];
    },
  },

  data() {
    return {
      form: {
        reason: null,
      },
    };
  },
  mounted() {
    this.waitRequest(() => {
      return this.fetchPaymentCards().catch(this.checkErrors);
    });

    // this.setCurrentSubscription(null);
    this.setCurrentSubscription(this.data?.card ?? this.tempSubscriptionObject);
  },
  methods: {
    ...mapMutations({
      updateCards: "subscriptions/updateCards",
      deleteCard: "subscriptions/deleteCard",
      setCurrentSubscription: "subscriptions/setCurrentSubscription",
    }),
    ...mapActions({
      deleteSubscription: "subscriptions/deleteSubscription",
      createSubscription: "subscriptions/createSubscription",
      fetchPaymentCards: "payments/fetchPaymentCards",
      createPayments: "payments/createPayments",
    }),
    initState() {
      this.fetchPaymentCards().catch(this.checkErrors);
    },
    changePaymentMethod() {
      this.$popup.open("ChoosePaymentMethodPopup");
    },
    addPaymentMethod() {
      this.$popup.close(1);
      this.$popup.open(
        "AddCardPopup",
        { closeCallback: this.onAddPaymentCallback },
        2
      );
    },

    onAddPaymentCallback() {
      this.$popup.close(2);
      this.$popup.open("SubscriptionPopup");
    },

    confirmSubscription() {
      const payment_method_id = this.activePaymentMethod?.id;
      let userId = this.tempSubscriptionObject?.sub?.id;

      if (this.requestInProgress && !payment_method_id) return;

      let promo_id = "";
      if (this.isPromoActive) {
        promo_id = this.promo?.id;
      }

      let bundle_id = "";
      if (this.isBundleActive) {
        bundle_id = this.bundle.id;
        userId = this.tempSubscriptionObject?.id;
      }

      if (this.isUnlockPost) {
        return this.unlockPost();
      }

      if (this.isUnlockMessage) {
        return this.unlockMessage();
      }

      this.waitRequest(() => {
        return this.createSubscription({
          userId,
          data: {
            payment_method_id,
            promo_id,
            bundle_id,
          },
        })
          .then((response) => {
            this.updateCards({
              cards: { data: [response.data] },
              type: subscriptionTypes.active,
            });

            if (this.tempSubscriptionObject.sub) {
              this.deleteCard({
                card: this.tempSubscriptionObject,
                type: subscriptionTypes.cancelled,
              });
            }

            if (this.data.callback) {
              this.data.callback();
            }

            if (this.tempSubscriptionObject.action) {
              this.tempSubscriptionObject.action();
            }
            this.setCurrentSubscription(null);

            this.close();
          })
          .catch(this.checkErrors);
      });
    },
    close() {
      if (this.customClose) return this.customClose();

      this.$popup.close();
    },

    unlockPost() {
      if (this.requestInProgress) return;
      let data = {
        type: Payment.TYPE_POST,
        amount: this.unlockPostData.price,
        gateway: this.activePaymentMethod?.gateway,
        payment_method_id: this.activePaymentMethod?.id,
        post_id: this.unlockPostData.postId,
      };

      return this.waitRequest(() => {
        return this.createPayments(data)
          .then((response) => {
            this.updateCards({
              cards: { data: [response.data] },
              type: subscriptionTypes.active,
            });

            if (this.tempSubscriptionObject.sub) {
              this.deleteCard({
                card: this.tempSubscriptionObject,
                type: subscriptionTypes.cancelled,
              });
            }

            if (this.data.callback) {
              this.data.callback();
            }

            if (this.tempSubscriptionObject.action) {
              this.tempSubscriptionObject.action();
            }
            this.setCurrentSubscription(null);

            this.close();
          })
          .catch(this.checkErrors);
      });
    },

    unlockMessage() {
      if (this.requestInProgress) return;
      let data = {
        type: Payment.TYPE_MESSAGE,
        amount: this.unlockMessageData.price,
        gateway: this.activePaymentMethod?.gateway,
        payment_method_id: this.activePaymentMethod?.id,
        message_id: this.unlockMessageData.messageId,
      };

      return this.waitRequest(() => {
        return this.createPayments(data)
          .then((response) => {
            if (this.tempSubscriptionObject.action) {
              this.tempSubscriptionObject.action(response);
            }
            this.setCurrentSubscription(null);

            this.close();
          })
          .catch(this.checkErrors);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.subscription-popup {
  $parent: &;

  width: em(330);

  &__wrapper {
    position: relative;
    z-index: 2;
    background: $white;
    border-radius: em(10);
    overflow: auto;
  }

  &__title {
    //padding: em(19) em(10) em(18);
  }

  &__text {
    margin: 9px 0px 22px 0px;
  }

  &__content {
    padding: 18px 17px 0px;
    position: relative;
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px 0px;
  }

  &__footer {
    padding: 0px 17px 22px;
  }

  &__change {
    width: auto;
  }

  &__renews {
    position: absolute;
    bottom: -20px;
    left: 0;
    padding: 18px 17px 0px;
  }

  &__button {
    text-align: center;
    padding: em(16) em(10) em(17);
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-style: normal;
    font-weight: 500;
    line-height: 1;

    &--title {
      font-size: 16px;
      line-height: 112%;
    }

    &--text {
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
    }

    &--button {
      font-size: 16px;
      line-height: 112%;
    }

    &--renews {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 10px;
      color: $app-gray-31;
    }
  }
}

.check-list {
  $parent: &;

  &:not(:last-child) {
    margin-bottom: em(11);
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    color: $font-black;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);

    position: absolute;
  }
}

.card-settings {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__icon {
    display: inline-block;
    width: em(24);
    min-width: em(24);
    height: em(24);
    margin-right: em(10);
  }

  &--active {
    &:before {
      background-color: $app-blue;
      border: 1px solid $app-blue;
    }
  }

  &--disabled {
    &:before {
      content: "";
      display: inline-block;
      width: em(16);
      min-width: em(16);
      height: em(16);
      min-height: em(16);
      background-color: transparent;
      border: 1px solid $app-gray-18;
      border-radius: 50%;
      margin-right: em(17);
      transition: background-color $time-normal $ease, border $time-normal $ease;
    }
  }
}
</style>
